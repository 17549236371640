import React, { Component } from 'react'
import Card4 from '../../../components/public_components/cards/Card4'
import { connect } from 'react-redux';
import { getPublicSections } from '../../../actions/PublicActions';
import ViewAllBtn from '../../../components/public_components/ViewAllBtn';
import LoadMoreBtn from '../../../components/public_components/LoadMoreBtn';
import Card10 from '../../../components/public_components/cards/Card10';
export class SubjectSection extends Component {
  componentDidMount(){
    this.props.getPublicSections(this.props.page)
  }
  loadMore=(page)=>{
    this.props.getPublicSections(page)
  }
  render() {
    const {publicSections, viewBtn=false, loadMoreBtn=false, headingType="h2"} = this.props;
    //   console.log(publicSections)
    return (
        <section className='bg-[#fff] pt-[50px] pb-[70px]'>
            <div className='max-w-[1200px] ml-auto mr-auto max-[1240px]:ml-[20px] max-[1240px]:mr-[20px]'>
              {
                headingType === "h2"?
                <h2 className='text-black text-[44px] font-semibold max-[450px]:text-[30px] text-center'>{this.props.language?.section_title5}</h2>:
                <h1 className='text-black text-[44px] font-semibold max-[450px]:text-[30px] text-center'>{this.props.language?.section_title5}</h1>
              }
                
                <div className='mt-[60px] grid-full-250 gap-[40px_!important]'>
                  {
                    [...publicSections]?.sort((a,b) => b?.universities_count - a?.universities_count)?.map((data, i)=>{
                      return(
                        <Card10 key={i} data={data} />
                      )
                    })
                  }
                </div>
                {
                    viewBtn?
                    <ViewAllBtn to={`/${this.props.langPrefix}subjects`} />:null
                }
                {
                    loadMoreBtn && publicSections?.length >=20?
                    <LoadMoreBtn onLoadData={this.loadMore} count={publicSections?.length} />:null
                }
            </div>
        </section>
    )
  }
}
const mapStateToProps = (state) =>({
  publicSections: state.Data.publicSections,
  language: state.Data.language,
  langPrefix: state.Data.langPrefix,
});
const mapDispatchToProps = {getPublicSections}
export default connect(mapStateToProps, mapDispatchToProps)(SubjectSection)


