import React, { Component } from 'react'
import { changeStateValue } from '../../../redux/MainReducer';
import { connect } from 'react-redux';
import { getPublicCountries, getPublicEduDegree, getPublicHighSchoolExams } from '../../../actions/PublicActions';
import DropSearch from '../../../components/modals/components/DropSearch';
import Input from '../../../components/modals/components/Input';
import { getDropValue, getTranslateLanguage } from '../../../actions/getOtherFunctions';
import WorldSvg from '../../../components/svg/WorldSvg';
import EduSvg from '../../../components/svg/EduSvg';
import DiplomaSvg from '../../../components/svg/DiplomaSvg';
import GradeSvg from '../../../components/svg/GradeSvg';
import InpSvg from '../../../components/svg/InpSvg';
import SelectBtn from '../../../components/apply/SelectBtn';

export class EducationBackground extends Component {
    state={
        sub_degrees:[]
    }
    componentDidMount(){
        this.props.getPublicCountries(2);
        this.props.getPublicHighSchoolExams();
        this.props.getPublicEduDegree();
        try{
            if(this.props.applyDetails.educationBackground.country_id && this.props.applyDetails.educationBackground.country_id != "" &&  this.props.applyDetails.educationBackground.edu_degree_id && this.props.applyDetails.educationBackground.edu_degree_id !=""){
                let subDegrees = this.props.publicEducationDegree?.find(x=>x?.id == this.props.applyDetails.educationBackground.edu_degree_id)?.sub_degrees?.filter(y=>y?.country_id == this.props.applyDetails.educationBackground.country_id);
                this.setState({sub_degrees:subDegrees})
            }
        }catch(err){}
    }
    onSelectDegree=(countryId, degrees, degreeId)=>{
        let subDegrees = degrees?.find(x=>x?.id == degreeId)?.sub_degrees?.filter(y=>y?.country_id == countryId);
        this.setState({sub_degrees:subDegrees})
        this.props.changeStateValue({  name:'applyDetails.educationBackground.edu_degree_id', value:degreeId });
        this.props.changeStateValue({  name:'applyDetails.educationBackground.sub_edu_degree_id', value:'' });
        this.props.changeStateValue({  name:'applyDetails.educationBackground.grade', value:'' });
        this.props.changeStateValue({  name:'applyDetails.educationBackground.a_level', value:'' });
        this.props.changeStateValue({  name:'applyDetails.educationBackground.ap', value:'' });
        this.props.changeStateValue({  name:'applyDetails.educationBackground.ib', value:'' });
        this.props.changeStateValue({  name:'applyDetails.educationBackground.ossd', value:'' });
        this.props.changeStateValue({  name:'applyDetails.educationBackground.foundation', value:'' });
        this.props.changeStateValue({  name:'applyDetails.educationBackground.grade_range', value:'' });
    }
    onSelectCountry=(id)=>{
        this.props.changeStateValue({  name:'applyDetails.educationBackground.country_id', value: id });
        this.props.changeStateValue({  name:'applyDetails.educationBackground.edu_degree_id', value:'' });
        this.props.changeStateValue({  name:'applyDetails.educationBackground.sub_edu_degree_id', value:'' });
        this.props.changeStateValue({  name:'applyDetails.educationBackground.grade', value:'' });
        this.props.changeStateValue({  name:'applyDetails.educationBackground.a_level', value:'' });
        this.props.changeStateValue({  name:'applyDetails.educationBackground.ap', value:'' });
        this.props.changeStateValue({  name:'applyDetails.educationBackground.ib', value:'' });
        this.props.changeStateValue({  name:'applyDetails.educationBackground.ossd', value:'' });
        this.props.changeStateValue({  name:'applyDetails.educationBackground.foundation', value:'' });
        this.props.changeStateValue({  name:'applyDetails.educationBackground.grade_range', value:'' });

    }
    onSelectSubDegree=(id)=>{
        this.props.changeStateValue({  name:'applyDetails.educationBackground.sub_edu_degree_id', value:id });
        this.props.changeStateValue({  name:'applyDetails.educationBackground.grade', value:'' });
        this.props.changeStateValue({  name:'applyDetails.educationBackground.grade_range', value:'' });
        this.props.changeStateValue({  name:'applyDetails.educationBackground.a_level', value:'' });
        this.props.changeStateValue({  name:'applyDetails.educationBackground.ap', value:'' });
        this.props.changeStateValue({  name:'applyDetails.educationBackground.ib', value:'' });
        this.props.changeStateValue({  name:'applyDetails.educationBackground.ossd', value:'' });
        this.props.changeStateValue({  name:'applyDetails.educationBackground.foundation', value:'' });

    }

    onSelectGradeScheme=(id)=>{
        this.props.changeStateValue({  name:'applyDetails.educationBackground.grade_range', value:id });
        this.props.changeStateValue({  name:'applyDetails.educationBackground.grade', value:'' });
    }
    changePageBack=()=>{
        this.props.onChangePage("discipline")
    }
    changePage=()=>{
        this.props.onChangePage("info")
    }
    getPlaceholder(name){
        if(name == "IB"){
            return "Yalnız 24 və 45 aralığında qiymət daxil edin.";
        }else if (name == "OSSD"){
            return "Yalnız 50 və 100 aralığında qiymət daxil edin."
        }else if (name == "Foundation"){
            return "Yalnız 40 və 100 aralığında qiymət daxil edin."
        }else{
            return ""
        }
    }
  render() {
    const {applyDetails, publicEducationDegree, publicHighSchoolExams, publicCountries} = this.props;
//    console.log(publicEducationDegree)
    const {sub_degrees} = this.state;
    let countryOption = [];
    for(const proeprty of publicCountries){
      if(proeprty?.id===1 || proeprty?.id === 5){
        countryOption.push({
          label: getTranslateLanguage(this.props.lang_id, "name", proeprty),
          value: proeprty?.id
        })
      }
    }
    let subDegrees = [...getDropValue(sub_degrees, "name","id")];
    for(const exam of publicHighSchoolExams){
      subDegrees.push({
        label: exam?.name,
        value: exam?.name?.replaceAll(" ", "_")?.toLowerCase()
      })
    }
    // console.log(publicHighSchoolExams)
    let degreeOpt = [];
    for(const data of publicEducationDegree?.filter(x=>x.id ==1|| x.id==3 || x.id == 4 || x.id == 5)){
        degreeOpt.push({
            label: getTranslateLanguage(this.props.lang_id, "name", data),
            value: data?.id
        })
    }
    let gradeSchemeOpt = [
        {
            label: "1-4",
            value: "1-4",
        },
        {
            label: "1-5",
            value: "1-5",
        },
        {
            label: "51-100",
            value: "1-100",
        },
    ]
    return (
      <div className='mt-[10px] max-h-[50vh] overflow-auto'>
       {
        applyDetails?.page == "edu_back"?
        <div>
            <h4 className='text-center txt-blue font-semibold'>{this.props.language?.study_country}</h4>
            <div className='flex flex-wrap gap-[10px] mt-[10px]'>
                {
                    countryOption?.map((data, i)=> {
                        return (
                            <SelectBtn check={applyDetails.educationBackground.country_id === data?.value} onClick={this.onSelectCountry.bind(this, data?.value)} key={i} title={data?.label} />
                        )
                    })
                }
            </div>
            <div className='mt-[14px] border-t pt-[10px]'>
                <h4 className='text-center txt-blue font-semibold'>{this.props.language?.last_edu_diploma}</h4>
                <div className='flex flex-wrap gap-[10px] mt-[10px] pb-[5px]'>
                    {
                        degreeOpt?.map((data, i)=>{
                            return(
                                <SelectBtn check={applyDetails.educationBackground.edu_degree_id === data?.value} onClick={this.onSelectDegree.bind(this, applyDetails.educationBackground.country_id, publicEducationDegree, data?.value)} key={i} title={data?.label} />
                            )
                        })
                    }
                </div>
            </div>
        </div>:null
       }
       {
        applyDetails?.page == "edu_back_1"?
        <div>
            {
                applyDetails.educationBackground?.edu_degree_id ===1 || applyDetails.educationBackground?.edu_degree_id === 2?
                <div className='mt-[14px] pt-[10px]'>
                    <h4 className='text-center txt-blue font-semibold'>{this.props.language?.school_diploma}</h4>
                    <div className='flex flex-wrap gap-[10px] mt-[10px]'>
                        {
                            subDegrees?.map((data, i) => {
                                return (
                                    <SelectBtn check={applyDetails.educationBackground.sub_edu_degree_id === data?.value} onClick={this.onSelectSubDegree.bind(this, data?.value)} key={i} title={data?.label} />
                                )
                            })
                        }
                    </div>
                    <div  className='mt-[14px] border-t pt-[10px]'>
                        {
                            publicHighSchoolExams?.map((data, j)=>{
                                if(data?.name?.replaceAll(" ", "_")?.toLowerCase() ===  applyDetails.educationBackground?.sub_edu_degree_id){
                                    if(data?.grades?.length !==0){
                                    return(
                                        <DropSearch svg={<GradeSvg />} bigDrop key={j} 
                                            required name={`applyDetails.educationBackground.${applyDetails.educationBackground?.sub_edu_degree_id}`} 
                                            value={applyDetails.educationBackground[applyDetails.educationBackground?.sub_edu_degree_id]} 
                                            placeholder={data?.name+" "+this.props.language?.grade_select} options={getDropValue(data?.grades, "grade", "grade")} 
                                            reqText={data?.name+" "+this.props.language?.grade_select}
                                            title={data?.name} />
                                    )
                                    }else{
                                    return(
                                        <Input bigInp svg={<InpSvg />} bg="bg-[#F3F4F6]" key={j} 
                                            required
                                            minValue={data?.min_value} 
                                            maxValue={data?.max_value} 
                                            step={1}
                                            name={`applyDetails.educationBackground.${applyDetails.educationBackground?.sub_edu_degree_id}`}  
                                            value={applyDetails.educationBackground[applyDetails.educationBackground?.sub_edu_degree_id]} 
                                            title={`${data?.name} (${data?.min_value} - ${data?.max_value})`} 
                                            reqText={this.getPlaceholder(data?.name)}
                                            type="number"
                                            placeholder={data?.name+" "+ this.props.language?.grade_predicted}
                                            />
                                    )
                                    }
                                    
                                }
                            })
                        }
                        {
                            applyDetails.educationBackground?.sub_edu_degree_id ===1?
                            <Input 
                            bigInp svg={<InpSvg />} bg="bg-[#F3F4F6]"
                            required
                            minValue={3} 
                            maxValue={5} 
                            step={1}
                            type="number"
                            name={`applyDetails.educationBackground.grade`}  
                            value={applyDetails.educationBackground?.grade} 
                            title={`Attestat ${this.props.language?.average_grade}`}  
                            placeholder={`${this.props.language?.example}: 3, 3.5, 4, 4.5, 5`}
                            reqText={this.props.language?.required_txt}/>:null
                        }
                    </div>
                </div>:null
                }
        </div>:null
       }
        {
            applyDetails?.page == "edu_back_2"?
            <div>
            {
                applyDetails.educationBackground?.edu_degree_id !=="" &&applyDetails.educationBackground?.edu_degree_id !==1 && applyDetails.educationBackground?.edu_degree_id !== 2?
                <div className='mt-[14px]  pt-[10px]'>
                    <h4 className='text-center txt-blue font-semibold'>{this.props.language?.grade_schmede_title}</h4>
                    <div className='flex flex-wrap gap-[10px] mt-[10px] pb-[5px]'>
                        {
                            gradeSchemeOpt?.map((data, i) => {
                                return (
                                    <SelectBtn check={applyDetails.educationBackground.grade_range === data?.value} onClick={this.onSelectGradeScheme.bind(this, data?.value)} key={i} title={data?.label} />
                                )
                            })
                        }
                    </div>
                    {
                        applyDetails.educationBackground.grade_range != ""?
                        <div className='mt-[14px] border-t pt-[10px]'>
                            <Input 
                                bigInp svg={<InpSvg />} bg="bg-[#F3F4F6]"
                                required
                                minValue={applyDetails.educationBackground.grade_range?.split("-")[0]} 
                                maxValue={applyDetails.educationBackground.grade_range?.split("-")[1]} 
                                step={1}
                                type="number"
                                name={`applyDetails.educationBackground.grade`}  
                                value={applyDetails.educationBackground?.grade} 
                                reqText={this.props.language?.required_txt}
                                title={`${this.props.language?.grade} (${applyDetails.educationBackground.grade_range?.split("-")[1] == "100"? 51: applyDetails.educationBackground.grade_range?.split("-")[0]} - ${applyDetails.educationBackground.grade_range?.split("-")[1]})`}  />
                        </div>:null
                    }
                </div>:null
            }
            </div>:null
        }
       
         {/* <div className='grid grid-cols-2 gap-[20px] max-[640px]:grid-cols-1'>
            <DropSearch 
                svg={<WorldSvg />} bigDrop 
                required name="applyDetails.educationBackground.country_id"  
                selectData={this.onSelectCountry.bind(this)}
                value={applyDetails.educationBackground.country_id} 
                placeholder={this.props.language?.study_country} 
                options={countryOption} 
                title={this.props.language?.study_country} />
            {
                applyDetails.educationBackground?.country_id !=""?
                <DropSearch svg={<EduSvg />} bigDrop required 
                    name="applyDetails.educationBackground.edu_degree_id"  
                    selectData={this.onSelectDegree.bind(this,applyDetails.educationBackground.country_id, publicEducationDegree, )} 
                    value={applyDetails.educationBackground.edu_degree_id} 
                    placeholder={this.props.language?.last_edu_diploma} 
                    options={degreeOpt} 
                    reqText={this.props.language?.select_edu_diploma}
                    title={this.props.language?.last_edu_diploma} />:null
            }
            {
                applyDetails.educationBackground?.edu_degree_id ===1 || applyDetails.educationBackground?.edu_degree_id === 2?
                <DropSearch svg={<DiplomaSvg />} bigDrop required 
                    name="applyDetails.educationBackground.sub_edu_degree_id"  
                    selectData={this.onSelectSubDegree.bind(this)} 
                    value={applyDetails.educationBackground.sub_edu_degree_id}
                    reqText={this.props.language?.select_sch_diploma}
                    placeholder={this.props.language?.school_diploma} options={subDegrees} title={this.props.language?.school_diploma} />:null
            }
            {
                publicHighSchoolExams?.map((data, j)=>{
                if(data?.name?.replaceAll(" ", "_")?.toLowerCase() ===  applyDetails.educationBackground?.sub_edu_degree_id){
                    if(data?.grades?.length !==0){
                    return(
                        <DropSearch svg={<GradeSvg />} bigDrop key={j} 
                            required name={`applyDetails.educationBackground.${applyDetails.educationBackground?.sub_edu_degree_id}`} 
                            value={applyDetails.educationBackground[applyDetails.educationBackground?.sub_edu_degree_id]} 
                            placeholder={data?.name+" "+this.props.language?.grade_select} options={getDropValue(data?.grades, "grade", "grade")} 
                            reqText={data?.name+" "+this.props.language?.grade_select}
                            title={data?.name} />
                    )
                    }else{
                    return(
                        <Input bigInp svg={<InpSvg />} bg="bg-[#F3F4F6]" key={j} 
                            required
                            minValue={data?.min_value} 
                            maxValue={data?.max_value} 
                            step={1}
                            name={`applyDetails.educationBackground.${applyDetails.educationBackground?.sub_edu_degree_id}`}  
                            value={applyDetails.educationBackground[applyDetails.educationBackground?.sub_edu_degree_id]} 
                            title={`${data?.name} (${data?.min_value} - ${data?.max_value})`} 
                            reqText={this.getPlaceholder(data?.name)}
                            placeholder={data?.name+" "+ this.props.language?.grade_predicted}
                            />
                    )
                    }
                    
                }
                })
            }
             {
                applyDetails.educationBackground?.sub_edu_degree_id ===1?
                <Input 
                bigInp svg={<InpSvg />} bg="bg-[#F3F4F6]"
                required
                minValue={3} 
                maxValue={5} 
                step={1}
                name={`applyDetails.educationBackground.grade`}  
                value={applyDetails.educationBackground?.grade} 
                title={`Attestat ${this.props.language?.average_grade}`}  
                placeholder={`${this.props.language?.example}: 3, 3.5, 4, 4.5, 5`}
                reqText={this.props.language?.required_txt}/>:null
            }
            {
                applyDetails.educationBackground?.sub_edu_degree_id ===2?
                <Input 
                bigInp svg={<InpSvg />} bg="bg-[#F3F4F6]"
                required
                minValue={1} 
                maxValue={100} 
                step={1}
                reqText={this.props.language?.required_txt}
                name={`applyDetails.educationBackground.grade`}  
                value={applyDetails.educationBackground?.grade} 
                title={`Lise diploması (1 - 100)`}  />:null
            }
            {
                applyDetails.educationBackground?.edu_degree_id !=="" &&applyDetails.educationBackground?.edu_degree_id !==1 && applyDetails.educationBackground?.edu_degree_id !== 2?
                <Input 
                bigInp svg={<InpSvg />} bg="bg-[#F3F4F6]"
                required
                minValue={1} 
                maxValue={100} 
                step={1}
                name={`applyDetails.educationBackground.grade`}  
                value={applyDetails.educationBackground?.grade} 
                reqText={this.props.language?.required_txt}
                title={`${this.props.language?.grade} (1 - 100)`}  />:null
            }
         </div> */}
        
      </div>
    )
  }
}
const mapStateToProps = (state) =>({
    applyDetails: state.Data.applyDetails,
    publicEducationDegree: state.Data.publicEducationDegree,
    publicHighSchoolExams: state.Data.publicHighSchoolExams,
    publicCountries: state.Data.publicCountries,
    language: state.Data.language,
    lang_id: state.Data.lang_id,
});
const mapDispatchToProps = {changeStateValue, getPublicCountries, getPublicHighSchoolExams, getPublicEduDegree}
export default connect(mapStateToProps, mapDispatchToProps)(EducationBackground)
