import React, { Component } from 'react'
import { changeStateValue, resetStateValue } from '../../redux/MainReducer';
import { insertNewData } from '../../actions/MainAction';
import { connect } from 'react-redux';
import Input from '../../components/modals/components/Input';
import InputPhone from '../../components/modals/components/InputPhone';
import { mixinAlert } from '../../actions/SweetAlerts';
import { withHooks } from '../../actions/withHooks';
import { Link } from 'react-router-dom';

export class ForgotPasswordPage extends Component {
    state={
        page:"email"
    }

    sendMsg=(page, forgotPassword, phoneValidFormat)=>{
        if(forgotPassword.trim() !=""){
            if(page == "email"){
                if(!forgotPassword.match(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)){
                    mixinAlert("warning", "Email düzgün formatda deyil.")
                    return;
                }
            }
            if(page == "whatsapp"){
                if(!phoneValidFormat){
                    mixinAlert("warning", "Nömrə düzgün formatda deyil.");
                    return;
                }
            }
            this.props.insertNewData("resetPassword", {type: page, data: forgotPassword.trim()})
            .then(resp=>{
                if(resp == "success"){
                    mixinAlert("success", `Giriş məlumatları ${page == "email" ? "Email": "Whatsapp"} vasitəsilə göndərildi.`);
                    this.props.resetStateValue({name:"forgotPassword"});
                    this.props.resetStateValue({name:"phoneValidFormat"});
                    this.props.navigate("/login");
                }else{
                    mixinAlert("warning", `İstifadəçi tapılmadı.`);
                }
            })
        }
    }   
  render() {
    const {page} = this.state;
    const {forgotPassword, phoneValidFormat} = this.props;
    // console.log(phoneValidFormat)
    return (
      <div className='flex items-center justify-center h-screen'>
            <div className='bg-white p-[20px] shadow w-[400px] rounded-[10px] max-[400px]:ml-[20px] max-[400px]:mr-[20px]'>
                <h3 className='text-center txt-blue text-[16px] font-semibold'>{this.props.language?.change_psw_device}</h3>
                <div className='mt-[10px] flex items-center justify-center gap-[20px]'>
                    <button onClick={()=>{this.setState({page:"email"}); this.props.changeStateValue({name:"forgotPassword", value:""})}} className={`${page == "email"? "bg-blue text-white":" txt-blue border border-[var(--blue)]"} text-[14px] p-[4px_10px]  rounded-[5px]`}>
                        Email
                    </button>
                    <button onClick={()=>{this.setState({page:"whatsapp"}); this.props.changeStateValue({name:"forgotPassword", value:""})}} className={`${page == "whatsapp"? "bg-blue text-white":" txt-blue border border-[var(--blue)]"} text-[14px] p-[4px_10px]  rounded-[5px]`}>
                        Whatsapp
                    </button>
                </div>
                <div className='mt-[15px]'>
                {
                    page == "email"?
                    <Input 
                        type="email"
                        title="Email"
                        name="forgotPassword"
                        value={forgotPassword}
                    />:
                    <InputPhone 
                        name="forgotPassword"
                        value={forgotPassword}
                        title={this.props.language?.phon_number}
                    />
                }
                </div>
               <div className='flex justify-center mt-[20px] '>
                 <button onClick={this.sendMsg.bind(this, page, forgotPassword, phoneValidFormat)} className='bg-blue text-white text-[12px] w-[120px] h-[30px] rounded-[10px]'>{this.props.language?.send_txt}</button>
               </div>
               <div className='pt-[30px] flex justify-center'>
                   <Link to={`/${this.props.langPrefix}login`} className='txt-blue text-[14px]'>{this.props.language?.return_login}</Link>
                </div>
            </div>
      </div>
    )
  }
}
const mapStateToProps = (state) =>({
    forgotPassword: state.Data.forgotPassword,
    phoneValidFormat: state.Data.phoneValidFormat,
    language: state.Data.language,
    langPrefix: state.Data.langPrefix,
});
const mapDispatchToProps = {changeStateValue, insertNewData, resetStateValue}
export default connect(mapStateToProps, mapDispatchToProps)(withHooks(ForgotPasswordPage))
