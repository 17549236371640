import React, { Component } from 'react'
import LoginEmailSvg from '../components/svg/LoginEmailSvg';
import LoginPasswordSvg from '../components/svg/LoginPasswordSvg';
import EyeCloseSvg from '../components/svg/EyeCloseSvg';
import EyeSvg from '../components/svg/EyeSvg';
import { Link } from 'react-router-dom';
import { withHooks } from '../actions/withHooks';
import { mixinAlert } from '../actions/SweetAlerts';
import { loginAction } from '../actions/MainAction';
import { connect } from 'react-redux';
import { changeStateValue } from '../redux/MainReducer';
export class LoginPage extends Component {
    state={
        username:'',
        password:'',
        loginError: false,
        type:'password',
        btnDisable:false
    }
        componentDidMount(){
        window.scrollTo(0,0)
    }
    loginBtn(username, password, e){
        e.preventDefault();
        if(username.trim() !== '' && password.trim() !== ''){
          if(password.length <8){
            mixinAlert("warning", "Password should be at least 8 characters.");
            return;
          }
          // if(!username.match(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)){
          //   mixinAlert("warning", "The email should be valid email format.");
          //   return;
          // }
          this.setState({btnDisable:true})
          this.props.loginAction({username:username, password})
          .then(resp=>{
            this.setState({btnDisable:false})
            if(resp == "error"){
              this.setState({loginError:true})
            }else{
              this.props.navigate("/")
            } 
          })
        }
    }
  render() {
    const {username, password, loginError, type, btnDisable} = this.state;
    return (
        <div className='flex bg-[#f5f5f5] items-center justify-center min-h-screen w-full mb-[-120px]'>
            <div className='w-[510px] bg-white pt-[44px] pl-[54px] pr-[54px] max-[660px]:pl-[20px]  max-[660px]:pr-[20px]  pb-[50px] rounded-[12px] shadow mt-[100px] max-[520px]:ml-[20px] max-[520px]:mr-[20px]'>
            <div className='flex justify-center'>
                {/* <img className='w-[160px] h-[50px]' src={logo} /> */}
                <h2 className='text-[30px] font-semibold txt-blue'>{this.props.language?.login}</h2>
            </div>
            <form className='mt-[35px]'>
                <div className='flex flex items-center p-[16px] gap-[16px] w-full border h-[56px] border-[#E2E8F0] rounded-[12px]'>
                    <LoginEmailSvg />
                    <input className='bg-white w-[80%] outline-none h-[40px] rounded-full p-2 text-[14px]' type='email' id='loginEmail' placeholder={this.props.language?.email} value={username} onChange={(e)=>this.setState({username: e.target.value})} />         
                </div>
                <div className='flex mt-[15px] justify-between flex items-center p-[16px] gap-[16px] w-full border h-[56px] border-[#E2E8F0] rounded-[12px]'>
                    <LoginPasswordSvg />
                    <input className='bg-white outline-none w-[80%] h-[40px] rounded-full p-2 text-[14px]' type={type} id='loginPassword' placeholder={this.props.language?.password} value={password} onChange={(e)=>this.setState({password: e.target.value})} />         
                    <div className='cursor-pointer' onClick={(e)=>{e.preventDefault(); this.setState({type: type==="text"?"password":"text"})}}>
                    {type==="password"? <EyeSvg />: <EyeCloseSvg />}
                    </div>
                </div>
                <div className='flex justify-end mt-[17px]'>
                    <Link className='text-[14px] txt-blue font-bold' to={`/${this.props.langPrefix}forgot-password`}>{this.props.language?.forgot_psw}</Link>
                </div>
                <button disabled={btnDisable} onClick={this.loginBtn.bind(this, username, password)} className='mt-[33px] bg-blue w-full h-[56px] font-bold text-[16px] rounded-[12px] text-white '>{this.props.language?.login_btn_txt}</button>
                {
                    loginError?
                    <p className='text-red-600 text-[12px] mt-2'>{this.props.language?.email_psw_inc}</p>:null
                }
            </form>
            <div className='pt-[30px] flex justify-center'>
                <p>{this.props.language?.not_acc} <button className='txt-blue' onClick={()=>this.props.changeStateValue({name:'admissionModal.position', value:true})}>{this.props.language?.create_new}</button></p>
            </div>
            </div>
        </div>
    )
  }
}
const mapStateToProps = (state) =>({
  language: state.Data.language,
  langPrefix: state.Data.langPrefix,
});
const mapDispatchToProps = {loginAction, changeStateValue}
export default connect(mapStateToProps, mapDispatchToProps)(withHooks(LoginPage))
