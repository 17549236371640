import React, { Component } from 'react'
import { connect } from 'react-redux';
import { getStudentProgramAndUni, insertNewData } from '../../../../actions/MainAction';
import { getStudentPrograms } from '../../../../actions/MainAction';
import Card5 from '../../../../components/public_components/cards/Card5';
import { bigAlert, confirmAlert, mixinAlert } from "../../../../actions/SweetAlerts";
import Card9 from "../../../..//components/public_components/cards/Card9"
import { getStartFrom } from '../../../../actions/getOtherFunctions';
import LoadMoreBtn from '../../../../components/LoadMoreBtn';
import { Link } from 'react-router-dom';
import { withHooks } from '../../../../actions/withHooks';
import Search from '../../../../components/Search';
export class ProgramsPage extends Component {
    state={
      programs:[],
      btnDisable:false,
      page:1,
      search:'',
      page_count: 4
    }
    componentDidMount(){
      this.props.getStudentPrograms(1,this.props.student_id, "")
      .then(resp=>{
        if(resp.length == 0){
          this.props.insertNewData("send-alternative-proposal", {student_id: this.props.student_id})
        }
        this.setState({programs: resp})
      })
      this.props.getStudentProgramAndUni(this.props.student_id)
     
    }
   

    onClickApply=(programId)=>{
      confirmAlert("warning", "", "Əminsiniz?", "Bəli, müraciət et.", "Ləğv et.")
      .then(alertResp=>{
          if(alertResp === "success"){
            this.setState({btnDisable:true})
            let formData = new FormData();
            formData.append("student_id", this.props.student_id);
            formData.append("program_id", programId);
            this.props.insertNewData("apply-program", formData)
            .then(resp=>{
              mixinAlert("success", "Müraciət edildi.");
              this.setState({btnDisable:false});
              bigAlert("", "Müraciətiniz qəbul olundu. Təhsil nümayəndəsi yoxlanış etdikdən sonra müraciətinizə baxacaq və sizə geri dönüş edəcək.", "success", 30000)
              if(this.props.user?.role == "student"){
                this.props.navigate("/applied-programs")
              }else{
                this.props.navigate(`/program-requirements/${this.props.student_id}/${programId}`)
              }
              // 
              this.props.getStudentPrograms(this.state.page, this.props.student_id, this.state.search)
              .then(resp=>{
                this.setState({programs: resp})
              })
            })
          }
      })

     
    }

    loadMore=(page)=>{
      this.setState({page})
      this.props.getStudentPrograms(page,this.props.student_id, this.state.search)
      .then(resp=>{
        this.setState({programs: resp})
      })
    }

    onSearchData=(search)=>{
     this.setState({search:search})
      this.props.getStudentPrograms(this.state.page,this.props.student_id, search)
      .then(resp=>{
        this.setState({programs: resp})
      })
    }
    redirectPage=(data)=>{
      this.props.navigate(`/program-requirements/${data?.student_id}/${data?.program_id}`)
    }

    exceptForPrograms=(programs)=>{
      let arr = [];
      for(const data of programs){
        arr.push(data?.program_id)
      }
      return arr;
    }

    getSelectedPrograms=(programs, selectedUniAndPrograms)=>{
      let arr = [];
      selectedUniAndPrograms.programs?.forEach(item=>{
        const check = programs.find(x=>x.program_id == item?.program_id);
        if(check){
           arr.push(check);
        }
      })
      let result = arr.reduce(function (r, a) {
        r[a.university_id] = r[a.university_id] || [];
        r[a.university_id].push(a);
        return r;
      }, {});
      return result;
    }
    checkVisible=(data, programs)=>{
      let check=false;
      for(const item of data){
        if(!programs.includes(item?.program_id)){
          check=true
        }
      }
      return check;
    }

   
  
  render() {
    const {programs, btnDisable, search, page, page_count} = this.state;
    const {user, selectedUniAndPrograms} = this.props;
    let result = programs.reduce(function (r, a) {
        r[a.university_id] = r[a.university_id] || [];
        r[a.university_id].push(a);
        return r;
    }, {});
    // console.log(programs)
    return (
      <div className='mb-[30px]' >
        <div className='flex justify-center'>
          <Search  largWidth={true} onSearchData={this.onSearchData.bind(this)} />
        </div>
        {
          programs.length ==0 &&  selectedUniAndPrograms?.status == 0?
          <div className='bg-white text-center flex justify-center items-center h-[80vh] shadow rounded-[10px] mt-[20px] ml-[20px] mr-[20px]'>
            <h3 className='text-[25px]'>Sizin təhsil məlumatlarınıza əsasən uyğun proqram tapılmadı. Daha yaxşı nəticələr əldə etmək üçün  <Link to={user?.role == "student"?"/info":`/students/${this.props.student_id}`} className='text-blue-700'>profilinizi</Link> yeniləyin və təkrar yoxlamaya göndərin. Məsələn imtahan nəticələriniz varsa, daxil edin, başqa ölkə seçin və sair.</h3>
          </div>:null
        }
        { selectedUniAndPrograms?.status==1?
         <div className='flex flex-col gap-[20px] mt-[20px] '>
          {
            selectedUniAndPrograms?.programs?.length !=0?
            <>
              {
                selectedUniAndPrograms?.program_select ==1?
                <h2 className='text-[18px] mb-[-10px] font-[600] max-[600px]:text-center'>Seçiminizə uyğun ixtisaslar</h2>:
                <h2 className='text-[18px] mb-[-10px] font-[600] max-[600px]:text-center'>Universitet seçiminizə uyğun ixtisaslar</h2>
              }
              
              {
                [...Object.values(this.getSelectedPrograms(programs, selectedUniAndPrograms))].sort((a,b)=>a[0]?.local_ranking - b[0]?.local_ranking).map((data, i)=>{
                  return(
                    <Card9 key={i} 
                            data={data} 
                            degree={data?.degree?.name}
                            university={data?.university?.name}
                            btn={true}
                            onClickBtn={(id)=>this.onClickApply(id)}
                            btnDisable={btnDisable}
                            onRedirectBtn={this.redirectPage.bind(this)}
                            selected={true}
                            onSearch={this.onSearchData.bind(this)}
                            searchVisible={true}
                        />
                  )
                })
              }
            </>:
            <div className='bg-white p-[20px] text-center shadow rounded-[10px]'>
             <p> Seçdiyiniz {  selectedUniAndPrograms?.program_select ==1?"ixtisasa":"universitetə"}  uyğun təklif tapılmadı. Zəhmət olmasa <Link className='text-blue-600' to={user?.role == "student"?"/info":`/students/${this.props.student_id}`}>profilinizi</Link> yeniləyərək davam edin.</p>
            </div>
          }
          
          </div>:null
        }
        <div className='flex flex-col gap-[20px] mt-[20px]'>
          {
            selectedUniAndPrograms?.status==1 && programs.length !=0?
            <h2  className='text-[18px] mb-[-10px] font-[600] max-[600px]:text-center'>Əlavə uyğun ixtisaslar</h2>:null
          }
        
          {
            [...Object.values(result)].sort((a,b)=>a[0]?.local_ranking - b[0]?.local_ranking)?.map((data, i)=>{
              if(this.checkVisible(data, this.exceptForPrograms(selectedUniAndPrograms?.programs))){
                return(
                  <Card9 key={i} 
                  
                      data={data} 
                      degree={data?.degree?.name}
                      university={data?.university?.name}
                      btn={true}
                      onClickBtn={(id)=>this.onClickApply(id)}
                      btnDisable={btnDisable}
                      onRedirectBtn={this.redirectPage.bind(this)}
                      exceptFor={this.exceptForPrograms(selectedUniAndPrograms?.programs)}
                      onSearch={this.onSearchData.bind(this)}
                      searchVisible={true}
                  />
                )
              }
            })
          }
          </div>

          {/* {
                programs?.length >=20?
                <LoadMoreBtn onLoadData={this.loadMore} count={programs?.length} />:null
            } */}
            {/* <LoadMoreBtn defCount={15} onLoadData={this.loadMore} count={programs?.length} /> */}
            {/* {
              page <=Math.ceil(page_count/4)?
              <button onClick={this.loadMore.bind(this, page+1)}>Load</button>:null
            }
             */}
      </div>
    )
  }
}
const mapStateToProps = (state) =>({
  user: state.Data.user,
  selectedUniAndPrograms: state.Data.selectedUniAndPrograms,
});
const mapDispatchToProps = {insertNewData, getStudentPrograms, getStudentProgramAndUni}
export default connect(mapStateToProps, mapDispatchToProps)(withHooks(ProgramsPage))
