import React, { Component } from 'react'
import Header from '../../components/header/Header'
import Search from '../../components/Search'
import Buttons from '../../components/Buttons'
import { connect } from 'react-redux'
import { changeStateValue } from '../../redux/MainReducer';
import { getBlogs } from '../../actions/MainAction';
import Card1 from '../../components/cards/Card1'
export class AdminBlogsPage extends Component {
 
  openModal=(data, lang)=>{
    // console.log(lang)
    this.props.changeStateValue({
      name:'adminModal',
      value:{
        position: true,
        id: data?.id?data?.id:'',
        title: data?.title?data?.title:'',
        image: data?.image?data?.image:'',
        image_full_url: data?.image_full_url?data?.image_full_url:'',
        review: data?.review?data?.review:'',
        path: data?.path?data?.path:'',
        lang_id: data?.lang_id?data?.lang_id:lang?.value,

        seo_title: data?.seo_title?data?.seo_title:'',
        seo_description: data?.seo_description?data?.seo_description:'',
        seo_keywords: data?.seo_keywords?data?.seo_keywords:'',
      
        afterCallFunction:this.props.getBlogs,
        afterCallParams:[],
        requireds: ["title", "review"],
        url:'blog',
        modalTitle:`${data?.id?'Edit':'Add'} blog`
      }
    })
  }

  componentDidMount(){
    this.props.getBlogs('', '')
  }

  afterCallFunction=()=>{
    this.props.getBlogs()
  }
  render() {
    const {blogs} = this.props;
  
    return (
        <div className='w-full  pl-[27px] pr-[27px]'>
            <Header title="blogs" />
            <div className='flex justify-end mt-[30px]'>
                <Buttons onClick={this.openModal.bind(this, '')} padding={true} options={[{title:"Add a blog AZ", value:1}, {title:"Add a blog EN", value:2}, {title:"Add a blog RU", value:3}, {title:"Add a blog TR", value:4}]} selected={5} />
            </div>
           

            <div className='grid-full-250 mt-[30px]'>
              {
                blogs?.map((data, i)=>{
                  return(
                    <Card1 
                      key={i}
                      image={data?.image_full_url}
                      name={data?.title}
                      onClickEdit={this.openModal.bind(this, data)}
                      deletePath={`blog/${data?.id}`}
                      afterCallFunction={this.afterCallFunction.bind(this)}
                    />
                  )
                })
              }
            </div>
           
        </div>
    )
  }
}
const mapStateToProps = (state) =>({
  blogs: state.Data.blogs
});
const mapDispatchToProps = {changeStateValue, getBlogs}
export default connect(mapStateToProps, mapDispatchToProps)(AdminBlogsPage)





