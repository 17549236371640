import React, { Component } from 'react'
import Header from '../../components/header/Header'
import WishList from '../teacher_role/students/pages/WishList'

export class StudentWishListPage extends Component {
  render() {
    return (
        <div className='w-full  pl-[27px] pr-[27px]'>
            <Header title={"Proqram sorğusu"} />
            <div className='mt-[40px]'>
                <WishList />
            </div>
        </div>
    )
  }
}

export default StudentWishListPage
