import React, { Component } from 'react'
import SectionHeader from '../../components/public_components/SectionHeader'
import BookingCalendarSection from './sections/BookingCalendarSection'
import { Helmet } from 'react-helmet'
import { connect } from 'react-redux'
import { changeStateValue } from '../../redux/MainReducer'
import { withHooks } from '../../actions/withHooks'
import { getSeoPageData } from '../../actions/MainAction'
import { getTranslateLanguage } from '../../actions/getOtherFunctions'

export class BookingPage extends Component {
  componentDidMount(){
    window.scrollTo(0,0);
    this.props.getSeoPageData("booking_page")
    let url = window.location.search;
    if(url){
      try{
        function getQueryParams(url) {
          const query = url.substring(1); // Remove the leading "?"
          const params = new URLSearchParams(query);
          const result = {};
          for (const [key, value] of params.entries()) {
              // Decode the base64-encoded values
              result[key] = atob(value);
          }
          return result;
        }
      
        const jsonData = getQueryParams(url);

        if(jsonData){
          this.props.changeStateValue({  name:"bookingUserInfo.name",  value: jsonData?.name  });
          this.props.changeStateValue({  name:"bookingUserInfo.email",  value: jsonData?.email  });
          this.props.changeStateValue({  name:"bookingUserInfo.phone_number",  value: jsonData?.phone });
          this.props.changeStateValue({  name:"bookingUserInfo.day",  value: ""  });
          this.props.changeStateValue({  name:"bookingUserInfo.time",  value: ""  });
        }
       setTimeout(()=>{
        this.props.navigate(window.location.pathname)
       }, 200)
      // console.log(jsonData);
      }catch(err){
        // console.log(err)
      }
    }
  }
  render() {
    const {seo_page_data} = this.props;
    return (
        <section className='mt-[80px] bg-[#F6F8FB]'>
          {
            seo_page_data !=""?
            <Helmet>
              <title>{getTranslateLanguage(this.props.lang_id, "seo_title", seo_page_data)}</title>
              <meta property="og:locale" content="az_AZ" />
              <meta property="og:type" content="website" />
              <meta property="og:title" content={getTranslateLanguage(this.props.lang_id, "seo_title", seo_page_data)} />
              <meta property="og:description" content={getTranslateLanguage(this.props.lang_id, "seo_description", seo_page_data)} />
              <meta name="description" content={getTranslateLanguage(this.props.lang_id, "seo_description", seo_page_data)}/>
              <meta name="keywords" content={getTranslateLanguage(this.props.lang_id, "seo_keywords", seo_page_data)}/>
              <meta property="og:url" content={`https://celtgo.com${window.location.pathname}`} />
              <meta property="og:site_name" content="CELT Colleges" />
              <meta property="article:publisher" content="https://www.facebook.com/celtscollege" />
              <meta property="article:modified_time" content="2024-06-06T08:56:25+00:00" />
              <meta property="og:image" content="https://celtgo.com/static/media/celtLogo6.05ddd884642befe11a4e.png" />
              <link rel="canonical" href={`https://celtgo.com${window.location.pathname}`} />
              <meta property="og:image:width" content="900" />
              <meta property="og:image:height" content="600" />
              <meta property="og:image:type" content="image/jpeg" />
              <meta name="twitter:card" content="summary_large_image" />
              <meta name="twitter:title" content={getTranslateLanguage(this.props.lang_id, "seo_title", seo_page_data)} />
              <meta name="twitter:description" content={getTranslateLanguage(this.props.lang_id, "seo_description", seo_page_data)} />
              <meta name="twitter:site" content="@celtcolleges" />
              <meta name="twitter:label1" content="Est. reading time" />
              <meta name="twitter:data1" content="7 minutes" />
            </Helmet>:null
          }
           
            <div className='pt-[40px]'>
                <SectionHeader headingType="h1" text1={this.props.language?.section_title6} text2="" />
                <div className='max-w-[1200px] ml-auto mr-auto max-[1240px]:ml-[20px] max-[1240px]:mr-[20px] mt-[40px]'>
                  <div className='bg-white p-[20px] rounded-[5px]'>
                      <BookingCalendarSection />
                  </div>
                </div>
            </div>
        </section>
    )
  }
}
const mapStateToProps = (state) =>({
  language: state.Data.language,
  seo_page_data: state.Data.seo_page_data,
  lang_id: state.Data.lang_id,
});
const mapDispatchToProps = {changeStateValue, getSeoPageData}
export default connect(mapStateToProps, mapDispatchToProps)(withHooks(BookingPage))
