import React, { Component } from 'react'
import CheckRoundedSvg from '../svg/CheckRoundedSvg';

export class SelectBtn extends Component {
  render() {
    const {img="", title="", check, onClick=()=>{}} = this.props;
    return (
      <button onClick={()=>onClick()} className={`h-[50px] shadow rounded-[8px] p-[0_16px] gap-[10px] flex items-center border justify-between ${!check? "border-[#EEEEEE] bg-[#fff]":"border-[#1E398F] bg-[#F4F3FF]"}`}>
        <div className='flex items-center gap-[17px]'>
            {img !=""? <img className='w-[36px] h-[36px] min-w-[36px] rounded-full' alt={title} src={img} />:null}
            <span className='text-[13px] font-[600]'>{title?.length >28? `${title?.substring(0,28)}...`:title}</span>
        </div>
        <CheckRoundedSvg color={check?"#1E398F":"#ABABAB"} />
      </button>
    )
  }
}

export default SelectBtn
