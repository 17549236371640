import React, { Component } from 'react'
import SuccessSvg from '../components/public_components/svg/SuccessSvg'
import { changeStateValue } from '../redux/MainReducer';
import { connect } from 'react-redux';
import { withHooks } from '../actions/withHooks';
import { insertNewData } from '../actions/MainAction';
import { mixinAlert } from '../actions/SweetAlerts';

export class UnSubscribeEmail extends Component {
    componentDidMount(){
        let url = window.location.search;
        if(url){
            try{
                function getQueryParams(url) {
                const query = url.substring(1); // Remove the leading "?"
                const params = new URLSearchParams(query);
                const result = {};
                for (const [key, value] of params.entries()) {
                    // Decode the base64-encoded values
                    result[key] = atob(value);
                }
                return result;
                }
            
                const jsonData = getQueryParams(url);

                if(jsonData){
                    this.props.insertNewData("unsubscribe-email", {email: jsonData?.dataset})
                    .then(resp=>{
                        // console.log(resp)
                        this.props.navigate("/")
                        mixinAlert("success", 'Unsubscribe successfully');
                    })
                }
            // setTimeout(()=>{
            //     this.props.navigate(window.location.pathname)
            // }, 200)
            // console.log(jsonData);
            }catch(err){
                // console.log(err)
            }
        }
    }
  render() {
    return (
      <div className='w-full h-screen flex justify-center items-center'>
            <div className='w-[300px] flex flex-col gap-[10px] bg-white p-[20px] items-center shadow rounded-[10px]'>
                <SuccessSvg size={90} />
                <p>Unsubscribe email</p>
            </div>
      </div>
    )
  }
}
const mapStateToProps = (state) =>({

});
const mapDispatchToProps = {insertNewData}
export default connect(mapStateToProps, mapDispatchToProps)(withHooks(UnSubscribeEmail))
