import React, { Component } from 'react'
import { getStaffStudentInfo, insertNewData } from '../../actions/MainAction';
import { connect } from 'react-redux';
import Header from '../../components/header/Header';
import Card1 from '../../components/cards/Card1';
import defUserImg from "../../images/def_user.png"
import moment from 'moment';
import StudenMoreInfoModal from '../../components/modals/StudenMoreInfoModal';
import { changeStateValue } from '../../redux/MainReducer';
import { withHooks } from '../../actions/withHooks';
import { confirmAlert, mixinAlert } from '../../actions/SweetAlerts';
export class StaffStudentInfo extends Component {
    state={
        type:"document_send"
    }
    componentDidMount(){
        this.props.getStaffStudentInfo("document_send");
    }
    getData=(page)=>{
        this.setState({type:page})
        this.props.getStaffStudentInfo(page);
    }
    openModal=(data)=>{
        this.props.navigate(`/students/${data==""?"create":data?.id}`)
       
    }
    onClickMore=(data)=>{
        let obj = {
          position: true,
          email: data?.email,
          phone_number: data?.phone_number,
          register_type: data?.edu_back?.finish_year?"Education plan":"Apply",
          registerDate: moment(data?.created_at)?.format("DD/MM/YYYY"),
          verify_type: data?.verify_type
        }
        this.props.changeStateValue({
          name:"studentMoreInfoModalData",
          value: obj
        })
    
      }

      onClickStatus=(id)=>{
        confirmAlert("warning", "Əminsiniz?", "Son status dəyişimi bu günün tarixi olacaq!", "Bəli", "Xeyir")
        .then(alertResp=>{
            if(alertResp === "success"){
              this.props.insertNewData(`staff-student-status`, {student_id:id})
              .then(resp=>{
                if(resp == "success"){
                  mixinAlert("success", "Status tarixi uğurla dəyişdirildi");
                  this.props.getStaffStudentInfo(this.state.type)
                }else{
                  mixinAlert("error", "Xəta baş verdi")
                }
              })
            }
        })
      }
  render() {
    const {studentinfo_for_staff, user, studentMoreInfoModalData} = this.props;
    const {type} = this.state;
    // 
    // console.log(studentinfo_for_staff)
    return (
        <div className='w-full  pl-[27px] pr-[27px]'>
            <Header title="Zəng ediləcək tələbələr" />
            <div className='flex flex-wrap gap-[20px] items-center mt-[20px]'>
                <button onClick={this.getData.bind(this, "document_send")} className={`${type === "document_send"?"bg-blue text-white":"bg-white border border-blue-600 txt-blue"} text-[14px] font-[500] rounded-[5px] p-[5px_10px]`}>Sənəd göndərilənlər</button>
                <button onClick={this.getData.bind(this, "not_apply")} className={`${type === "not_apply"?"bg-blue text-white":"bg-white border border-blue-600 txt-blue"} text-[14px] font-[500] rounded-[5px] p-[5px_10px]`}>Müraciət etməyənlər</button>
                <button onClick={this.getData.bind(this, "not_offer")} className={`${type === "not_offer"?"bg-blue text-white":"bg-white border border-blue-600 txt-blue"} text-[14px] font-[500] rounded-[5px] p-[5px_10px]`}>Təklifi olmayanlar</button>
            </div>
            <div className='grid-full-250 mt-[30px]'>
            {
                studentinfo_for_staff?.map((data, i)=>{
                  return(
                    <Card1
                      key={i}
                      image={data?.image_full_url? data?.image_full_url:defUserImg}
                      name={data?.full_name}
                      onClickEdit={this.openModal.bind(this, data)}
                   
                      moreLink={`/student-info/${data?.id}`}
                      username={"İstifadəçi adı: "+data?.username}
                      relatedUser={data?.related_user?.staff?.full_name}
                      relatedUserManager={data?.related_user?.staff?.related_user?.staff?.full_name}
                      changeStaffBtn={false}
                      moreInfo={user?.role == "xtm" || user?.role == "manager"?true:false}
                      onClickMore={this.onClickMore.bind(this, data)}
                      date={moment(data?.created_at).format("DD/MM/YYYY")}
                      program={data?.status_track}
                      onClickStatus={this.onClickStatus.bind(this, data?.id)}
                      staffStatusBtn={true}
                      staffStatusDate={data?.staff_status?.date}
                    />
                  )
                })
              }
            </div>
            {studentMoreInfoModalData?.position? <StudenMoreInfoModal />:null }
        </div>
    )
  }
}
const mapStateToProps  =(state) => ({
    studentinfo_for_staff: state.Data.studentinfo_for_staff,
    user: state.Data.user,
    studentMoreInfoModalData: state.Data.studentMoreInfoModalData,
});
const mapDispatchToProps = {getStaffStudentInfo, changeStateValue, insertNewData}
export default connect(mapStateToProps, mapDispatchToProps)(withHooks(StaffStudentInfo))
