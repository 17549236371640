export const az_lang = {
    nav_1: "Ölkələr",
    nav_2: "Randevu Al",
    nav_3: "Tələbələr",
    nav_4: "Universitetlər",
    nav_5: "Əlaqə",
    nav_6: "İxtisas sahələri",
    nav_7: "Qəbul olmuş tələbələr",
    nav_8: "Bloglar",


    login_btn:"Giriş ET",
    sub_title:"Xaricdə Təhsil Platforması",
    heading: "Xaricdə Təhsilinizi CELT.go ilə alın.",
    title: "Təhsil məlumatlarınızı daxil etməklə siz dünyanın ən yaxşı xarici universitetlərindən ixtisas təklifini alırsınız. Təhsil planı yaradaraq, siz xaricdə təhsil almaq üçün xüsusi yol xəritəsi əldə edirsiniz.",
    foreign_uni_for:"Xaricdəki universitetlər üçün",
    show_prog:"İxtisaslara bax",
    edu_plan:"Qəbul təklif al",
    no_fee_service: "Bu xidmətlər üçün heç bir ödəniş tələb edilmir.",

    section_title1: "Dövlət Təqaüdlü ölkələr",
    section_title2: "Universitetlər",
    section_title3: "Tələbələrimiz",
    section_title4: "İşlədiyimiz Ölkələr",
    section_title5: "Ən yaxşı ixtisaslar",
    section_title6: "Xaricdə Təhsil üçün Randevu",
    section_title7: "Ən yaxşı 20 Universitet",
    section_title8: "Ən yaxşı 300 Universitet",
    section_title9: "Bizimlə Əlaqə",

    university: "Universitet",
    uni_card_title1: "Dünyanın ən yaxşı 20 universiteti",
    uni_card_title2: "Dünyanın ən yaxşı 300 universiteti",
    uni_card_title3: "Dünyanın ixtisaslar üzrə ən yaxşı universitetləri ",
    accept_change_check: "Qəbul şansını yoxla",
    show_more: "Daha çox",
    show_less: "Daha az",

    booking_title:"Konsultasiya görüşü",
    booking_text: "Xaricdə təhsil almaq istəyirsinizsə konsultasiya görüşü alın, təhsil planınızı hazırlayaq.",
    booking_btn: "Randevu al",

    select_date_time: "Gün və tarixi seç",
    next: "Növbəti",

    name:"Adınız",
    surname:"Soyadınız",
    email:"Email",
    phon_number: "Mobil nömrə",
    your_phone_num:"Mobil nömrəniz",

    booking_desc: "Randevunun məqsədi. Öncədən hazırlıq üçün lazım olan məlumat.",
    accept_booking:"Görüşü təsdiqləyin",

   
    your_booking: "Sizin radevunuz",
    education_profession: "Təhsil Mütəxəssisi",
    booking_last_text:"Görüşü təsdiqlədiyiniz üçün təşəkkürlər. Tezliklə sizin mobil nömrənizlə əlaqə saxlanılacaq və görüşün daha yaxşı keçməsi üçün bəzi sualları dəqiqləşdirəcəyik.",
    
   

    at_the_country:"Ölkə üzrə",
    program_name:"İxtisas",
    deadline: "Son Müraciət",
    duration: "Müddət",

    uni_about: "Haqqında",
    country_sch_note: "Qeyd: bu səhifədə yalnız Azərbaycan dövlət təqaüd proqramına uyğun olan universitetlərə baxırsınız. Ölkənin bütün universitetlərinə _btn_ baxa bilərsiniz.",
    uni_sch_note: "Qeyd: bu səhifədə yalnız Azərbaycan dövlət təqaüd proqramına uyğun olan proqramalara baxırsınız. Universitetin bütün ixtisaslarınıa _btn_ baxa bilərsiniz.",
    here_btn:"buradan",

    year: "il",

    apply_title_1:"Hansı ölkədə və ya ölkələrdə oxumaq istəyirsiniz?",
    apply_title_2:"Hansı sahədə oxumaq istəyirsiniz?",
    apply_title_3: "Təhsil məlumatınız",
    apply_title_4: "Şəxsi məlumat",
    apply_title_5: "Hansı imtahan nəticəniz var?",


    study_country: "Təhsil aldığınız ölkə",
    last_edu_diploma:"Aldığınız və ya alacağınız diplom",
    grade_schmede_title:"Seçdiyiniz dərəcə üçün qiymət aralığı seçin",
    select_edu_diploma:"Təhsil diplomunu seçin",
    school_diploma:"Məktəb Diplomu",
    select_sch_diploma: "Məktəb diplomunu seçin",
    average_grade:"ortalama qiyməti",
    required_txt: "Anketi doldurmaq mütləqdir",
    example:"Məsələn",
    grade_select:"qiymətləri seçin",
    graduate_year: "Məktəbi hansı ildə bitirəcəksiniz",
    select_grad_year: "Bitirəcəyiniz ili seçin",
    graduated_already: "Bitirmişəm",
    grade_txt:"qiyməti",
    required_grade: "Qiyməti yazmaq mütləqdir",
    save_btn:"Yadda saxla",

    more_show:"Daha çox göstər",
    less_show:"Daha az göstər",
    next: "Növbəti",
    prev: "Geri",


    yearly_fee: "İllik təhsil haqqı",
    starting: "başlayır",

    at_world:"Dünya üzrə",
    most_popular_uni: "Ən güclü universitetlər",

    at_the_subject: "İxtisas üzrə",
    grade_predicted:"balınız (gözlənilən və ya son)",
    grade:"Qiymət",

    please_fill_all_inp: "Zəhmət olmasa bütün xanaları doldurun",
    select_atleas_country:"Ən azı 1 ölkə seçməlisiniz.",
    select_atleas_discipline:"Ən azı 1 sahə seçməlisiniz.",

    incorrect_phone:"Telefon nömrəniz doğru deyil!",
    incorrect_email: "Elektron poçt ünvanınız doğru deyil!",

    where_send_otp:"Təsdiq kodunu hara göndərək?",
    or:"Yoxsa",
    shouldbe_active_email:"Nömrə və ya email aktiv olmalıdır!",
    incorrect_otp:"Təsdiq kodu yanlışdır",


    send_otp_email:"Sizin email ünvanına 4 rəqəmli kod gələcək.",
    if_email_incorrect:"Email-iniz doğrudursa davam edin və ya da geri dönüş edib emailinizi düzgün daxil edin.",
    send_otp_wp: "Sizin mobil nömrənizin Whatsapp-ına 4 rəqəmli kod gələcək.",
    if_wp_incorrect: "Whatsapp nömrəniz doğrudursa davam edin, ya da geri dönüb nömrənizi düzgün daxil edin.",
    cont_btn_txt: "Davam et",
    back_btn_txt: "Ger dön",


    otp_shouldbe_4:"Təsdiq kodu 4 rəqəmli olmalıdır",
    close: "Bağla",
    accept_btn: "Təsdiqlə",
    notworking_change:" Alınmır? başqasına dəyiş.",

    change_password: "Şifrəni yenilə",


    booking_title_2:"Randevu",
    booking_text_2: "Xaricdə təhsil planlarınız barədə yol xəritəsinin hazırlanması və universitetlərin seçilməsi xidməti",
    boss:"Rəhbər",
    minute:"dəq",

    team:"Komandası",

    main_page_seo_title: "CELT.go Xaricdə təhsil mərkəzi",
    main_page_seo_desc: "CELTgo.com - Xaricdə təhsil imkanlarını kəşf edin! Dünyanın ən yaxşı universitetlərinə qəbul olun və qlobal karyera imkanlarına sahib olun. Bizimlə xaricdə təhsil almaq daha asandır!",
    main_page_seo_keyword: "xaricdə təhsil, xarici universitetlər, təhsil məsləhətçisi, universitet qəbulu, xaricdə magistratura, xaricdə bakalavr, təhsil proqramları, təhsil xaricdə, təhsil agentliyi",

    accepted_page_seo_title: "Qəbul Olmuş Tələbələr | CELTgo.com - Uğur Hekayələri",
    accepted_page_seo_desc: "CELTgo.com ilə xaricdə təhsil arzusunu gerçəkləşdirən tələbələrimizin uğur hekayələrini kəşf edin. Onların təcrübələri və nailiyyətləri ilə tanış olun.",
    accepted_page_seo_keyword: "qəbul olmuş tələbələr, uğur hekayələri, xaricdə təhsil uğurları, universitetə qəbul, tələbə təcrübələri, xaricdə təhsil nailiyyətləri, qəbul olma hekayələri",

    countries_page_seo_title: "Xaricdə Təhsil Üçün Ən Yaxşı Ölkələr | CELTgo.co",
    countries_page_seo_desc: "Xaricdə təhsil üçün ölkə seçimi edərkən doğru qərarı verin. CELTgo.com olaraq sizə ABŞ, Kanada, İngiltərə, Avstraliya, Almaniya və digər ölkələrdə təhsil imkanlarını təqdim edirik.",
    countries_page_seo_keyword: "xaricdə təhsil ölkələri, xaricdə təhsil üçün ən yaxşı ölkələr, təhsil üçün ölkə seçimi, ABŞ-da təhsil, Kanadada təhsil, İngiltərədə təhsil, Almaniyada təhsil, Avstraliyada təhsil, xaricdə təhsil proqramları",

    subj_page_seo_title: "Ən Yaxşı İxtisaslar | CELTgo.com - Karyera Üçün Ən Yaxşı Təhsil İxtisasları",
    subj_page_seo_desc: "CELTgo.com ilə karyera üçün ən perspektivli və tələb olunan ixtisasları kəşf edin. İxtisas seçimi edərkən doğru qərar verin və uğurlu gələcək üçün ilk addımı atın.",
    subj_page_seo_keyword: "ən yaxşı ixtisaslar, tələb olunan ixtisaslar, karyera üçün ixtisaslar, perspektivli ixtisaslar, xaricdə təhsil ixtisasları, ən perspektivli təhsil ixtisasları, gələcək üçün ixtisas seçimi, uğurlu karyera üçün ixtisaslar",

    booking_page_seo_title: "Xaricdə Təhsil üçün Randevu Alın | CELTgo.com - Məsləhət Üçün Görüş Təyin Edin",
    booking_page_seo_desc: "CELTgo.com ilə xaricdə təhsil məsləhəti üçün randevu alın. Peşəkar təhsil məsləhətçilərimizlə görüş təyin edərək, arzuladığınız universitetə qəbul olunmağınıza kömək edək.",
    booking_page_seo_keyword: "xaricdə təhsil üçün randevu, təhsil məsləhəti üçün görüş, universitet qəbulu məsləhəti, xaricdə təhsil görüşü, CELTgo randevu, təhsil məsləhətçisi ilə görüş, xaricdə təhsil üçün məsləhət",

    contact_page_seo_title: "Bizimlə Əlaqə | CELTgo.com - Xaricdə Təhsil Üçün Dəstək Alın",
    contact_page_seo_desc: "CELTgo.com ilə əlaqə saxlayın və xaricdə təhsil barədə bütün suallarınıza cavab tapın. Təhsil məsləhətçilərimiz hər zaman sizin yanınızdadır.",
    contact_page_seo_keyword: "bizimlə əlaqə, CELTgo əlaqə, təhsil dəstəyi, xaricdə təhsil əlaqə, təhsil məsləhətçisi ilə əlaqə, suallar və dəstək, xaricdə təhsil üçün yardım",

    uni_page_seo_title: "Top Universitetlər | CELTgo.com - Dünyanın Ən Yaxşı Universitetləri",
    uni_page_seo_desc: "CELTgo.com ilə dünyanın ən yaxşı universitetlərini kəşf edin. Təhsil və karyera məqsədləriniz üçün ideal universitetlər haqqında ətraflı məlumat əldə edin.",
    uni_page_seo_keyword: "top universitetlər, ən yaxşı universitetlər, dünyanın ən yaxşı universitetləri, xaricdə təhsil universitetləri, ən yüksək reytinqli universitetlər, təhsil üçün universitet seçimi, CELTgo universitetlər",

    top_20_page_seo_title: "Ən Yaxşı 20 Universitet | CELTgo.com - Dünyanın Top 20 Universiteti",
    top_20_page_seo_desc: "CELTgo.com ilə dünyanın ən yaxşı 20 universitetini kəşf edin. Təhsil keyfiyyəti və global reytinq baxımından ən önəmli universitetlər haqqında ətraflı məlumat əldə edin.",
    top_20_page_seo_keyword: "ən yaxşı 20 universitet, top 20 universitet, dünyanın ən yaxşı universitetləri, xaricdə təhsil üçün ən yaxşı universitetlər, top universitetlər 2024, tələbə reytinqləri, CELTgo universitetlər",

    top_300_page_seo_title: "Ən Yaxşı 300 Universitet | CELTgo.com - Dünyanın Top 300 Universiteti",
    top_300_page_seo_desc: "CELTgo.com ilə dünyanın ən yaxşı 300 universitetini kəşf edin. Təhsil keyfiyyəti və qlobal reytinq baxımından ən önəmli universitetlər haqqında geniş məlumat əldə edin.",
    top_300_page_seo_keyword: "ən yaxşı 300 universitet, top 300 universitet, dünyanın ən yaxşı universitetləri, xaricdə təhsil üçün universitetlər, 300 ən yüksək reytinqli universitet, universitet reytinqləri, CELTgo universitetlər",

    login: "Giriş edin",
    password: "Şifrə",
    forgot_psw: "Şifrəni unutmsunuz?",
    email_psw_inc: "Email və ya şifrəniz səhvdir!",
    not_acc: "Hesabınız yoxdur?",
    create_new: "Yenisini yaradın",

    change_psw_device: "Şifrənizi qeydiyyatdan keçdiyiniz vasitə ilə dəyişin.",
    send_txt: "Göndər",
    return_login: "Loginə geri dön",

    not_page: "AXTADIĞINIZ SƏHİFƏ YOXDUR.",
    return_main_page: "Əsas səhifəyə geri dön",
    login_btn_txt: "Daxil olun",

    code_to_send_wp:"Nömrənizə gələn kodu daxil edin.",
    code_to_send_email: "Emailinizə gələn kodu daxil edin.",
    faq_title: "Tez-tez verilən suallar",

    your_full_name:"Adınız",



    sch_country_page_seo_title:"Dövlət Təqaüdlü Ölkələrdə Təhsil – CELTgo ilə Xaricdə Təhsil İmkanları",
    sch_country_page_seo_description: "Dövlət təqaüdlü ölkələrdə təhsil alın və karyera yolunuzu gücləndirin! CELTgo ilə xaricdə pulsuz təhsil imkânlarından faydalanın. Dünya səviyyəsində tanınan universitetlər və ölkələrin təqaüdləri haqqında ətraflı məlumat əldə edin.",
    sch_country_page_seo_keywords: "dövlət təqaüdlü ölkələr, xaricdə təhsil, təqaüdlü təhsil proqramları, pulsuz təhsil, CELTgo, təqaüd proqramları, xaricdə universitetlər, təqaüdlər, beynəlxalq təqaüd",


    grade_scheme_req: "Qiymət 50 dən yüksək olmalıdır.",


    address:"CELT Xaricdə Təhsil Mərkəzi, Xocalı prospekti 14, 1025, Xətai, Bakı, Azərbaycan",
    contact_help_text:"Bizdən soruşun. Biz kömək etmək üçün buradayıq!",
    form_name:"Ad",
    form_last_name:"Soyad",
    form_phone:"Nömrə",
    form_note:"Qeyd"
  } 

export const en_lang = {
    "nav_1": "Countries",
    "nav_2": "Book a meeting",
    "nav_3": "Students",
    "nav_4": "Universities",
    "nav_5": "Contact",
    "nav_6": "Fields of Study",
    "nav_7": "Accepted Students",
      nav_8: "Blogs",
    "login_btn": "Login",
    "sub_title": "Global Study Platform",
    "heading": "Global Education with CELT.go.",
    "title": "By entering your education information, you get a specialization offer from the world's best foreign universities. By creating an education plan, you get a special roadmap to study abroad.",
    "foreign_uni_for": "For International Universities",
    "show_prog": "View Programs",
    "edu_plan": "Get Offer",
    "no_fee_service": "No fees are required for these services.",
    "section_title1": "Countries with Government Scholarships",
    "section_title2": "Universities",
    "section_title3": "Our Students",
    "section_title4": "Countries We Work With",
    "section_title5": "Top Specializations",
    "section_title6": "Study Abroad Consultation",
     "section_title7": "Top 20 Universities",
  "section_title8": "Top 300 Universities",
  "section_title9": "Contact Us",
    "university": "University",
    "uni_card_title1": "Top 20 Universities in the World",
     "uni_card_title2": "Top 300 Universities in the World",
    "uni_card_title3": "Best Universities in the World by Specializations",
    "accept_change_check": "Get Accepted",
    "show_more": "Show More",
    "show_less": "Show Less",
    "booking_title": "Consultation Appointment",
    "booking_text": "If you want to study abroad, get a consultation appointment, and let's prepare your education plan.",
    "booking_btn": "Make an Appointment",
    "select_date_time": "Select Date and Time",
    "next": "Next",
    "name": "Your Name",
    "surname": "Your Surname",
    "email": "Email",
    "phon_number": "Mobile Number",
    "your_phone_num": "Your Mobile Number",
    "booking_desc": "Purpose of Appointment. Information needed for preparation.",
    "accept_booking": "Schedule a meeting",
    "your_booking": "Your Appointment",
    "education_profession": "Education Specialist",
    "booking_last_text": "Thank you for confirming the appointment. We will contact your mobile number soon, and clarify some questions to ensure the meeting goes smoothly.",
    "at_the_country": "By Country",
    "program_name": "Program",
    "deadline": "Deadline",
    "duration": "Duration",
    "uni_about": "About",
    "country_sch_note": "Note: On this page, you are only viewing universities eligible for the Azerbaijan government scholarship program. You can view all universities in the country _btn_.",
    "uni_sch_note": "Note: On this page, you are only viewing programs eligible for the Azerbaijan government scholarship program. You can view all specializations at the university _btn_.",
    "here_btn": "here",
    "year": "year",
    "apply_title_1": "In which country or countries would you like to study?",
    "apply_title_2": "In which field would you like to study?",
    "apply_title_3": "Your Education Information",
    "apply_title_4": "Personal Information",
    "apply_title_5": "What Exam Results Do You Have?",
    "study_country": "Country of Study",
    "last_edu_diploma": "Your Latest Diploma",
    grade_schmede_title:"Select a grade range for the degree you choose",
    "select_edu_diploma": "Select Education Diploma",
    "school_diploma": "School Diploma",
    "select_sch_diploma": "Select School Diploma",
    "average_grade": "Average Grade",
    "required_txt": "The form must be filled out",
    "example": "For Example",
    "grade_select": "Select Grades",
    "graduate_year": "Which year will you graduate?",
    "select_grad_year": "Select Graduation Year",
    "graduated_already": "I Graduated",
    "grade_txt": "grade",
    "required_grade": "Entering a grade is required",
    "save_btn": "Save",
    "more_show": "Show More",
    "less_show": "Show Less",
    "next": "Next",
    "prev": "Previous",

    "yearly_fee": "Annual Tuition Fee",
    "starting": "starting",
    "at_world": "Worldwide",
    "most_popular_uni": "Top Universities",

    "at_the_subject": "By Subject",
    "grade_predicted": "your grade (predicted or final)",
    "grade": "Grade",
     "please_fill_all_inp": "Please fill in all the fields",

     "select_atleas_country": "You must select at least one country.",
  "select_atleas_discipline": "You must select at least one field.",

  "incorrect_phone": "Your phone number is incorrect!",
  "incorrect_email": "Your email address is incorrect!",
  "where_send_otp": "Where should we send the verification code?",
   "or": "Or",
   "shouldbe_active_email": "The phone number or email must be active!",
   "incorrect_otp": "The verification code is incorrect",
   "send_otp_email": "A 4-digit code will be sent to your email address.",
  "if_email_incorrect": "If your email is correct, continue, or go back and enter your correct email.",
  "send_otp_wp": "A 4-digit code will be sent to your WhatsApp number.",
  "if_wp_incorrect": "If your WhatsApp number is correct, continue, or go back and enter your correct number.",
  "cont_btn_txt": "Continue",
  "back_btn_txt": "Go back",
  "otp_shouldbe_4": "The verification code must be 4 digits",
  "close": "Close",
  "accept_btn": "Confirm",
  "notworking_change": "Not working? Change it to another.",
   "change_password": "Change password",
   "booking_title_2": "Appointment",
  "booking_text_2": "Service for preparing a roadmap for your overseas education plans and selecting universities",
  "boss": "Director",
  "minute": "min",
  "team": "Team",

  "main_page_seo_title": "CELT.go Study Abroad Center",
  "main_page_seo_desc": "CELTgo.com - Discover study abroad opportunities! Get accepted to the world's best universities and gain global career opportunities. Studying abroad with us is easier!",
  "main_page_seo_keyword": "study abroad, foreign universities, education consultant, university admission, master's abroad, bachelor's abroad, education programs, education abroad, education agency",

  "accepted_page_seo_title": "Accepted Students | CELTgo.com - Success Stories",
  "accepted_page_seo_desc": "Discover the success stories of students who realized their dreams of studying abroad with CELTgo.com. Learn about their experiences and achievements.",
  "accepted_page_seo_keyword": "accepted students, success stories, study abroad successes, university admission, student experiences, study abroad achievements, acceptance stories",

  "countries_page_seo_title": "Best Countries for Study Abroad | CELTgo.com",
  "countries_page_seo_desc": "Make the right decision when choosing a country for study abroad. At CELTgo.com, we present educational opportunities in the USA, Canada, UK, Australia, Germany, and other countries.",
  "countries_page_seo_keyword": "study abroad countries, best countries for study abroad, country selection for education, study in the USA, study in Canada, study in the UK, study in Germany, study in Australia, study abroad programs",

  "subj_page_seo_title": "Best Specializations | CELTgo.com - Best Education Specializations for Career",
  "subj_page_seo_desc": "Discover the most promising and in-demand specializations for your career with CELTgo.com. Make the right decision when choosing a specialization and take the first step toward a successful future.",
  "subj_page_seo_keyword": "best specializations, in-demand specializations, specializations for career, promising specializations, study abroad specializations, most promising education specializations, specialization selection for the future, specializations for a successful career",

  "booking_page_seo_title": "Make an Appointment for Study Abroad | CELTgo.com - Schedule a Consultation",
  "booking_page_seo_desc": "Make an appointment for study abroad consultation with CELTgo.com. Schedule a meeting with our professional education consultants and let us help you get accepted to your desired university.",
  "booking_page_seo_keyword": "appointment for study abroad, consultation for education, university admission consultation, study abroad meeting, CELTgo appointment, meeting with education consultant, study abroad advice",

  "contact_page_seo_title": "Contact Us | CELTgo.com - Get Support for Study Abroad",
  "contact_page_seo_desc": "Contact CELTgo.com and find answers to all your questions about studying abroad. Our education consultants are always here for you.",
  "contact_page_seo_keyword": "contact us, CELTgo contact, education support, study abroad contact, contact with education consultant, questions and support, help for study abroad",

  "uni_page_seo_title": "Top Universities | CELTgo.com - The Best Universities in the World",
  "uni_page_seo_desc": "Discover the best universities in the world with CELTgo.com. Get detailed information about ideal universities for your education and career goals.",
  "uni_page_seo_keyword": "top universities, best universities, the best universities in the world, study abroad universities, highest-rated universities, university selection for education, CELTgo universities",

  "top_20_page_seo_title": "Top 20 Universities | CELTgo.com - The World's Top 20 Universities",
  "top_20_page_seo_desc": "Discover the top 20 universities in the world with CELTgo.com. Get detailed information about the most important universities in terms of education quality and global ranking.",
  "top_20_page_seo_keyword": "top 20 universities, world's top 20 universities, best universities in the world, best universities for study abroad, top universities 2024, student rankings, CELTgo universities",

  "top_300_page_seo_title": "Top 300 Universities | CELTgo.com - The World's Top 300 Universities",
  "top_300_page_seo_desc": "Discover the top 300 universities in the world with CELTgo.com. Get extensive information about the most important universities in terms of education quality and global ranking.",
  "top_300_page_seo_keyword": "top 300 universities, best 300 universities, world's best universities, universities for study abroad, 300 highest-rated universities, university rankings, CELTgo universities",

  "login": "Log In",
  "password": "Password",
  "forgot_psw": "Forgot Password?",
  "email_psw_inc": "Email or password is incorrect!",
  "not_acc": "Don't have an account?",
  "create_new": "Create a new one",

  "change_psw_device": "Change your password using the device you registered with.",
  "send_txt": "Send",
  "return_login": "Return to Login",

  "not_page": "THE PAGE YOU ARE LOOKING FOR DOES NOT EXIST.",
  "return_main_page": "Return to the main page",

  "login_btn_txt": "Log in",
  "code_to_send_wp": "Enter the code sent to your phone.",
"code_to_send_email": "Enter the code sent to your email.",
faq_title: "Frequently Asked Questions",

 your_full_name:"Your full name",


 sch_country_page_seo_title: "Study in State Scholarship Countries – Education Abroad with CELTgo",
sch_country_page_seo_description: "Study in countries with state scholarships and boost your career! Take advantage of free education opportunities abroad with CELTgo. Get detailed information about scholarships from globally recognized universities and countries.",
sch_country_page_seo_keywords: "state scholarship countries, education abroad, scholarship programs, free education, CELTgo, scholarship programs, universities abroad, scholarships, international scholarships",

   grade_scheme_req: "The grade must be higher than 50.",
   address:"CELT Study Abroad Center, Khojaly Avenue 14, 1025, Khatai, Baku, Azerbaijan",
   contact_help_text:"Ask us. We are here to help!",

   form_name:"First Name",
   form_last_name:"Last Name",
   form_phone:"Phone Number",

   form_note: "Note"
}

export const tr_lang = {
    "nav_1": "Ülkeler",
    "nav_2": "Randevu Al",
    "nav_3": "Öğrenciler",
    "nav_4": "Üniversiteler",
    "nav_5": "İletişim",
    "nav_6": "İhtisas Alanları",
    "nav_7": "Kabul Olmuş Öğrenciler",
    nav_8: "Bloglar",
    "login_btn": "Giriş Yap",
    "sub_title": "Yurtdışında Eğitim Platformu",
    "heading": "Yurtdışında Eğitim Alın.",
    "title": "Eğitim bilgilerinizi girerek, dünyanın en iyi yabancı üniversitelerinden uzmanlık teklifi alırsınız. Eğitim planı oluşturarak, yurtdışında eğitim almak için özel bir yol haritası elde edersiniz.",
    "foreign_uni_for": "Yurtdışındaki Üniversiteler İçin",
    "show_prog": "Programları Gör",
    "edu_plan": "Kabul Teklifi Al",
    "no_fee_service": "Bu hizmetler için hiçbir ücret talep edilmez.",
    "section_title1": "Devlet Burslu Ülkeler",
    "section_title2": "Üniversiteler",
    "section_title3": "Öğrencilerimiz",
    "section_title4": "Çalıştığımız Ülkeler",
    "section_title5": "En İyi İhtisaslar",
    "section_title6": "Yurtdışında Eğitim için Randevu",
     "section_title7": "En iyi 20 Üniversite",
  "section_title8": "En iyi 300 Üniversite",
  "section_title9": "Bizimle İletişim",
    "university": "Üniversite",
    "uni_card_title1": "Dünyanın En İyi 20 Üniversitesi",
    "uni_card_title2": "Dünyanın en iyi 300 üniversitesi",
    "uni_card_title3": "Dünyanın uzmanlıklar konusunda en iyi üniversiteleri",
    "accept_change_check": "Şansını dene",
    "show_more": "Daha Fazla",
    "show_less": "Daha Az",
    "booking_title": "Danışmanlık Görüşmesi",
    "booking_text": "Yurtdışında eğitim almak istiyorsanız danışmanlık görüşmesi alın, eğitim planınızı hazırlayalım.",
    "booking_btn": "Randevu Al",
    "select_date_time": "Gün ve Tarih Seç",
    "next": "Sonraki",
    "name": "Adınız",
    "surname": "Soyadınız",
    "email": "Email",
    "phon_number": "Cep Numarası",
    "your_phone_num": "Cep Numaranız",
    "booking_desc": "Randevunun Amacı. Önceden hazırlık için gerekli bilgiler.",
    "accept_booking": "Görüşmeyi Onaylayın",
    "your_booking": "Randevunuz",
    "education_profession": "Eğitim Uzmanı",
    "booking_last_text": "Görüşmeyi onayladığınız için teşekkürler. Yakında cep numaranız ile iletişim kurulacak ve görüşmenin daha iyi geçmesi için bazı sorular netleştirilecektir.",
    "at_the_country": "Ülkeye Göre",
    "program_name": "Program",
    "deadline": "Son Başvuru",
    "duration": "Süre",
    "uni_about": "Hakkında",
    "country_sch_note": "Not: Bu sayfada sadece Azerbaycan devlet burs programına uygun üniversitelere bakıyorsunuz. Ülkenin tüm üniversitelerine _btn_ bakabilirsiniz.",
    "uni_sch_note": "Not: Bu sayfada sadece Azerbaycan devlet burs programına uygun programlara bakıyorsunuz. Üniversitenin tüm uzmanlıklarına _btn_ bakabilirsiniz.",
    "here_btn": "buradan",
    "year": "yıl",
    "apply_title_1": "Hangi ülkede veya ülkelerde okumak istiyorsunuz?",
    "apply_title_2": "Hangi alanda okumak istiyorsunuz?",
    "apply_title_3": "Eğitim Bilginiz",
    "apply_title_4": "Kişisel Bilgiler",
    "apply_title_5": "Hangi sınav sonuçlarınız var?",
    "study_country": "Okuduğunuz Ülke",
    "last_edu_diploma": "En Son Eğitim Diplomanız",
    grade_schmede_title:"Seçtiğiniz derece için not aralığını seçin",
    "select_edu_diploma": "Eğitim Diplomasını Seçin",
    "school_diploma": "Okul Diploması",
    "select_sch_diploma": "Okul Diplomasını Seçin",
    "average_grade": "Ortalama Not",
    "required_txt": "Anketi doldurmak zorunludur",
    "example": "Örneğin",
    "grade_select": "Notları seçin",
    "graduate_year": "Okulu hangi yıl bitireceksiniz?",
    "select_grad_year": "Bitireceğiniz Yılı Seçin",
    "graduated_already": "Bitirdim",
    "grade_txt": "not",
    "required_grade": "Not yazmak zorunludur",
    "save_btn": "Kaydet",
    "more_show": "Daha Fazla Göster",
    "less_show": "Daha Az Göster",
    "next": "Sonraki",
    "prev": "Önceki",

    "yearly_fee": "İllik eğitim ücreti",
    "starting": "başlıyor",
    "at_world": "Dünya genelinde",
    "most_popular_uni": "En güçlü üniversiteler",

    "at_the_subject": "Bölümde",
    "grade_predicted": "notunuz (beklenen veya son)",

    "grade": "Not",
    "please_fill_all_inp": "Lütfen tüm alanları doldurun",

    "select_atleas_country": "En az bir ülke seçmelisiniz.",
  "select_atleas_discipline": "En az bir alan seçmelisiniz.",
  "incorrect_phone": "Telefon numaranız doğru değil!",
  "incorrect_email": "E-posta adresiniz doğru değil!",
  "where_send_otp": "Onay kodunu nereye gönderelim?",
  "or": "Yoksa",
  "shouldbe_active_email": "Numara veya e-posta aktif olmalıdır!",
  "incorrect_otp": "Onay kodu yanlış",

  "send_otp_email": "E-posta adresinize 4 haneli bir kod gönderilecek.",
  "if_email_incorrect": "E-posta adresiniz doğruysa devam edin, aksi halde geri dönüp e-posta adresinizi doğru girin.",
  "send_otp_wp": "Mobil numaranızın Whatsapp'ına 4 haneli bir kod gönderilecek.",
  "if_wp_incorrect": "Whatsapp numaranız doğruysa devam edin, aksi halde geri dönüp numaranızı doğru girin.",
  "cont_btn_txt": "Devam et",
  "back_btn_txt": "Geri dön",
  "otp_shouldbe_4": "Onay kodu 4 haneli olmalıdır",
  "close": "Kapat",
  "accept_btn": "Onayla",
  "notworking_change": "Çalışmıyor mu? Başkasıyla değiştir.",
   "change_password": "Şifreyi yenile",
   "booking_title_2": "Randevu",
   "booking_text_2": "Yurt dışı eğitim planlarınızla ilgili yol haritasının hazırlanması ve üniversite seçimi hizmeti",
   "boss": "Yönetici",
   "minute": "dak",
    "team": "Takım",

    "main_page_seo_title": "CELT.go Yurt Dışı Eğitim Merkezi",
    "main_page_seo_desc": "CELTgo.com - Yurt dışı eğitim fırsatlarını keşfedin! Dünyanın en iyi üniversitelerine kabul alın ve global kariyer fırsatlarına sahip olun. Bizimle yurt dışında eğitim almak daha kolay!",
    "main_page_seo_keyword": "yurt dışında eğitim, yabancı üniversiteler, eğitim danışmanı, üniversite kabulu, yurt dışında yüksek lisans, yurt dışında lisans, eğitim programları, yurtdışı eğitim, eğitim ajansı",

    "accepted_page_seo_title": "Kabul Edilen Öğrenciler | CELTgo.com - Başarı Hikayeleri",
    "accepted_page_seo_desc": "CELTgo.com ile yurt dışında eğitim hayalini gerçekleştiren öğrencilerimizin başarı hikayelerini keşfedin. Onların deneyim ve başarılarıyla tanışın.",
    "accepted_page_seo_keyword": "kabul edilen öğrenciler, başarı hikayeleri, yurt dışı eğitim başarıları, üniversite kabulu, öğrenci deneyimleri, yurt dışında eğitim başarıları, kabul hikayeleri",

    "countries_page_seo_title": "Yurt Dışı Eğitim İçin En İyi Ülkeler | CELTgo.com",
    "countries_page_seo_desc": "Yurt dışı eğitim için ülke seçimi yaparken doğru kararı verin. CELTgo.com olarak size ABD, Kanada, İngiltere, Avustralya, Almanya ve diğer ülkelerde eğitim imkanları sunuyoruz.",
    "countries_page_seo_keyword": "yurt dışı eğitim ülkeleri, yurt dışı eğitim için en iyi ülkeler, eğitim için ülke seçimi, ABD'de eğitim, Kanada'da eğitim, İngiltere'de eğitim, Almanya'da eğitim, Avustralya'da eğitim, yurtdışı eğitim programları",

    "subj_page_seo_title": "En İyi Bölümler | CELTgo.com - Kariyer İçin En İyi Eğitim Bölümleri",
    "subj_page_seo_desc": "CELTgo.com ile kariyer için en umut verici ve talep gören bölümleri keşfedin. Bölüm seçerken doğru kararı verin ve başarılı bir gelecek için ilk adımı atın.",
    "subj_page_seo_keyword": "en iyi bölümler, talep gören bölümler, kariyer için bölümler, umut verici bölümler, yurt dışında eğitim bölümleri, en umut verici eğitim bölümleri, gelecek için bölüm seçimi, başarılı kariyer için bölümler",

    "booking_page_seo_title": "Yurt Dışı Eğitim İçin Randevu Alın | CELTgo.com - Danışmanlık İçin Görüşme Ayarlayın",
    "booking_page_seo_desc": "CELTgo.com ile yurt dışı eğitim danışmanlığı için randevu alın. Profesyonel eğitim danışmanlarımızla görüşme ayarlayarak, hayal ettiğiniz üniversiteye kabul olmanıza yardımcı olalım.",
    "booking_page_seo_keyword": "yurt dışı eğitim için randevu, eğitim danışmanlığı için görüşme, üniversite kabulu danışmanlığı, yurt dışı eğitim görüşmesi, CELTgo randevu, eğitim danışmanı ile görüşme, yurt dışı eğitim için danışmanlık",

    "contact_page_seo_title": "Bizimle İletişime Geçin | CELTgo.com - Yurt Dışı Eğitim İçin Destek Alın",
    "contact_page_seo_desc": "CELTgo.com ile iletişime geçin ve yurt dışı eğitim ile ilgili tüm sorularınıza yanıt bulun. Eğitim danışmanlarımız her zaman sizin yanınızda.",
    "contact_page_seo_keyword": "bizimle iletişime geçin, CELTgo iletişim, eğitim desteği, yurt dışı eğitim iletişim, eğitim danışmanı ile iletişim, sorular ve destek, yurt dışı eğitim için yardım",

    "uni_page_seo_title": "En İyi Üniversiteler | CELTgo.com - Dünyanın En İyi Üniversiteleri",
    "uni_page_seo_desc": "CELTgo.com ile dünyanın en iyi üniversitelerini keşfedin. Eğitim ve kariyer hedefleriniz için ideal üniversiteler hakkında detaylı bilgi edinin.",
    "uni_page_seo_keyword": "en iyi üniversiteler, dünyanın en iyi üniversiteleri, yurt dışında eğitim üniversiteleri, en yüksek puanlı üniversiteler, eğitim için üniversite seçimi, CELTgo üniversiteleri",

    "top_20_page_seo_title": "En İyi 20 Üniversite | CELTgo.com - Dünyanın En İyi 20 Üniversitesi",
    "top_20_page_seo_desc": "CELTgo.com ile dünyanın en iyi 20 üniversitesini keşfedin. Eğitim kalitesi ve global sıralamalar açısından en önemli üniversiteler hakkında detaylı bilgi edinin.",
    "top_20_page_seo_keyword": "en iyi 20 üniversite, en iyi 20 üniversite, dünyanın en iyi üniversiteleri, yurt dışında eğitim için en iyi üniversiteler, en iyi üniversiteler 2024, öğrenci sıralamaları, CELTgo üniversiteleri",

    "top_300_page_seo_title": "En İyi 300 Üniversite | CELTgo.com - Dünyanın En İyi 300 Üniversitesi",
    "top_300_page_seo_desc": "CELTgo.com ile dünyanın en iyi 300 üniversitesini keşfedin. Eğitim kalitesi ve global sıralamalar açısından en önemli üniversiteler hakkında geniş bilgi edinin.",
    "top_300_page_seo_keyword": "en iyi 300 üniversite, en iyi 300 üniversite, dünyanın en iyi üniversiteleri, yurt dışında eğitim için üniversiteler, en yüksek puanlı 300 üniversite, üniversite sıralamaları, CELTgo üniversiteleri",

    "login": "Giriş Yap",
    "password": "Şifre",
    "forgot_psw": "Şifrenizi mi unuttunuz?",
    "email_psw_inc": "Email veya şifreniz hatalı!",
    "not_acc": "Hesabınız yok mu?",
    "create_new": "Yeni Hesap Oluşturun",

    "change_psw_device": "Kayıt olduğunuz cihaz ile şifrenizi değiştirin.",
    "send_txt": "Gönder",
    "return_login": "Girişe Dön",

    "not_page": "ARAMIŞ OLDUĞUNUZ SAYFA BULUNAMADI.",
    "return_main_page": "Ana Sayfaya Dön",
    "login_btn_txt": "Giriş Yap",
    "code_to_send_wp": "Telefonunuza gönderilen kodu girin.",
"code_to_send_email": "E-postanıza gönderilen kodu girin.",
faq_title: "Sıkça Sorulan Sorular",

 your_full_name:"Tam adınız",

 sch_country_page_seo_title: "Devlet Burslu Ülkelerde Eğitim – CELTgo ile Yurt Dışında Eğitim Fırsatları",
sch_country_page_seo_description: "Devlet burslu ülkelerde eğitim alın ve kariyerinizi güçlendirin! CELTgo ile yurt dışında ücretsiz eğitim fırsatlarından yararlanın. Dünyaca tanınmış üniversitelerden ve ülkelerin burs programları hakkında ayrıntılı bilgi edinin.",
sch_country_page_seo_keywords: "devlet burslu ülkeler, yurt dışında eğitim, burslu eğitim programları, ücretsiz eğitim, CELTgo, burs programları, yurt dışındaki üniversiteler, burslar, uluslararası burslar",

   grade_scheme_req: "Not 50'den yüksek olmalıdır.",

   address:"CELT Yurtdışı Eğitim Merkezi, Hocalı Caddesi 14, 1025, Hatai, Bakü, Azerbaycan",

   contact_help_text:"Bize sorun. Yardım etmek için buradayız!",

   form_name:"Ad",
   form_last_name:"Soyad",
   form_phone:"Telefon Numarası",
   form_note: "Not"
}
export const ru_lang = {
    "nav_1": "Страны",
    "nav_2": "Записаться на прием",
    "nav_3": "Студенты",
    "nav_4": "Университеты",
    "nav_5": "Контакт",
    "nav_6": "Направления обучения",
    "nav_7": "Принятые студенты",
    nav_8: "блоги",
    "login_btn": "Войти",
    "sub_title": "Платформа для обучения за границей",
    "heading": "Oбразование за границей с CELT.go.",
    "title": "Заполнив информацию об образовании, вы получите предложение по специальности от лучших иностранных университетов мира. Создав образовательный план, вы получите специальную дорожную карту для обучения за границей.",
    "foreign_uni_for": "Для иностранных университетов",
    "show_prog": "Посмотреть программы",
    "edu_plan": "Получить предложение о приеме",
    "no_fee_service": "Эти услуги предоставляются бесплатно.",
    "section_title1": "Страны с государственными стипендиями",
    "section_title2": "Университеты",
    "section_title3": "Наши студенты",
    "section_title4": "Страны, с которыми мы работаем",
    "section_title5": "Лучшие специальности",
    "section_title6": "Запись на обучение за границей",
     "section_title7": "20 лучших университетов",
  "section_title8": "300 лучших университетов",
  "section_title9": "Свяжитесь с нами",
    "university": "Университет",
    "uni_card_title1": "20 лучших университетов мира",
      "uni_card_title2": "300 лучших университетов мира",
  "uni_card_title3": "Лучшие университеты мира по специальностям",
    "accept_change_check": "Проверьте шанс на прием",
    "show_more": "Показать больше",
    "show_less": "Показать меньше",
    "booking_title": "Консультационная встреча",
    "booking_text": "Если вы хотите учиться за границей, запишитесь на консультацию, и мы подготовим ваш образовательный план.",
    "booking_btn": "Записаться на прием",
    "select_date_time": "Выберите дату и время",
    "next": "Далее",
    "name": "Ваше имя",
    "surname": "Ваша фамилия",
    "email": "Email",
    "phon_number": "Мобильный номер",
    "your_phone_num": "Ваш мобильный номер",
    "booking_desc": "Цель встречи. Информация, необходимая для подготовки.",
    "accept_booking": "Подтвердите встречу",
    "your_booking": "Ваша запись",
    "education_profession": "Образовательный специалист",
    "booking_last_text": "Спасибо за подтверждение встречи. Вскоре мы свяжемся с вами по мобильному номеру и уточним некоторые вопросы для успешного проведения встречи.",
    "at_the_country": "По странам",
    "program_name": "Программа",
    "deadline": "Срок подачи",
    "duration": "Продолжительность",
    "uni_about": "О",
    "country_sch_note": "Примечание: на этой странице вы смотрите только университеты, соответствующие программе государственной стипендии Азербайджана. Вы можете посмотреть все университеты страны _btn_.",
    "uni_sch_note": "Примечание: на этой странице вы смотрите только программы, соответствующие программе государственной стипендии Азербайджана. Вы можете посмотреть все специальности университета _btn_.",
    "here_btn": "здесь",
    "year": "год",
    "apply_title_1": "В какой стране или странах вы хотите учиться?",
    "apply_title_2": "В какой области вы хотите учиться?",
    "apply_title_3": "Ваша образовательная информация",
    "apply_title_4": "Личная информация",
    "apply_title_5": "Какие у вас результаты экзаменов?",
    "study_country": "Страна обучения",
    "last_edu_diploma": "Ваш последний диплом",
    grade_schmede_title:"Выберите диапазон оценок для выбранной вами степени",
    "select_edu_diploma": "Выберите диплом об образовании",
    "school_diploma": "Диплом школы",
    "select_sch_diploma": "Выберите школьный диплом",
    "average_grade": "Средний балл",
    "required_txt": "Форма должна быть заполнена",
    "example": "Например",
    "grade_select": "Выберите оценки",
    "graduate_year": "В каком году вы окончите?",
    "select_grad_year": "Выберите год окончания",
    "graduated_already": "Я закончил",
    "grade_txt": "оценка",
    "required_grade": "Указать оценку обязательно",
    "save_btn": "Сохранить",
    "more_show": "Показать больше",
    "less_show": "Показать меньше",
    "next": "Далее",
    "prev": "Назад",
    "yearly_fee": "Годовая плата за обучение",
     "starting": "начинается",
    "at_world": "По всему миру",
    "most_popular_uni": "Лучшие университеты",
    "at_the_subject": "По специальности",
    "grade_predicted": "ваша оценка (ожидаемая или окончательная)",

    "grade": "Оценка",
    "please_fill_all_inp": "Пожалуйста, заполните все поля",

    "select_atleas_country": "Вы должны выбрать хотя бы одну страну.",
  "select_atleas_discipline": "Вы должны выбрать хотя бы одну область.",

   "incorrect_phone": "Ваш номер телефона неверен!",
  "incorrect_email": "Ваш адрес электронной почты неверен!",
  "where_send_otp": "Куда отправить код подтверждения?",
  "or": "Или",
  "shouldbe_active_email": "Номер телефона или электронная почта должны быть активны!",
   "incorrect_otp": "Код подтверждения неверен",

   "send_otp_email": "На ваш адрес электронной почты будет отправлен 4-значный код.",
   "if_email_incorrect": "Если ваша электронная почта правильна, продолжайте, иначе вернитесь и введите правильный адрес.",
   "send_otp_wp": "На ваш номер WhatsApp будет отправлен 4-значный код.",
   "if_wp_incorrect": "Если ваш номер WhatsApp правильный, продолжайте, иначе вернитесь и введите правильный номер.",
   "cont_btn_txt": "Продолжить",
   "back_btn_txt": "Вернуться",

   "otp_shouldbe_4": "Код подтверждения должен состоять из 4 цифр",
  "close": "Закрыть",
  "accept_btn": "Подтвердить",
  "notworking_change": "Не работает? Смените на другой.",
  "change_password": "Обновить пароль",
  "booking_title_2": "Запись",
  "booking_text_2": "Услуга по подготовке дорожной карты для планов обучения за рубежом и выбору университетов",
  "boss": "Руководитель",
  "minute": "мин",
  "team": "Команда",

  "main_page_seo_title": "CELT.go Центр обучения за границей",
  "main_page_seo_desc": "CELTgo.com - Откройте возможности обучения за границей! Поступите в лучшие университеты мира и получите глобальные карьерные возможности. Учиться за границей с нами проще!",
  "main_page_seo_keyword": "обучение за границей, иностранные университеты, образовательный консультант, прием в университет, магистратура за границей, бакалавриат за границей, образовательные программы, образование за границей, образовательное агентство",

  "accepted_page_seo_title": "Принятые студенты | CELTgo.com - Истории успеха",
  "accepted_page_seo_desc": "Узнайте истории успеха студентов, которые осуществили свои мечты об обучении за границей с CELTgo.com. Ознакомьтесь с их опытом и достижениями.",
  "accepted_page_seo_keyword": "принятые студенты, истории успеха, успехи в обучении за границей, прием в университет, опыт студентов, достижения в обучении за границей, истории поступления",

  "countries_page_seo_title": "Лучшие страны для обучения за границей | CELTgo.com",
  "countries_page_seo_desc": "Примите правильное решение при выборе страны для обучения за границей. В CELTgo.com мы представляем образовательные возможности в США, Канаде, Великобритании, Австралии, Германии и других странах.",
  "countries_page_seo_keyword": "страны для обучения за границей, лучшие страны для обучения за границей, выбор страны для образования, обучение в США, обучение в Канаде, обучение в Великобритании, обучение в Германии, обучение в Австралии, программы обучения за границей",

  "subj_page_seo_title": "Лучшие специальности | CELTgo.com - Лучшие образовательные специальности для карьеры",
  "subj_page_seo_desc": "Узнайте о самых перспективных и востребованных специальностях для вашей карьеры с CELTgo.com. Примите правильное решение при выборе специальности и сделайте первый шаг к успешному будущему.",
  "subj_page_seo_keyword": "лучшие специальности, востребованные специальности, специальности для карьеры, перспективные специальности, специальности для обучения за границей, самые перспективные образовательные специальности, выбор специальности для будущего, специальности для успешной карьеры",

  "booking_page_seo_title": "Запишитесь на консультацию по обучению за границей | CELTgo.com - Назначьте встречу",
  "booking_page_seo_desc": "Запишитесь на консультацию по обучению за границей с CELTgo.com. Назначьте встречу с нашими профессиональными образовательными консультантами и позвольте нам помочь вам поступить в желаемый университет.",
  "booking_page_seo_keyword": "запись на обучение за границей, консультация по образованию, консультация по поступлению в университет, встреча по обучению за границей, запись CELTgo, встреча с образовательным консультантом, советы по обучению за границей",

  "contact_page_seo_title": "Свяжитесь с нами | CELTgo.com - Получите поддержку по обучению за границей",
  "contact_page_seo_desc": "Свяжитесь с CELTgo.com и найдите ответы на все ваши вопросы об обучении за границей. Наши образовательные консультанты всегда готовы помочь.",
  "contact_page_seo_keyword": "связаться с нами, контакт CELTgo, поддержка образования, контакт по обучению за границей, связь с образовательным консультантом, вопросы и поддержка, помощь по обучению за границей",

  "uni_page_seo_title": "Топ университетов | CELTgo.com - Лучшие университеты мира",
  "uni_page_seo_desc": "Откройте для себя лучшие университеты мира с CELTgo.com. Получите подробную информацию о идеальных университетах для ваших образовательных и карьерных целей.",
  "uni_page_seo_keyword": "топ университетов, лучшие университеты, лучшие университеты мира, университеты для обучения за границей, самые высокие рейтинги университетов, выбор университета для образования, университеты CELTgo",

  "top_20_page_seo_title": "Топ 20 университетов | CELTgo.com - 20 лучших университетов мира",
  "top_20_page_seo_desc": "Узнайте о 20 лучших университетах мира с CELTgo.com. Получите подробную информацию о самых значимых университетах с точки зрения качества образования и мирового рейтинга.",
  "top_20_page_seo_keyword": "топ 20 университетов, 20 лучших университетов, лучшие университеты мира, лучшие университеты для обучения за границей, топ университеты 2024, рейтинги студентов, университеты CELTgo",

  "top_300_page_seo_title": "Топ 300 университетов | CELTgo.com - 300 лучших университетов мира",
  "top_300_page_seo_desc": "Узнайте о 300 лучших университетах мира с CELTgo.com. Получите обширную информацию о самых значимых университетах с точки зрения качества образования и мирового рейтинга.",
  "top_300_page_seo_keyword": "топ 300 университетов, 300 лучших университетов, лучшие университеты мира, университеты для обучения за границей, 300 самых высоких рейтингов университетов, рейтинги университетов, университеты CELTgo",

  "login": "Войти",
  "password": "Пароль",
  "forgot_psw": "Забыли пароль?",
  "email_psw_inc": "Email или пароль неверен!",
  "not_acc": "У вас нет аккаунта?",
  "create_new": "Создать новый",

  "change_psw_device": "Измените свой пароль с помощью устройства, с которым вы зарегистрировались.",
  "send_txt": "Отправить",
  "return_login": "Вернуться к входу",

  "not_page": "СТРАНИЦА, КОТОРУЮ ВЫ ИСКАЛИ, НЕ СУЩЕСТВУЕТ.",
  "return_main_page": "Вернуться на главную страницу",

  "login_btn_txt": "Войти",
  "code_to_send_wp": "Введите код, отправленный на ваш номер.",
"code_to_send_email": "Введите код, отправленный на вашу электронную почту.",
faq_title: "Часто задаваемые вопросы",

 your_full_name:"Ваше полное имя",

 sch_country_page_seo_title: "Обучение в странах с государственными стипендиями – Образование за рубежом с CELTgo",
sch_country_page_seo_description: "Учитесь в странах с государственными стипендиями и укрепляйте свою карьеру! Воспользуйтесь возможностями бесплатного образования за рубежом с CELTgo. Получите подробную информацию о стипендиях от всемирно признанных университетов и стран.",
sch_country_page_seo_keywords: "страны с государственными стипендиями, обучение за рубежом, стипендиальные программы, бесплатное образование, CELTgo, программы стипендий, университеты за рубежом, стипендии, международные стипендии",

grade_scheme_req: "Оценка должна быть выше 50.",

address:"Центр образования за рубежом CELT, проспект Ходжалы 14, 1025, Хатаи, Баку, Азербайджан",
contact_help_text:"Спросите нас. Мы здесь, чтобы помочь!",

form_name:"Имя",
   form_last_name:"Фамилия",
   form_phone:"Номер телефона",

   form_note: "Заметка"
}