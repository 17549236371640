import React, { Component } from 'react'

export class SuccessSvg extends Component {
  render() {
    const {size=20} = this.props
    return (
        <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 20 20"
        width={size}
        height={size}
        style={{ minWidth:'20' }}
      >
        <path
          fill="#038164"
          d="M10 0a10 10 0 1 0 10 10A10.012 10.012 0 0 0 10 0Zm4.884 8.384-5.5 5.5a1.251 1.251 0 0 1-1.768 0l-2.5-2.5a1.25 1.25 0 0 1 1.768-1.768L8.5 11.232l4.616-4.616a1.25 1.25 0 1 1 1.768 1.768Z"
        />
      </svg>
    )
  }
}

export default SuccessSvg
