import React, { Component } from 'react'
import { resetStateValue } from '../../redux/MainReducer';
import { connect } from 'react-redux';
import { withHooks } from '../../actions/withHooks';
import { Link } from 'react-router-dom';
import Header from './components/Header';
import CloseSvg from '../svg/CloseSvg';

export class EmailPhoneIncorrectModal extends Component {
    componentDidMount(){
       setTimeout(()=>{
        this.props.resetStateValue({
            name:"incorrectModalData"
        })
       
       }, 5000)
    }
    resetAll=()=>{
        this.props.resetStateValue({name:"verifyModalData"});
        this.props.resetStateValue({name:"applyDetails"});
        this.props.resetStateValue({name:"confirmEmailWhatsaapModal"});
        this.props.resetStateValue({name:"admissionModal"});
        this.props.resetStateValue({name:"educationPlanModalData"});
        this.props.resetStateValue({name:"incorrectModalData"});
    }

    closeModal=(e)=>{
        if(typeof e.target.className == "string" && e.target.className.includes("background_shadow")){
            this.props.resetStateValue({
                name:"incorrectModalData"
            })
        }
    }

    onClose=()=>{
        this.props.resetStateValue({
            name:"incorrectModalData"
        })
    }
  render() {
    const {incorrectModalData} = this.props
    return (
      <div onClick={this.closeModal.bind(this)} className='background_shadow flex items-center justify-center '>
            <div className='bg-white w-[400px]  shadow rounded-[10px] relative  max-[450px]:ml-[20px] max-[450px]:mr-[20px]'>
                <div className='bg-white w-[38px] h-[38px] flex justify-center items-center absolute right-[-15px]  top-[-15px] rounded-full'>
                    <button onClick={this.onClose.bind(this)} className='border border-[var(--blue)] rounded-full w-[30px] h-[30px] inline-flex justify-center items-center'>
                        <CloseSvg color="#264EA9" />
                    </button>
                </div>
               <div className=' max-[400px] p-[20px] ml-[20px] mr-[20px] flex flex-col items-center gap-[20px]'>
                    <p className='text-[14px]  text-center'>{incorrectModalData?.message}</p>
                    <Link to="/forgot-password" onClick={this.resetAll.bind(this)} className='text-[14px] bg-blue p-[5px_8px] text-white rounded-[6px]'>{this.props.language?.change_password}</Link>
               </div>
            </div>
      </div>
    )
  }
}
const mapStateToProps = (state) =>({
    incorrectModalData: state.Data.incorrectModalData,
    language: state.Data.language,
});
const mapDispatchToProps = {resetStateValue}
export default connect(mapStateToProps, mapDispatchToProps)(withHooks(EmailPhoneIncorrectModal))
