import React, { Component } from 'react'
import { changeStateValue } from '../../../../redux/MainReducer';
import { deleteData, getStudentNotes } from '../../../../actions/MainAction';
import { connect } from 'react-redux';
import { getRole } from '../../../../actions/getOtherFunctions';
import { confirmAlert, mixinAlert } from '../../../../actions/SweetAlerts';

export class StudentNotePage extends Component {
    componentDidMount(){
        this.props.getStudentNotes(this.props.student_id)
    }

    openModal=(data)=>{
        this.props.changeStateValue({
            name:'adminModal',
            value:{
              position: true,
              id: data?.id?data?.id:'',
              note: data?.note?data?.note:'',
              student_id: data?.student_id?data?.student_id:this.props.student_id,
            
              afterCallFunction:this.props.getStudentNotes,
              afterCallParams:[this.props.student_id],
              requireds: ["note"],
              url:'student-note',
              modalTitle:`Qeydi ${data?.id?'Redaktə et':'Əlavə et'}`
            }
          })
    }

    onDeleteBtn=(id)=>{
        confirmAlert("warning", "Əminsiniz?", "Sildikdən sonra geri qaytarmaq olmuyacaq!", "Bəli", "Xeyir")
        .then(alertResp=>{
            if(alertResp === "success"){
            this.props.deleteData(`student-note/${id}`)
            .then(resp=>{
                if(resp == "success"){
                    this.props.getStudentNotes(this.props.student_id)
                    mixinAlert("success", "Qeyd uğurla silindi")
                }else{
                    mixinAlert("error", "Xəta baş verdi")
                }
            })
            }
        })
    }
  render() {
    const {student_notes, user} = this.props;
    // console.log(student_notes)
    return (
        <div>
            <div className='flex justify-end'>
                <button onClick={this.openModal.bind(this, "")} className='text-[14px] bg-blue text-white p-[5px_8px] rounded-[6px]'>Qeyd əlavə et</button>
            </div>
            <div className='flex flex-col gap-[20px] mt-[20px]'>
                {
                    student_notes?.map((data, i)=>{
                        return (
                            <div key={i} className='bg-white shadow p-[20px] rounded-[10px]'>
                                <p dangerouslySetInnerHTML={{__html: data?.note}}></p>
                                <div className='border-t mt-[15px] pt-[10px] flex justify-between items-center'>
                                    <p className='txt-blue font-[500]'>{getRole(data?.staff?.role)}: {data?.staff?.full_name}</p>
                                    {
                                        user?.id == data?.staff_id?
                                        <div className='flex gap-[20px] items-center'>
                                            <button onClick={this.onDeleteBtn.bind(this, data?.id)} className='bg-red text-[14px] font-[500] text-white p-[4px_10px] rounded-[7px]'>Sil</button>
                                            <button onClick={this.openModal.bind(this, data)} className='bg-blue text-[14px] font-[500] text-white p-[4px_10px] rounded-[7px]'>Redaktə et</button>
                                        </div>:null
                                    }
                                </div>
                            </div>
                        )
                    })
                }
            </div>
        </div>
    )
  }
}
const mapStateToProps = (state) =>({
    student_notes: state.Data.student_notes,
    user: state.Data.user,
});
const mapDispatchToProps = {changeStateValue, getStudentNotes, deleteData}
export default connect(mapStateToProps, mapDispatchToProps)(StudentNotePage)
