import React, { Component } from 'react'
import { Link } from 'react-router-dom';
import { getPathConvert, getTranslateLanguage } from '../../../actions/getOtherFunctions';
import { connect } from 'react-redux';

export class Card2 extends Component {
  render() {
    const {data, path="country"} = this.props;
    // console.log(data)
    return (
      <Link to={`/${this.props.langPrefix}${path}/${getPathConvert(data?.name_en)}`} className='flex flex-col items-center'>
        <img className='w-[221px] h-[221px] rounded-full max-[500px]:rounded-[10px] max-[500px]:w-full' src={data?.image_full_url} alt={data?.name} />
        <h3 className='text-[20px] font-regular text-center mt-[25px]'>{getTranslateLanguage(this.props.lang_id, "name", data)}</h3>
        <p className='text-[rgba(0,0,0,0.5)] text-[16px] font-regular text-center'>{data?.university_count} {this.props.language?.university}</p>
      </Link>
    )
  }
}
const mapStateToProps = (state) =>({
  lang_id: state.Data.lang_id,
  language: state.Data.language,
  langPrefix: state.Data.langPrefix,
});
export default connect(mapStateToProps)(Card2)
