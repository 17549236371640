import React, { Component } from 'react'
import Header from '../../components/header/Header'
import Search from '../../components/Search'
import Buttons from '../../components/Buttons'
import { connect } from 'react-redux'
import { changeStateValue } from '../../redux/MainReducer';
import { getUniversities, insertNewData } from '../../actions/MainAction';
import Card1 from '../../components/cards/Card1'
import LoadMoreBtn from '../../components/LoadMoreBtn'
import { confirmAlert, mixinAlert } from '../../actions/SweetAlerts'
export class AdminUniversityPage extends Component {
  state={
    page:1,
    search:''
  }
  openModal=(data)=>{
    this.props.changeStateValue({
      name:'adminModal',
      value:{
        position: true,
        id: data?.id?data?.id:'',
        name: data?.name?data?.name:'',
        name_en: data?.name_en?data?.name_en:'',
        name_tr: data?.name_tr?data?.name_tr:'',
        name_ru: data?.name_ru?data?.name_ru:'',
        image: data?.image?data?.image:'',
        image_full_url: data?.image_full_url?data?.image_full_url:'',
        country_id: data?.country_id?data?.country_id:'',
        state_id: data?.state_id?data?.state_id:'',
        city_id: data?.city_id?data?.city_id:'',
        type_id: data?.type_id?data?.type_id:'',
        ranking: data?.ranking?data?.ranking:'',
        local_ranking: data?.local_ranking?data?.local_ranking:'',
        bachelour_start: data?.bachelour_start?data?.bachelour_start:'',
        master_start: data?.master_start?data?.master_start:'',
        pre_master_start: data?.pre_master_start?data?.pre_master_start:'',
        phd_start: data?.phd_start?data?.phd_start:'',
        afterCallFunction:this.props.getUniversities,
        afterCallParams:[this.state.page, this.state.search],
        requireds: ["name",  "name_en", "name_tr", "name_ru","country_id", "city_id", "type_id"],
        url:'university',
        modalTitle:`${data?.id?'Edit':'Add'} university`
      }
    })
  }

  componentDidMount(){
    this.props.getUniversities(1, '')
  }

  onSearchData=(search)=>{
    this.setState({search})
    this.props.getUniversities(this.state.page, search)
  }
  loadMore=(page)=>{
    this.setState({page})
    this.props.getUniversities(page, this.state.search)
  }

  afterCallFunction=()=>{
    this.props.getUniversities(this.state.page, this.state.search)
  }

  onChangeStatus=(data)=>{
    confirmAlert("warning", "Are you sure?", `Do you want to ${data?.deleted_status==1?"Active":"Deactice"} university`, "yes")
    .then(resp=>{
      if(resp == "success"){
        this.props.insertNewData(`changeuni-status/${data?.id}`, {status:data?.deleted_status==1?0:1 })
        .then(resp=>{
          if(resp=="success"){
            mixinAlert("success", "Data saved successfully");
            this.afterCallFunction()
          }else{
            mixinAlert("error", "Something went wrong");
          }
        })
      }
    })
  }
  render() {
    const {universities, user} = this.props;
    return (
        <div className='w-full  pl-[27px] pr-[27px]'>
            <Header title="Universities" />
            <div className='flex justify-end mt-[30px]'>
                <Buttons onClick={this.openModal.bind(this, '')} options={[{title:"Add a university", value:0}]} selected={0} />
            </div>
            <div className='flex justify-center mt-[20px]'>
                <Search onSearchData={this.onSearchData.bind(this)} largWidth={true} />
            </div>

            <div className='grid-full-250 mt-[30px]'>
              {
                universities?.map((data, i)=>{
                  return(
                    <Card1 
                      activeStatusPosition={user?.role == "admin"}
                      activeStatus={data?.deleted_status==0?1:0}
                      key={i}
                      image={data?.image_full_url}
                      name={data?.name}
                      country={data?.country?.name}
                      type={data?.type?.name}
                      onClickEdit={this.openModal.bind(this, data)}
                      deletePath={user?.role == "admin"? `university/${data?.id}`:""}
                      afterCallFunction={this.afterCallFunction.bind(this)}
                      moreLink={`/add-programs/${data?.id}`}
                      userLink={`/univerisy-programs/${data?.id}`}
                      onChangeStatus={this.onChangeStatus.bind(this, data)}
                    />
                  )
                })
              }
            </div>
            {
                universities?.length >=20?
                <LoadMoreBtn onLoadData={this.loadMore} count={universities?.length} />:null
            }
        </div>
    )
  }
}
const mapStateToProps = (state) =>({
  universities: state.Data.universities,
  user: state.Data.user,
});
const mapDispatchToProps = {changeStateValue, getUniversities, insertNewData}
export default connect(mapStateToProps, mapDispatchToProps)(AdminUniversityPage)