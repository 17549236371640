import React, { Component } from 'react'
import SearchSvg from './svg/SearchSvg';
import CloseSvg from './svg/CloseSvg';
import { changeStateValue } from '../redux/MainReducer';
import { connect } from 'react-redux';

export class Search extends Component {
  
    searchBtn(search, e){
        e.preventDefault();
        if(search.trim() !== ''){
            this.props.onSearchData(search.trim())
        }
    }
    changeInpValue(e){
        this.setState({search:e.target.value});
        this.props.changeStateValue({name:'search', value:e.target.value})
        if(e.target.value === ''){
            this.props.onSearchData('');
        }
    }
    resetSearch=()=>{
        this.props.changeStateValue({name:'search', value:''})
        this.props.onSearchData('');
    }
  render() {
    const {search} = this.props;
    const {largWidth=false} = this.props;
    return (
        <div className='h-[56px] p-[10px] bg-white rounded-[30px] flex items-center gap-[20px] headerbar'>
            <form onSubmit={this.searchBtn.bind(this, search)} className={`bg-light-gray ${largWidth?"w-[400px] ":" w-[214px]"} h-[38px] max-md:w-[100%] rounded-[30px] flex items-center justify-between pr-[10px] pl-[20px] gap-[5px]`}>
                <button >
                    <SearchSvg />
                </button>
                <input value={search} onChange={this.changeInpValue.bind(this)} placeholder={"Axtar"}  className={`placeholder-txt-blue bg-light-gray w-[80%] outline-none text-[14px] txt-dark-gray mr-[20px]`} type='text' />
                <button onClick={this.resetSearch.bind(this)}>
                    <CloseSvg color={"#2B3674"} />
                </button>
            </form>
        </div>
    )
  }
}
const mapStateToProps = (state)=>({
    search: state.Data.search
});
const mapDispatchToProps = {changeStateValue}
export default connect(mapStateToProps, mapDispatchToProps)(Search)
