import React, { Component } from 'react'
import azFlag from "../../images/lang/az.png";
import enFlag from "../../images/lang/en.png";
import trFlag from "../../images/lang/tr.webp";
import ruFlag from "../../images/lang/ru.png";
import  {changeStateValue} from "../../redux/MainReducer"
import { connect } from 'react-redux';
import { az_lang, en_lang, ru_lang, tr_lang } from '../../lang';
import { withHooks } from '../../actions/withHooks';
export class ChangeLangBtn extends Component {
  state={
    langVisible:false
  }
  getLangImg(lang_id){
    if(lang_id == 1){
      return azFlag;
    }else if(lang_id == 2){
      return enFlag
    }else if(lang_id == 3){
      return ruFlag
    }else if(lang_id == 4){
      return trFlag
    }
  }
  onChangeLang=(lang_id)=>{
    this.props.changeStateValue({
      name:"lang_id",
      value: lang_id
    });
    let prefix = this.props.langPrefix;
    let language = this.props.language
    if(lang_id==1){
      language = az_lang;
      prefix="";
    }else if(lang_id == 2){
      language = en_lang;
      prefix = "en/";
    }else if(lang_id == 3){
      language = ru_lang;
      prefix = "ru/";
    }else if(lang_id == 4){
      language = tr_lang;
      prefix = "tr/";
    }
    this.props.changeStateValue({ name: "language", value: language });
    this.props.changeStateValue({ name: "langPrefix", value: prefix });

    this.setState({langVisible:false});
    let path = window.location.pathname;
    let newPath = path
    if(path.includes("ru/")){
      newPath = path.replace("ru/", prefix)
    }else if(path.includes("en/")){
      newPath = path.replace("en/", prefix)
    }else if(path.includes("tr/")){
      newPath = path.replace("tr/", prefix)
    }else{
      newPath = prefix+path.replace("/",'')
    }
    this.props.navigate(`${newPath}`)
    // console.log(newPath)
    
  }
  componentDidMount(){
    let path = window.location.href;
    let prefix = this.props.langPrefix;
    let language = this.props.language;
    let lang_id = this.props.lang_id;
    if(path.includes(".com/ru") || path.includes("00/ru")){
      prefix = "ru/";
      language = ru_lang;
      lang_id = 3;
    }else if(path.includes(".com/en") || path.includes("00/en")){
      prefix = "en/";
      language = en_lang;
      lang_id = 2;
    }else if(path.includes(".com/tr") || path.includes("00/tr")){
      prefix = "tr/";
      language = tr_lang;
      lang_id = 4;
    }else{
      prefix = "";
      language = az_lang;
      lang_id = 1;
    }
    this.props.changeStateValue({name:"language", value: language});
    this.props.changeStateValue({name:"lang_id", value: lang_id});
    this.props.changeStateValue({name:"langPrefix", value: prefix});
  }
  render() {
    const {lang_id} = this.props;
    const {langVisible} = this.state;
    return (
     <>
      {langVisible? <div onClick={()=>this.setState({langVisible: !langVisible})} className='fixed bg-none w-full h-screen left-0 top-0'></div>:null}
     
       <div className='relative'>
        <button onClick={()=>this.setState({langVisible: !langVisible})} className='mt-[5px]'>
          <img className='w-[40px] h-[25px] rounded-[5px]' src={this.getLangImg(lang_id)} />
        </button>
        {
          langVisible?
          <div className='absolute bg-white w-[65px] shadow ml-[-12px] rounded-[5px] flex flex-col items-center pt-[10px] pb-[10px] gap-[8px]'>
          <button onClick={this.onChangeLang.bind(this, 1)}>
              <img className='w-[40px] h-[25px] rounded-[5px]' src={azFlag} />
          </button>
          <button onClick={this.onChangeLang.bind(this, 2)}>
              <img className='w-[40px] h-[25px] rounded-[5px]' src={enFlag} />
          </button>
          <button onClick={this.onChangeLang.bind(this, 3)}>
              <img className='w-[40px] h-[25px] rounded-[5px]' src={ruFlag} />
          </button>
          <button onClick={this.onChangeLang.bind(this, 4)}>
              <img className='w-[40px] h-[25px] rounded-[5px]' src={trFlag} />
          </button>
        </div>:null
        }
      </div>
     </>
    )
  }
}
const mapStateToProps = (state) =>({
  lang_id: state.Data.lang_id,
  language: state.Data.language,
  langPrefix: state.Data.langPrefix,
});
const mapDispatchToProps = {changeStateValue}
export default connect(mapStateToProps, mapDispatchToProps)(withHooks(ChangeLangBtn))
