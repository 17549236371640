import React, { Component } from 'react'
import CloseSvg from '../../../components/svg/CloseSvg'
import { changeStateValue, resetStateValue } from '../../../redux/MainReducer'
import { connect } from 'react-redux'
import { bigAlert, confirmAlert, mixinAlert } from '../../../actions/SweetAlerts'
import { insertNewDataParams } from '../../../actions/MainAction'
import { withHooks } from '../../../actions/withHooks'

export class EmailOrPhoneConfirmModal extends Component {
    closeBtn=()=>{
        this.props.changeStateValue({name:"confirmEmailWhatsaapModal", value: false})
    }

    sendCodeBtn=(type)=>{
        let msg = "";
        let msg1 = "";
        if(type == "email"){
            msg =this.props.language?.send_otp_email;
            msg1 = this.props.language?.if_email_incorrect
        }else{
            msg =this.props.language?.send_otp_wp;
            msg1 =this.props.language?.if_wp_incorrect
        }
        confirmAlert("warning", msg, msg1, this.props.language?.cont_btn_txt, this.props.language?.back_btn_txt)
        .then(respAlert=>{
            if(respAlert == "success"){
                let formData = new FormData();
                for(const property in this.props.educationPlanModalData?.information){
                    formData.append(property, this.props.educationPlanModalData?.information[property])
                }
                formData.append("type", type)
                this.props.insertNewDataParams("send-verify-code", formData)
                .then(resp=>{
                    if(resp?.msg == "success"){
                        if(type == "phone"){
                            // console.log(resp)
                            if(resp?.data?.valid){
                                let formData = new FormData();
                                formData.append("countries", JSON.stringify(this.props.educationPlanModalData?.countries));
                                formData.append("disciplines", JSON.stringify(this.props.educationPlanModalData?.disciplines));
                                formData.append("educationBackground", JSON.stringify(this.props.educationPlanModalData?.educationBackground));
                                formData.append("information", JSON.stringify(this.props.educationPlanModalData?.information));
                                formData.append("exams", JSON.stringify(this.props.educationPlanModalData?.exams));
                                formData.append("code", resp?.data?.verify?.code);
                                formData.append("username", resp?.data?.verify?.username);
                                formData.append("registerType", "normal");
                                if(window.location.search && atob(window.location.search?.replace("?tn=", ""))?.replace("generated", "")?.replace("code", "")){
                                  formData.append("teacher_id", atob(window.location.search?.replace("?tn=", ""))?.replace("generated", "")?.replace("code", ""));
                                }
                                this.props.changeStateValue({name:"loaderLong", value:true});

                                this.props.insertNewDataParams("register-student", formData)
                                .then(resp2=>{
                                  this.props.changeStateValue({name:"loaderLong", value:false})
                                  if(resp2?.msg == 'success'){
                                    if(resp2?.data?.token){
                                        localStorage.setItem("celtGoMainToken", resp2?.data?.token);
                                        this.props.changeStateValue({name:'user', value:resp2?.data?.user});
                                        this.props.resetStateValue({name:"verifyModalData"})
                                        this.props.resetStateValue({name:"educationPlanModalData"})
                                        this.props.resetStateValue({name:"confirmEmailWhatsaapModal"})
                                        this.props.navigate("/available-programs?check_available")
                                    }else{
                                        this.props.resetStateValue({name:"verifyModalData"})
                                        this.props.resetStateValue({name:"educationPlanModalData"})
                                        this.props.resetStateValue({name:"confirmEmailWhatsaapModal"})
                                        bigAlert(resp?.data?.title,  resp2?.data?.msg, "info", 5000)
                                    }
                                    
                                  }else{
                                    mixinAlert("error", this.props.language?.incorrect_otp);
                                  }
                                })
                            }else{
                                this.props.changeStateValue({name:"applyDetails", value: {...this.props.educationPlanModalData, type:"phone"}});
                                this.props.changeStateValue({
                                    name:"verifyModalData",
                                    value:{
                                        position:true,
                                        code:'',
                                        username: resp?.data?.username
                                    }
                                  })
                            }
                        }else{
                            this.props.changeStateValue({name:"applyDetails", value: {...this.props.educationPlanModalData, type:"email"}});
                            this.props.changeStateValue({
                                name:"verifyModalData",
                                value:{
                                    position:true,
                                    code:'',
                                    username: resp?.data?.username
                                }
                            })
                        }
                       
                    }else{
                        mixinAlert("error", resp?.data);
                    }
                })
            }
        })
    }
  render() {
    return (
      <div className='background_shadow flex items-center justify-center'>
        <div className='bg-white w-[400px] rounded-[8px] shadow max-[450px]:ml-[20px] max-[450px]:mr-[20px]'>
            <div className='flex justify-center relative p-[8px_0] border-b'>
                <h2 className='text-[#032552] font-semibold text-[24px]   max-[640px]:text-[14px] text-center'>{this.props.language?.where_send_otp}</h2>
                <div className='absolute right-[-25px] top-[-25px] mt-0 bg-white w-[38px] h-[38px] flex justify-center items-center rounded-full max-[640px]:w-[30px] max-[640px]:h-[30px] max-[640px]:right-[-20px] max-[640px]:top-[-20px]'>
                    <button onClick={this.closeBtn.bind(this)} className='w-[30.28px] h-[30.28px] rounded-full border border-[#1E398F] inline-flex justify-center items-center max-[640px]:w-[24px] max-[640px]:h-[24px]'>
                        <CloseSvg color="#1E398F" />
                    </button>
                </div>
            </div>
            <div className='flex items-center justify-between  p-[20px]'>
                <button onClick={this.sendCodeBtn.bind(this, "email")} className='bg-blue p-[3px_8px] text-white text-[14px] rounded-[6px]'>
                    Email
                </button>
                <div className='flex items-center justify-between gap-[20px] max-[500px]:gap-[10px]'>
                    <span className='inline-block h-[1px] w-[50px] bg-gray-300 max-[500px]:w-[10px]'></span>
                    <span className='text-gray-400 font-[300] text-[14px]'>{this.props.language?.or}</span>
                    <span className='inline-block h-[1px] w-[50px] bg-gray-300 max-[500px]:w-[10px]'></span>
                </div>
                <button onClick={this.sendCodeBtn.bind(this, "phone")} className='bg-blue p-[3px_8px] text-white text-[14px] rounded-[6px]'>
                    Whatsapp
                </button>
            </div>
            <p className='text-center txt-red text-[14px] mb-[10px]'>{this.props.language?.shouldbe_active_email}</p>
        </div>
      </div>
    )
  }
}
const mapStateToProps = (state)=>({
    educationPlanModalData: state.Data.educationPlanModalData,
    language: state.Data.language,
});
const mapDispatchToProps = {changeStateValue, insertNewDataParams, resetStateValue}
export default connect(mapStateToProps, mapDispatchToProps)(withHooks(EmailOrPhoneConfirmModal))
