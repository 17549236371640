import React, { Component } from 'react'
import { changeStateValue, resetStateValue } from '../../redux/MainReducer';
import { connect } from 'react-redux';
import { insertNewDataParams } from '../../actions/MainAction';
import { mixinAlert } from '../../actions/SweetAlerts';
import { withHooks } from '../../actions/withHooks';
import WhatsappSvg from '../public_components/svg/WhatsappSvg';
import ModalEmailSvg from '../public_components/svg/ModalEmailSvg';

export class VerifyModalTn extends Component {
    state={
        otp1:'',
        otp2:'',
        otp3:'',
        otp4:'',
        otp1Ref: React.createRef(),
        otp2Ref: React.createRef(),
        otp3Ref: React.createRef(),
        otp4Ref: React.createRef(),
    }

    onChangeOtp=(index,value, ref="", backRef="",e)=>{
        if(value=="" || e.nativeEvent?.inputType == "deleteContentBackward"){
          this.setState({[`otp${index}`]:e.target.value})
        }
        if(e.nativeEvent?.inputType == "deleteContentBackward"){
          if(backRef !=""){
            backRef?.current?.focus()
          }
        }else{
          if(ref !== ""){
            ref?.current?.focus();
          }
        }
    }
    componentDidMount(){
        if(this.state.otp1Ref?.current){
            this.state.otp1Ref?.current?.focus()
        }
    }
    closeModal=()=>{
        this.props.resetStateValue({name:"tnRegConfModal"});
        this.props.resetStateValue({name:"tnRegisterVerifyModal"});
    }
    onPaste=(e)=>{
        e.preventDefault();
        const value = e.clipboardData.getData('text');
        if(value.length ==4 && !isNaN(+value) && typeof +value == "number"){
            this.setState({otp1: value[0], otp2: value[1], otp3: value[2], otp4: value[3],})
            this.state.otp4Ref?.current?.focus()
        }
    }
    verifyNumber=(data, otp1, otp2, otp3, otp4)=>{
        if(otp1 !="" && otp2 !="" && otp3 !="" && otp4  !="" ){
            console.log("ss")
            let formData = new FormData();
            for(const property in data){
                formData.append(property, data[property]);
            }
            formData.append("code", `${otp1}${otp2}${otp3}${otp4}`);
            this.props.changeStateValue({name:"loaderLong", value:true});

            this.props.insertNewDataParams("register-staff", formData)
            .then(resp=>{
                this.props.changeStateValue({name:"loaderLong", value:false});
                if(resp?.msg == 'success'){
                    // console.log(resp)
                    if(resp?.data?.token){
                        localStorage.setItem("celtGoMainToken", resp?.data?.token);
                        this.props.changeStateValue({name:'user', value: resp?.data?.user})
                        this.closeModal();
                        this.props.navigate("/")
                    }
                }else{
                    mixinAlert("error", this.props.language?.incorrect_otp);
                    this.setState({ otp1:'', otp2:'', otp3:'', otp4:'',})
                }
            })
        }
    }
  render() {
    const {tnRegisterVerifyModal} = this.props;
    const {otp1, otp2, otp3, otp4, otp1Ref, otp2Ref, otp3Ref, otp4Ref} = this.state;
    return (
        <div className='background_shadow flex items-center justify-center'>
            <div className='w-[435px] bg-white shadow rounded-[10px] max-[475px]:ml-[20px] max-[475px]:mr-[20px] max-[500px]:ml-[20px] max-[500px]:mr-[20px]'>
            {
                tnRegisterVerifyModal?.type && tnRegisterVerifyModal?.type =="phone"?
                <h3 className='text-[24px] text-center mt-[26px] text-center flex items-center gap-[10px] justify-center max-[500px]:text-[16px] max-[350px]:flex-col'><WhatsappSvg /> {this.props.language?.code_to_send_wp}</h3>:
                <h3 className='text-[24px] text-center mt-[26px] text-center flex items-center gap-[10px] justify-center max-[500px]:text-[16px]  max-[350px]:flex-col'><ModalEmailSvg />{this.props.language?.code_to_send_email}</h3>
            }
            
            <div className='pl-[57px] pr-[57px] pb-[28px] max-[500px]:pl-[10px] max-[500px]:pr-[10px]'>
            
                <div className='flex justify-between items-center gap-[20px] mt-[20px] max-[400px]:gap-[14px] max-[400px]:justify-center'>
                    <input type='number' onPaste={this.onPaste.bind(this)} ref={otp1Ref} onChange={this.onChangeOtp.bind(this,1, otp1, otp2Ref, "")} value={otp1} className='w-[48px] h-[48px]  max-[400px]:w-[35px] max-[400px]:h-[35px]  bg-[#F9F9F9] rounded-[8px] border border-[#EEEEEE] outline-none text-center text-[#868686] text-[18px]' />
                    <input type='number' onPaste={(e)=>e.preventDefault()} ref={otp2Ref} onChange={this.onChangeOtp.bind(this,2, otp2, otp3Ref, otp1Ref)} value={otp2} className='w-[48px] h-[48px]  max-[400px]:w-[35px] max-[400px]:h-[35px] bg-[#F9F9F9] rounded-[8px] border border-[#EEEEEE] outline-none text-center text-[#868686] text-[18px]' />
                    <input type='number' onPaste={(e)=>e.preventDefault()} ref={otp3Ref} onChange={this.onChangeOtp.bind(this,3, otp3, otp4Ref, otp2Ref)} value={otp3} className='w-[48px] h-[48px]  max-[400px]:w-[35px] max-[400px]:h-[35px] bg-[#F9F9F9] rounded-[8px] border border-[#EEEEEE] outline-none text-center text-[#868686] text-[18px]' />
                    <input type='number' onPaste={(e)=>e.preventDefault()} ref={otp4Ref} onChange={this.onChangeOtp.bind(this,4, otp4, "", otp3Ref)} value={otp4} className='w-[48px] h-[48px]  max-[400px]:w-[35px] max-[400px]:h-[35px] bg-[#F9F9F9] rounded-[8px] border border-[#EEEEEE] outline-none text-center text-[#868686] text-[18px]' />
                </div>
                {/* <input value={verifyModalData?.code} onChange={(e)=>this.props.changeStateValue({name:'verifyModalData.code', value:e.target.value})} type='number' className='bg-gray-100 w-full h-[35px] mt-[40px] rounded-[10px] text-[14px] pl-[5px]' placeholder='6 digit number' /> */}
                <div className='flex justify-between items-center mt-[30px]'>
                <button onClick={()=>window.location.reload()} className='bg-orange h-[48px] w-[150px] text-white text-[16px] font-regular rounded-[8px] max-[400px]:w-[80px] max-[400px]:text-[16px] max-[400px]:h-[30px] max-[400px]:pl-[5px] max-[400px]:pr-[5px] max-[310px]:text-[14px]'>
                    {this.props.language?.close}
                </button>
                <button onClick={this.verifyNumber.bind(this, tnRegisterVerifyModal, otp1, otp2, otp3, otp4)} className='bg-blue h-[48px] w-[150px]  max-[400px]:w-[80px]  text-white text-[16px] font-regular rounded-[8px]  max-[400px]:text-[16px] max-[400px]:h-[30px] max-[400px]:pl-[5px] max-[400px]:pr-[5px] max-[310px]:text-[14px]'>
                    {this.props.language?.accept_btn}
                </button>
                </div>
                <button onClick={this.closeModal.bind(this)} className='txt-blue mt-[10px] text-[14px]'>
                {this.props.language?.notworking_change}
                </button>
            </div>
            </div>
        </div>
    )
  }
}
const mapStateToProps = (state)=>({
    language: state.Data.language,
    tnRegisterVerifyModal: state.Data.tnRegisterVerifyModal,
});
const mapDispatchToProps = {changeStateValue, resetStateValue, insertNewDataParams}
export default connect(mapStateToProps, mapDispatchToProps)(withHooks(VerifyModalTn))
