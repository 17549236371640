import React, { Component } from 'react'
import { getTranslateLanguage } from '../../actions/getOtherFunctions';
import { connect } from 'react-redux';
import PlusSvg from '../svg/PlusSvg';
import MinusSvg from './svg/MinusSvg';

export class FaqCard extends Component {
    state={
        faqVisible:false
    }
  render() {
    const {data} = this.props;
    const {faqVisible} = this.state;
    return (
      <div className='bg-white p-[10px] rounded-[10px] shadow'>
        <div className='flex justify-between items-center cursor-pointer' onClick={()=>this.setState({faqVisible: !faqVisible})}>
            <h3 className='text-[17px] font-[500] txt-blue max-[400px]:text-[14px]'>{getTranslateLanguage(this.props.lang_id, "question", data)}</h3>
            <button className='bg-blue w-[25px] h-[25px] min-w-[25px] shadow rounded-full flex items-center justify-center'>
                {
                    faqVisible?
                    <MinusSvg color={"#fff"}  />:
                    <PlusSvg color={"#fff"} />
                }
            </button>
        </div>
        {
            faqVisible?
            <div className='mt-[10px]'>
                <p className='text-[14px] max-[400px]:text-[12px]'>{getTranslateLanguage(this.props.lang_id, "answer", data)}</p>
            </div>:null
        }
        
      </div>
    )
  }
}
const mapStateToProps = (state) =>({
    lang_id: state.Data.lang_id
})
export default connect(mapStateToProps)(FaqCard)
