import React, { Component } from 'react'
import Input from '../components/modals/components/Input'
import { changeStateValue } from '../redux/MainReducer';
import { insertNewData, insertNewDataParams } from '../actions/MainAction';
import { connect } from 'react-redux';
import InpSvg from '../components/svg/InpSvg';
import InputPhone from '../components/modals/components/InputPhone';
import PhoneSvg from '../components/svg/PhoneSvg';
import { mixinAlert } from '../actions/SweetAlerts';
import EmailPhoneConfTN from '../components/EmailPhoneConfTN';

export class TNRegisterPage extends Component {
 
    onRegisterBtn=(data)=>{
        if(data?.first_name?.trim() !=="" && data?.last_name?.trim() !=="" && data?.email?.trim() !=="" && data?.phone_number?.trim() !==""){
            if(!this.props.phoneValidFormat){
                mixinAlert("warning", this.props.language?.incorrect_phone);
                return;
            }
            if(!data?.email.match(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)){
                mixinAlert("warning", this.props.language?.incorrect_email)
                return;
            }
            this.props.insertNewDataParams("check-email-phone", data)
            .then(resp=>{
              if(resp.msg == "success"){
                this.props.changeStateValue({name:"tnRegConfModal", value: true})
              }else{
                this.props.changeStateValue({
                  name:"incorrectModalData",
                  value: {
                    position: true,
                    message: resp?.data,
                  }
                })
                // mixinAlert("warning", resp?.data);
              }
            })

        }else{
            mixinAlert("warning", this.props.language?.please_fill_all_inp);
        }
    }
  render() {
    const {tn_register, tnRegConfModal} = this.props;
    return (
      <div className='flex justify-center items-center h-screen'>
            <div className='w-[700px] p-[20px]  bg-white shadow rounded-[15px]'>
                <div className='grid grid-cols-2  gap-[20px]'>
                  <Input
                    bigInp svg={<InpSvg />} bg="bg-[#F3F4F6]"
                    required
                    redborder={false}
                    name={`tn_register.first_name`}  
                    value={tn_register?.first_name} 
                    reqText="Anketi doldurmaq mütləqdir"
                    title={`Ad`}
                  />
                  <Input 
                    bigInp svg={<InpSvg />} bg="bg-[#F3F4F6]"
                    required
                    redborder={false}
                    name={`tn_register.last_name`}  
                    value={tn_register?.last_name} 
                    reqText="Anketi doldurmaq mütləqdir"
                    title={`Soyad`}
                  />
                   <InputPhone  bigInp svg={<PhoneSvg />}
                    required
                    name={`tn_register.phone_number`}  
                    value={tn_register?.phone_number} 
                    title={this.props.language?.your_phone_num}  />
                
                  <Input 
                    bigInp svg={<InpSvg />} bg="bg-[#F3F4F6]"
                    required
                    redborder={false}
                    name={`tn_register.email`}  
                    value={tn_register?.email} 
                    reqText="Anketi doldurmaq mütləqdir"
                    title={`Email`}
                  />
                </div>
                <div className='mt-[20px] flex justify-center'>
                    <button onClick={this.onRegisterBtn.bind(this, tn_register)} className='h-[40px] w-[120px] bg-blue text-white text-[14px] font-[500] rounded-[6px]'>Qeydiyyat ol</button>
                </div>
            </div>
            {tnRegConfModal? <EmailPhoneConfTN data={tn_register} onClose={()=>this.props.changeStateValue({name:"tnRegConfModal", value: false})} /> :null}
      </div>
    )
  }
}
const mapStateToProps = (state) =>({
    tn_register: state.Data.tn_register,
    language: state.Data.language,
    phoneValidFormat: state.Data.phoneValidFormat,
    tnRegConfModal: state.Data.tnRegConfModal,
});
const mapDispatchToProps = {changeStateValue, insertNewData, insertNewDataParams}
export default connect(mapStateToProps, mapDispatchToProps)(TNRegisterPage)
