import React, { Component } from 'react'
import Header from '../../components/header/Header'
import Search from '../../components/Search'
import Buttons from '../../components/Buttons'
import { connect } from 'react-redux'
import { changeStateValue } from '../../redux/MainReducer';
import { getEduDegree } from '../../actions/MainAction';
import Card1 from '../../components/cards/Card1'
import LoadMoreBtn from '../../components/LoadMoreBtn'
export class AdminEducationDegreePage extends Component {

  openModal=(data)=>{
    this.props.changeStateValue({
      name:'adminModal',
      value:{
        position: true,
        id: data?.id?data?.id:'',
        name: data?.name?data?.name:'',
        name_en: data?.name_en?data?.name_en:'',
        name_tr: data?.name_tr?data?.name_tr:'',
        name_ru: data?.name_ru?data?.name_ru:'',
        afterCallFunction:this.props.getEduDegree,
        afterCallParams:[],
        requireds: ["name"],
        url:'education-degree',
        modalTitle:`${data?.id?'Edit':'Add'} edu degree`
      }
    })
  }

  componentDidMount(){
    this.props.getEduDegree()
  }


  afterCallFunction=()=>{
    this.props.getEduDegree()
  }
  render() {
    const {educationDegree} = this.props;
  
    return (
        <div className='w-full  pl-[27px] pr-[27px]'>
            <Header title="University Types" />
            <div className='flex justify-end mt-[30px]'>
                <Buttons onClick={this.openModal.bind(this, '')} options={[{title:"Add a edu degree", value:0}]} selected={0} />
            </div>
           

            <div className='grid-full-250 mt-[30px]'>
              {
                educationDegree?.map((data, i)=>{
                  return(
                    <Card1 
                      key={i}
                      name={data?.name}
                      onClickEdit={this.openModal.bind(this, data)}
                      deletePath={`education-degree/${data?.id}`}
                      afterCallFunction={this.afterCallFunction.bind(this)}
                    />
                  )
                })
              }
            </div>
            {
                educationDegree?.length >=20?
                <LoadMoreBtn onLoadData={this.loadMore} count={educationDegree?.length} />:null
            }
        </div>
    )
  }
}
const mapStateToProps = (state) =>({
  educationDegree: state.Data.educationDegree
});
const mapDispatchToProps = {changeStateValue, getEduDegree}
export default connect(mapStateToProps, mapDispatchToProps)(AdminEducationDegreePage)




