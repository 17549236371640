import React, { Component } from 'react'
import { changeStateValue } from '../../redux/MainReducer';
import { getSingleData } from '../../actions/MainAction';
import { connect } from 'react-redux';
import { getDegreeByUniversity, getUProgramByScholarhip } from '../../actions/PublicActions';
import { withHooks } from '../../actions/withHooks';
import SinglePageHeader from '../../components/public_components/SinglePageHeader';
import Card5 from '../../components/public_components/cards/Card5';
import { getMoneyFormat, getStartFrom, getTranslateLanguage } from '../../actions/getOtherFunctions';
import LoadMoreBtn from '../../components/LoadMoreBtn';
import Footer from './sections/Footer';
import { Helmet } from 'react-helmet';
import SectionHeader from '../../components/public_components/SectionHeader';
import moment from 'moment';
import WarningSvg from '../../components/svg/WarningSvg';
import { Link } from 'react-router-dom';
import FaqCard from '../../components/public_components/FaqCard';

export class SingleSchUniPage extends Component {
    state={
        universityData:'',
        degreeId:'',
        page:1
    }
    componentDidMount(){
        this.props.getSingleData(`public-university/${this.props.params?.id}?type=name`)
        .then(respuni=>{
            if(respuni == ""){this.props.navigate("/*")}
            this.setState({universityData:respuni});
            this.props.getDegreeByUniversity(respuni?.id, "sch_program")
            .then(resp=>{
                if(resp?.length !=0){
                    this.getProgramByDegree(resp[0]?.id);
                }
            })
        });
       
        
        this.props.changeStateValue({
            name:"programBySch",value:[]
        });

        window.scrollTo(0,0);
        try{
            let url = this.props.params.id;
            if(url){
                let checkUrl = url.includes("_");
                if(!checkUrl){
                    let checkUrl2 = url.includes(" ");
                    if(!checkUrl2){
                        let checkUrl3 = url.includes("-");
                        if(checkUrl3){
                            window.location.href = `/scholarships/${url?.replaceAll("-", "_")}`
                        }
                    }
                }
            }
        }catch(err){}

        
    }
    getProgramByDegree(degreeId){
        this.setState({degreeId:degreeId})
        this.props.getUProgramByScholarhip(this.state.page, this.state.universityData?.id, degreeId);
    }
    loadMore=(page)=>{
        this.setState({page:page})
        this.props.getUProgramByScholarhip(page, this.state.universityData?.id, this.state.degreeId);
    }
    checkApplyAvailable=(data, registerType)=>{
        let disciplines = [];
        for(const x of data?.disciplines){
          disciplines.push(x?.discipline_id)
        }
        const params = {
          page:"edu_back",
          countries:[data?.university?.country_id],
          disciplines:disciplines,
          educationBackground:{
              country_id:'1',
              edu_degree_id:'',
              sub_edu_degree_id:'',
              grade:'',
              a_level:'',
              ap:'',
              ib:'',
              ossd:'',
              foundation:''
          },
          information:{
              first_name:'',
              last_name:'',
              phone_number:''
          },
          registerType:registerType
        }
        this.props.changeStateValue({name:'applyDetails', value:params})
        this.props.changeStateValue({name:'applyDetails.registerType', value:registerType})
        this.props.changeStateValue({name:'admissionModal.position', value:true})
    }
  render() {
    const {programBySch, degreeByUniversity} = this.props;
    const {universityData, degreeId} = this.state;
    // console.log(universityData)
    return (
        <div className='mt-[80px] bg-[#F5F5F5]'>
             {
                 universityData !=""?
                 <Helmet>
                   <title>{getTranslateLanguage(this.props.lang_id, "seo_title", universityData)}</title>
                   <meta property="og:locale" content="az_AZ" />
                   <meta property="og:type" content="website" />
                   <meta property="og:title" content={getTranslateLanguage(this.props.lang_id, "seo_title", universityData)} />
                   <meta property="og:description" content={getTranslateLanguage(this.props.lang_id, "seo_description", universityData)} />
                   <meta name="description" content={getTranslateLanguage(this.props.lang_id, "seo_description", universityData)}/>
                   <meta name="keywords" content={getTranslateLanguage(this.props.lang_id, "seo_keywords", universityData)}/>
                   <meta property="og:url" content={`https://celtgo.com${window.location.pathname}`} />
                   <meta property="og:site_name" content="CELT Colleges" />
                   <meta property="article:publisher" content="https://www.facebook.com/celtscollege" />
                   <meta property="article:modified_time" content="2024-06-06T08:56:25+00:00" />
                   <meta property="og:image" content={`${universityData?.image_full_url}`} />
                   <meta property="og:image:width" content="900" />
                   <meta property="og:image:height" content="600" />
                   <meta property="og:image:type" content="image/jpeg" />
                   <meta name="twitter:card" content="summary_large_image" />
                   <meta name="twitter:title" content={getTranslateLanguage(this.props.lang_id, "seo_title", universityData)} />
                   <meta name="twitter:description" content={getTranslateLanguage(this.props.lang_id, "seo_description", universityData)} />
                   <meta name="twitter:site" content="@celtcolleges" />
                   <meta name="twitter:label1" content="Est. reading time" />
                   <meta name="twitter:data1" content="7 minutes" />
                   <link rel="canonical" href={`https://celtgo.com${window.location.pathname}`} />
                 </Helmet>:null
            }
            <SinglePageHeader name={getTranslateLanguage(this.props.lang_id, "name", universityData)} image={universityData?.image_full_url} />
            <div className='max-w-[1200px] ml-auto mr-auto max-[1240px]:ml-[20px] max-[1240px]:mr-[20px] mt-[50px]'>
                <div className='border border-orange-400 p-[15px] rounded-[10px] flex items-center gap-[10px] text-[14px]'>
                    <WarningSvg />
                    <p>{this.props.language?.uni_sch_note?.split("_btn_")[0]}  <Link to={`/${this.props.langPrefix}university/${this.props.params.id}`} className='text-blue-600'>{this.props.language?.here_btn}</Link> {this.props.language?.uni_sch_note?.split("_btn_")[1]}</p>
                </div>
            </div>
            <section className='mb-[50px]'>
                <div className='max-w-[1200px] ml-auto mr-auto max-[1240px]:ml-[20px] max-[1240px]:mr-[20px] mt-[40px]'>
                    <div className='flex flex-wrap items-center justify-center gap-[15px]'>
                        {
                            degreeByUniversity?.map((data, i)=>{
                                return(
                                    <button onClick={this.getProgramByDegree.bind(this, data?.id)} className={`p-[6px_20px] text-[12px] font-regular rounded-[5px] ${data?.id === degreeId?'bg-[#1E398F] text-white':'text-[rgba(0,0,0,0.4)] border border-[rgba(0,0,0,0.4)]'}`} key={i}>{getTranslateLanguage(this.props.lang_id, "name", data)}</button>
                                )
                            })
                        }
                    </div>
                    {
                        programBySch[0] && getStartFrom(programBySch[0]?.degree_id, programBySch[0]?.university)?
                        <h3 className='text-center font-semibold text-[25px] mt-[20px]'>
                        {this.props.language?.yearly_fee} $ {getMoneyFormat(getStartFrom(programBySch[0]?.degree_id, programBySch[0]?.university))} {this.props.language?.starting}
                        </h3>:null
                    }
                   <div className=' mt-[20px]'>
                    {
                        programBySch?.map((data, i)=>{
                            return (
                                <div key={i}>
                                    <div  key={i} className='bg-white  rounded-[10px] p-[5px] mt-[10px]'>
                                    <table className='w-full coll  '>
                                    <thead>
                                        <tr className='bg-[#F6F6F6] h-[45px]'>
                                            <th className={`text-center border max-[400px]:text-[12px] rounded-l-[10px] pl-[10px] w-1/3`}>{this.props.language?.program_name}</th>
                                            <th className={`text-center border max-[400px]:text-[12px] pl-[10px] w-1/3`}>{this.props.language?.deadline}</th>
                                            <th className={`text-center border max-[400px]:text-[12px] rounded-r-[10px] pl-[10px] w-1/3`}>{this.props.language?.duration}</th>
                                            
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr className={`h-[45px] ${2%2===0? "":"bg-[#F6F6F6]"}`}>
                                            <td className='text-center border max-[400px]:text-[12px] rounded-l-[10px]  pl-[10px]'>{data?.name}</td>
                                            <td className='text-center border max-[400px]:text-[12px]   pl-[10px]'>{moment(data?.deadline).format("D MMMM")}</td>
                                            <td className='text-center border max-[400px]:text-[12px] rounded-r-[10px]  pl-[10px]'>{data?.duration} {this.props.language?.year}</td>
                                        </tr>
                                    </tbody>
                                </table>
                                    
                                </div>
                                    <div className='w-full flex justify-center mt-[5px] mb-[20px]'>
                                        <button onClick={this.checkApplyAvailable.bind(this, data, "program")} className='bg-[var(--blue)] h-[34px] text-[14px] pl-[10px] pr-[10px] rounded-full text-white font-semibold'>{this.props.language?.accept_change_check}</button>
                                    </div>
                                </div>
                                           
                                )
                            })
                        }
                    </div>
                    {/* <div className='grid-full-380 mt-[20px]'>
                    {
                        programBySch?.map((data, i)=>{
                        return(
                            <Card5 key={i} 
                                data={data} 
                                name={data?.name}
                                image={data?.image_full_url}
                                // degree={data?.degree?.name}
                                // university={data?.university?.name}
                                // path={`/program/${data?.id}`} 
                                start_fee={getStartFrom(data?.degree_id, data?.university)}
                                footer={false}
                                start_fee_position={true}
                                type="program"
                                registerType="scholarship"
                            />
                        )
                        })
                    }
                    </div> */}
                    {
                        programBySch?.length >=20?
                        <LoadMoreBtn onLoadData={this.loadMore} count={programBySch?.length} />:null
                    }
                    {
                        universityData?.faqs?.length != 0?
                        <div className='flex flex-col gap-[10px] mt-[20px]'>
                            <h2 className='text-center text-[44px] font-semibold leading-[70px] max-[450px]:text-[30px] max-[450px]:leading-[35px] max-[450px]:text-center'>{this.props.language?.faq_title}</h2>
                            {
                                universityData?.faqs?.map((data, i)=>{
                                    return (
                                        <FaqCard key={i} data={data} />
                                    )
                                })
                            }
                        </div>:null
                    }
                       {
                    universityData?.about?
                    <div className='bg-white p-[20px] mt-[40px] rounded-[10px]'>
                        <SectionHeader text1={this.props.language?.uni_about} text2="" />
                        <p className='mt-[20px] uni_about' dangerouslySetInnerHTML={{ __html: `<pre class="whitespace-break-spaces">${getTranslateLanguage(this.props.lang_id, "about", universityData)?.replace(/###\s*(.*)/, '<h2>$1</h2>')?.replace(/\*\*(.*?)\*\*/g, '<h3>$1</h3>')}</pre>` }}></p>
                    </div>:null
                }
                </div>
            </section>
            <Footer />
        </div>
    )
  }
}
const mapStateToProps = (state) =>({
    programBySch: state.Data.programBySch,
    degreeByUniversity: state.Data.degreeByUniversity,
    lang_id: state.Data.lang_id,
    language: state.Data.language,
    langPrefix: state.Data.langPrefix,
});
const mapDispatchToProps = {changeStateValue, getSingleData, getUProgramByScholarhip, getDegreeByUniversity}
export default connect(mapStateToProps, mapDispatchToProps)(withHooks(SingleSchUniPage))
