import React, { Component } from 'react'
import SectionHeader from '../../components/public_components/SectionHeader'
import Footer from './sections/Footer'
import ContactInput from '../../components/public_components/ContactInput'
import SmileSvg from '../../components/svg/SmileSvg'
import { changeStateValue, resetStateValue } from '../../redux/MainReducer'
import { connect } from 'react-redux'
import InpEmailSvg from '../../components/svg/InpEmailSvg'
import InpTelSvg from '../../components/svg/InpTelSvg'
import ContactDescription from '../../components/public_components/ContactDescription'
import InpDescSvg from '../../components/svg/InpDescSvg'
import InpSendSvg from '../../components/svg/InpSendSvg'
import BtnRightSvg from '../../components/public_components/svg/BtnRightSvg'
import { Helmet } from 'react-helmet'
import { getSeoPageData, insertNewData } from '../../actions/MainAction'
import { getTranslateLanguage } from '../../actions/getOtherFunctions'
import ContactPhoneSvg from '../../components/svg/ContactPhoneSvg'
import ContactEmailSvg from '../../components/svg/ContactEmailSvg'
import ContactMapSvg from '../../components/svg/ContactMapSvg'
import Input from '../../components/modals/components/Input'
import InpSvg from '../../components/svg/InpSvg'
import { mixinAlert } from '../../actions/SweetAlerts'
import ContactWpSvg from '../../components/svg/ContactWpSvg'

export class ContactPage extends Component {
  state={
    btnDisable:false
  }
  componentDidMount(){
    window.scrollTo(0,0);
    this.props.getSeoPageData("contact_page")
  }

  submitForm=(data)=>{
    if(data?.first_name?.trim() !=="" && data?.last_name?.trim() !=="" && data?.phone_number?.trim() !=="" && data?.email?.trim() !==""){
        this.setState({btnDisable: true})
        let formData = new FormData();
        for(const property in data){
          formData.append(property, data[property])
        }
        this.props.insertNewData("contact-form", formData)
        .then(resp=>{
          this.props.resetStateValue({name:"contactForm"});
          this.setState({btnDisable: false});
          mixinAlert("success", "Müraciətiniz qeydə alındı.")
        })
        
    }else{
      mixinAlert("warning", "Bütün xanaları doldurun.")
    }
    console.log(data)
  }
  render() {
    const {contactInfo, seo_page_data, contactForm} = this.props
    return (
      <div className='mt-[40px] bg-[#fff]'>
        {
          seo_page_data !=""?
          <Helmet>
          <title>{getTranslateLanguage(this.props.lang_id, "seo_title", seo_page_data)}</title>
          <meta property="og:locale" content="az_AZ" />
          <meta property="og:type" content="website" />
          <meta property="og:title" content={getTranslateLanguage(this.props.lang_id, "seo_title", seo_page_data)} />
          <meta property="og:description" content={getTranslateLanguage(this.props.lang_id, "seo_description", seo_page_data)} />
          <meta name="description" content={getTranslateLanguage(this.props.lang_id, "seo_description", seo_page_data)}/>
          <meta name="keywords" content={getTranslateLanguage(this.props.lang_id, "seo_keywords", seo_page_data)}/>
          <meta property="og:url" content={`https://celtgo.com${window.location.pathname}`} />
          <meta property="og:site_name" content="CELT Colleges" />
          <meta property="article:publisher" content="https://www.facebook.com/celtscollege" />
          <meta property="article:modified_time" content="2024-06-06T08:56:25+00:00" />
          <meta property="og:image" content="https://celtgo.com/static/media/celtLogo6.05ddd884642befe11a4e.png" />
          <meta property="og:image:width" content="900" />
          <link rel="canonical" href={`https://celtgo.com${window.location.pathname}`} />
          <meta property="og:image:height" content="600" />
          <meta property="og:image:type" content="image/jpeg" />
          <meta name="twitter:card" content="summary_large_image" />
          <meta name="twitter:title" content={getTranslateLanguage(this.props.lang_id, "seo_title", seo_page_data)} />
          <meta name="twitter:description" content={getTranslateLanguage(this.props.lang_id, "seo_description", seo_page_data)} />
          <meta name="twitter:site" content="@celtcolleges" />
          <meta name="twitter:label1" content="Est. reading time" />
          <meta name="twitter:data1" content="7 minutes" />
        </Helmet>:null
        }
            
          <section className='bg-white pt-[100px]'>
            <div className=''>
                <SectionHeader headingType="h1" text1={this.props.language?.section_title9} text2="" />
            </div>
          
            <div className='max-w-[1200px] ml-auto mr-auto mt-[70px]  max-[1240px]:ml-[20px] max-[1240px]:mr-[20px] relative max-[520px]:mt-[30px]'>
              <div className='grid grid-cols-3 gap-[20px] max-[800px]:grid-cols-2 max-[520px]:grid-cols-1 max-[520px]:items-center'>
                <div className='max-[520px]:flex max-[520px]:flex-col max-[520px]:items-center max-[520px]:border-b max-[520px]:border-dashed max-[520px]:pb-[15px]'>
                  <ContactPhoneSvg />
                  <h3 className='text-[27px] text-[#11181c] font-[600] mt-[6px] max-[520px]:text-[20px] mb-[15px]'>
                    <a href="tel:+994508585114">+994 50 858 51 14</a>
                  </h3>
                  <ContactWpSvg />
                  <h3 className='text-[27px] text-[#11181c] font-[600] mt-[6px] max-[520px]:text-[20px]'>
                    <a target="_blank" href="https://wa.me/994508585114">+994 50 858 51 14</a>
                  </h3>
                </div>
                <div className='max-[520px]:flex max-[520px]:flex-col max-[520px]:items-center max-[520px]:border-b max-[520px]:border-dashed max-[520px]:pb-[15px]'>
                  <ContactEmailSvg />
                  <h3 className='text-[27px] text-[#11181c] font-[600] mt-[6px] max-[520px]:text-[20px]'>
                    <a href="mailto:elgun@celtgo.com">elgun@celtgo.com</a>
                  </h3>
                </div>
                <div className='max-[520px]:flex max-[520px]:flex-col max-[520px]:items-center max-[520px]:border-b max-[520px]:border-dashed max-[520px]:pb-[15px]'>
                  <ContactMapSvg />
                  <h3 className='text-[27px] text-[#11181c] font-[600] mt-[6px] max-[520px]:text-[20px] max-[520px]:text-center'>
                    <a target='_blank' href="https://maps.app.goo.gl/AddKVUrkGqABWgAe8">{this.props.language?.address}</a>
                  </h3>
                  <a target='_blank' className='p-[4px_8px] bg-blue text-white text-[14px] rounded-[6px] inline-block mt-[8px]' href='https://maps.app.goo.gl/AddKVUrkGqABWgAe8'>Xəritəyə bax</a>
                </div>
              </div>
              {/* <div className='bg-white   rounded-[10px] shadow bottom-0 right-0 p-[60px] max-[600px]:relative max-[600px]:bottom-[unset] max-[600px]:mt-[20px] max-[600px]:w-full max-[400px]:p-[20px]'>
                <h2 className='text-[28px] text-[#313e3b] font-semibold'>CELT Knightsbridge</h2>
                <p className='text-[#828c8a] text-[17px] font-regular mt-[10px]'>151 8 November Ave, Baku 1000</p>
                <ul className='mt-[10px] flex flex-col gap-[10px]'>
                  <li className='text-[#828c8a]'><span className='font-semibold  text-[#313e3b]'>T:</span> <a href='tel:+994508585114'>+994 50 858 51 14</a></li>
                  <li className='text-[#313e3b] font-semibold'><span className='font-semibold  text-[#313e3b]'>E:</span> <a href='mailto:hello@studycoach.vip'>hello@studycoach.vip</a></li>
                </ul>
                <a target='_blank' href='https://maps.app.goo.gl/2eivA3pFgdSHiHmn7' className='bg-blue inline-flex items-center justify-center gap-[10px] rounded-full p-[10px_20px] text-white text-[14px] mt-[10px]'>
                  Google map
                  <BtnRightSvg />
                </a>
              </div> */}
              {/* <iframe className='rounded-[10px] relative mt-[60px] max-[700px]:h-auto' src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d12157.376589711479!2d49.89197!3d40.3790646!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x40307d96c6119853%3A0x1daf056ac15ce9a7!2sCELT%20Knightsbridge!5e0!3m2!1sen!2saz!4v1719553103220!5m2!1sen!2saz" width="100%" height="800px" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe> */}
              {/* <iframe className='rounded-[10px] relative mt-[60px] max-[700px]:h-auto'  src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d12156.884052939049!2d49.866566!3d40.381794!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x40307d1b3e601b49%3A0xb923a92b9b1a3639!2sCELT%20Colleges%20Xetai%20-%20%C4%B0ngilis%20dili%20kurslari!5e0!3m2!1sen!2saz!4v1729600790631!5m2!1sen!2saz" width="100%" height="800px" allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe> */}
            
              <form onSubmit={(e)=>e.preventDefault()}>
                <h3 className='mt-[30px] text-center text-[24px] max-[550px]:text-[18px]'>{this.props.language?.contact_help_text}</h3>
                <div className='grid grid-cols-2 gap-[30px] mt-[20px] max-[550px]:grid-cols-1'>
                  <Input 
                    bigInp svg={<InpSvg />} bg="bg-[#F3F4F6]"
                    required
                    redborder={false}
                    name={`contactForm.first_name`}  
                    value={contactForm?.first_name} 
                    reqText={this.props.language?.required_txt}
                    title={this.props.language?.form_name}
                  />
                  <Input 
                    bigInp svg={<InpSvg />} bg="bg-[#F3F4F6]"
                    required
                    redborder={false}
                    name={`contactForm.last_name`}  
                    value={contactForm?.last_name} 
                    reqText={this.props.language?.required_txt}
                    title={this.props.language?.form_name}
                  />
                  <Input 
                    bigInp svg={<InpSvg />} bg="bg-[#F3F4F6]"
                    required
                    redborder={false}
                    name={`contactForm.phone_number`}  
                    value={contactForm?.phone_number} 
                    reqText={this.props.language?.required_txt}
                    title={this.props.language?.form_name}
                  />
                  <Input 
                    bigInp svg={<InpSvg />} bg="bg-[#F3F4F6]"
                    required
                    redborder={false}
                    name={`contactForm.email`}  
                    value={contactForm?.email} 
                    reqText={this.props.language?.required_txt}
                    title={`Email`}
                  />
                </div>
                <div className='mt-[20px]'>
                  <label className={'inline-flex items-center gap-[5px] text-[12px] txt-light-black '}><InpSvg /> {this.props.language?.form_note}</label>
                  <textarea name={"contactForm.note"} value={contactForm?.note} onChange={(e)=>this.props.changeStateValue({name:"contactForm.note", value: e.target.value})} className='w-full bg-gray-100 h-[150px] rounded-[8px] text-[14px] p-[8px] outline-none' placeholder='Qeyd' />
                </div>
                <div className='flex mt-[20px]'>
                    <button onClick={this.submitForm.bind(this, contactForm)} className='bg-blue text-[14px] p-[5px_8px] rounded-[6px] text-white'>
                      {this.props.language?.send_txt}
                    </button>
                </div>
              </form>
            </div>
        </section>
        <Footer />
      </div>
    )
  }
}
const mapStateToProps = (state) =>({
  contactInfo: state.Data.contactInfo,
  language: state.Data.language,
  seo_page_data: state.Data.seo_page_data,
  lang_id: state.Data.lang_id,
  contactForm: state.Data.contactForm,
});
const mapDispatchToProps = {changeStateValue, getSeoPageData, insertNewData, resetStateValue}
export default connect(mapStateToProps, mapDispatchToProps)(ContactPage)
