import React, { Component } from 'react'
import HeaderRightSvg from '../../../components/public_components/svg/HeaderRightSvg'
import { Link } from 'react-router-dom'
import img from "../../../images/Images.png"
import { changeStateValue } from '../../../redux/MainReducer'
import { connect } from 'react-redux'

export class MainHeaderSection extends Component {
    componentDidMount(){
        try{
            const search = window.location.search;
            if(search.includes("ixtisaslara-bax")){
                this.props.changeStateValue({name:'admissionModal.position', value:true})
            }else if(search.includes("qebul-teklifi-al")){
                this.props.changeStateValue({name:'educationPlanModalData.position', value:true})
            }
        }catch(err){}
    }
  render() {
    return (
        <section className='bg-white'>
        <div className='max-w-[1200px] ml-auto mr-auto max-[1240px]:ml-[20px] max-[1240px]:mr-[20px] flex justify-between items-center max-[950px]:flex-col max-[950px]:items-center max-[490px]:pb-[20px]'>
            <div className='max-w-[549px] max-[950px]:flex  max-[950px]:flex-col max-[950px]:items-center max-[950px]:mt-[20px]'>
                <h2  className='text-[14px] uppercase ' >{this.props.language?.sub_title}</h2>
                <h1 className='font-semibold text-[64px] mt-[20px] leading-[70px] max-[950px]:text-center max-[450px]:text-[40px] max-[450px]:leading-[40px] text-[#1E398F]'>{this.props.language?.heading}</h1>
                <p className='flex items-center gap-[14px] text-[14px] font-regular mt-[22px] max-[600px]:text-center'>
                    {/* <HeaderRightSvg /> */}
                    <span>{this.props.language?.title}</span>
                </p>
                <span className='font-bold inline-block mt-[10px] text-[14px] txt-blue ml-[55px] max-[950px]:ml-[0px]'>{this.props.language?.foreign_uni_for}</span>
                <div className='mt-[5px] flex items-center gap-[17px] max-[450px]:flex-col'>
                    <button onClick={()=>this.props.changeStateValue({name:'admissionModal.position', value:true})} className='h-[52px] p-[9px_27px] bg-[#1E398F] text-white rounded-[10px] font-[500] text-[14px] text-center inline-flex items-center justify-center max-[450px]:w-[180px]' to={'/apply'}>
                        {this.props.language?.show_prog}
                    </button>
                    <button onClick={()=>this.props.changeStateValue({name:'educationPlanModalData.position', value:true})} className='h-[52px] p-[9px_27px] bg-[#fff] text-[#1E398F] border border-[#1E398F] rounded-[10px] font-[500] text-[14px] text-center inline-flex items-center justify-center max-[450px]:w-[180px]' to={'/apply'}>
                        {this.props.language?.edu_plan}
                    </button>
                </div>
                <span className='inline-block mt-[10px] text-[14px] font-[300]'>{this.props.language?.no_fee_service}</span>
            </div>
            <div className='mb-[-70px] max-[490px]:hidden'>
                <img src={img} className='w-[529px] h-[561px]' />
            </div>
        </div>
    </section>
    )
  }
}
const mapStateToProps = (state) =>({
    language: state.Data.language
});
const mapDispathToProps = {changeStateValue}
export default connect(mapStateToProps, mapDispathToProps)(MainHeaderSection)
