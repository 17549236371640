import React, { Component } from 'react'
import { connect } from 'react-redux';
import { getCheckStudentApply, getEducationBackground, getPersonalInformation, getSingleData, getStudentExams, getStudyDestination } from '../../../../actions/MainAction';
import InfoCard from './InfoCard';
import moment from 'moment';

export class StudentInfo extends Component {
    state={
        studentData:'',
        edubackground:'',
        studyDestination:'',
        studentExams:'',
    }
    componentDidMount(){
        this.props.getSingleData(`users/${this.props.student_id}`)
        .then(resp=>{
          this.setState({studentData:resp})
        });

        this.props.getEducationBackground(this.props.student_id)
        .then(data=>{
          this.setState({edubackground:data})
        });

        this.props.getStudyDestination(this.props.student_id)
        .then(data=>{
          this.setState({studyDestination: data})
        })

        this.props.getStudentExams(this.props.student_id)
        .then(data=>{
          this.setState({studentExams:data})
        })

        this.props.getCheckStudentApply(this.props.student_id)

        this.props.getPersonalInformation(this.props.student_id)
    }
  render() {
    const {studentData, edubackground, studyDestination, studentExams} = this.state;
    const {checkStudentApply, studentPersonalInformation} = this.props;
    
    return (
      <div className='bg-white p-[20px] shadow rounded-[10px]'>
        <div className='border p-[10px] rounded-[10px]'>
            <h2 className='text-[18px] font-[500]'>Şəxsi məlumatlar</h2>
            <div className='grid grid-cols-3 max-[1300px]:grid-cols-2 max-[600px]:grid-cols-1 gap-[30px] mt-[15px]'>
                {studentData?.first_name && studentData?.first_name !=""? <InfoCard title="Ad" value={studentData?.first_name} />:null}
                {studentData?.last_name && studentData?.last_name !=""? <InfoCard title="Soyad" value={studentData?.last_name} />:null}
                {studentData?.username && studentData?.username !=""? <InfoCard title="İstifadəçi adı" value={studentData?.username} />:null}
                {studentData?.email && studentData?.email !=""? <InfoCard title="Email" value={studentData?.email} />:null}
                {studentData?.phone_number && studentData?.phone_number !=""? <InfoCard title="Nömrə" value={studentData?.phone_number} />:null}
                {studentData?.date_of_birth && studentData?.date_of_birth !=""? <InfoCard title="Doğum tarixi" value={moment(studentData?.date_of_birth)?.format("DD/MM/YYYY")} />:null}
                {studentData?.created_at && studentData?.created_at !=""? <InfoCard title="Qeydiyyat tarixi" value={moment(studentData?.created_at)?.format("DD/MM/YYYY")} />:null}
                {edubackground?.finish_year?<InfoCard title="Qeydiyyat növü" value={"Təhsil planı"} />: <InfoCard title="Qeydiyyat növü" value={"Müraciət"} />}
                {studentData?.verify_type?<InfoCard title="Təsdiqləmə növü" value={studentData?.verify_type} />: null}
              
            </div>
        </div>
        <div className='border p-[10px] rounded-[10px] mt-[20px]'>
            <h2 className='text-[18px] font-[500]'>Təhsil məlumatları</h2>
            <div className='grid grid-cols-3 max-[1300px]:grid-cols-2 max-[600px]:grid-cols-1 gap-[30px] mt-[15px]'>
                {edubackground?.country && edubackground?.country !=""? <InfoCard title="Oxuduğu ölkə" value={edubackground?.country?.name} />:null}
                {edubackground?.edu_degree && edubackground?.edu_degree !=""? <InfoCard title="Ən son təhsil diplomu" value={edubackground?.edu_degree?.name} />:null}
                {edubackground?.sub_edu_degree && edubackground?.sub_edu_degree !=""? <InfoCard title="Məktəb Diplomu" value={edubackground?.sub_edu_degree?.name} />:null}
                {edubackground?.edu_degree_id !=1 && edubackground?.grade_range && edubackground?.grade_range !=""? <InfoCard title="Qiymət aralığı" value={edubackground?.grade_range} />:null}
                {edubackground?.grade && edubackground?.grade !=""? <InfoCard title="Ortalama qiymət" value={edubackground?.grade} />:null}
                {edubackground?.a_level && edubackground?.a_level !=""? <InfoCard title="A Level" value={edubackground?.a_level} />:null}
                {edubackground?.ap && edubackground?.ap !=""? <InfoCard title="AP" value={edubackground?.ap} />:null}
                {edubackground?.ib && edubackground?.ib !=""? <InfoCard title="IB" value={edubackground?.ib} />:null}
                {edubackground?.ossd && edubackground?.ossd !=""? <InfoCard title="OSSD" value={edubackground?.ossd} />:null}
                {edubackground?.finish_year && edubackground?.finish_year !=""? <InfoCard title="Məktəbi hansı ildə bitirib" value={edubackground?.finish_year=="graduated"?"Bitirmişəm":edubackground?.finish_year} />:null}
               
            </div>
        </div>
        <div className='border p-[10px] rounded-[10px] mt-[20px]'>
            <h2 className='text-[18px] font-[500]'>Ölkə və ixtisas</h2>
            <div className='grid grid-cols-3 max-[1300px]:grid-cols-2 max-[600px]:grid-cols-1 gap-[30px] mt-[15px]'>
                {
                    studyDestination?.countries?.map((data, i)=>{
                        return (
                            <InfoCard key={i} title="Oxumaq istədiyi ölkə" value={data?.country?.name} />
                        )   
                    })
                }
                {
                    studyDestination?.states?.map((data, i)=>{
                        return (
                            <InfoCard key={i} title="Oxumaq istədiyi ştat" value={data?.state?.name} />
                        )   
                    })
                }
                {
                    studyDestination?.cities?.map((data, i)=>{
                        return (
                            <InfoCard key={i} title="Oxumaq istədiyi şəhər" value={data?.city?.name} />
                        )   
                    })
                }
                {
                    studyDestination?.disciplines?.map((data, i)=>{
                        return (
                            <InfoCard key={i} title="Oxumaq istədiyi ixtisas" value={data?.discipline?.name} />
                        )   
                    })
                }
              
            </div>
        </div>
        <div className='border p-[10px] rounded-[10px] mt-[20px]'>
            <h2 className='text-[18px] font-[500]'>İmtahan nəticələri</h2>
            <div className='grid grid-cols-3 max-[1300px]:grid-cols-2 max-[600px]:grid-cols-1 gap-[30px] mt-[15px]'>
                {studentExams?.ielts && studentExams?.ielts !=""? <InfoCard title="IELTS" value={studentExams?.ielts} />:null}
                {studentExams?.ielts_ukvi && studentExams?.ielts_ukvi !=""? <InfoCard title="IELTS UKVI" value={studentExams?.ielts_ukvi} />:null}
                {studentExams?.toefl && studentExams?.toefl !=""? <InfoCard title="TOEFL" value={studentExams?.toefl} />:null}
                {studentExams?.sat && studentExams?.sat !=""? <InfoCard title="SAT" value={studentExams?.sat} />:null}
                {studentExams?.gre && studentExams?.gre !=""? <InfoCard title="GRE" value={studentExams?.gre} />:null}
                {studentExams?.gmat && studentExams?.gmat !=""? <InfoCard title="GMAT" value={studentExams?.gmat} />:null}
                {studentExams?.dim && studentExams?.dim !=""? <InfoCard title="DIM" value={studentExams?.dim} />:null}
          
            </div>
        </div>
        <div className='border p-[10px] rounded-[10px] mt-[20px]'>
            <h2 className='text-[18px] font-[500]'>Digər məlumatlar</h2>
            <div className='grid grid-cols-3 max-[1300px]:grid-cols-2 max-[600px]:grid-cols-1 gap-[30px] mt-[15px]'>
                {studentPersonalInformation?.info?.country && studentPersonalInformation?.info?.country !=""? <InfoCard title="Ölkə" value={studentPersonalInformation?.info?.country} />:null}
                {studentPersonalInformation?.info?.city && studentPersonalInformation?.info?.city !=""? <InfoCard title="Şəhər" value={studentPersonalInformation?.info?.city} />:null}
                {studentPersonalInformation?.info?.address && studentPersonalInformation?.info?.address !=""? <InfoCard title="Ünvan" value={studentPersonalInformation?.info?.address} />:null}
                {
                    studentPersonalInformation?.info?.cv?
                    <div>
                        <span className='text-[14px] txt-light-black font-[400]'>CV</span>
                        <a  target='_blank' href={studentPersonalInformation?.info?.cv_full_url} className='bg-gray-100 p-[8px] rounded-[7px] block'>
                            <a target='_blank' className='text-[14px] text-blue-500' href={studentPersonalInformation?.info?.cv_full_url} >CV</a>
                        </a>
                    </div>:null
                }
                {
                    studentPersonalInformation?.passport?.map((data, i)=>{
                        return (
                            <div key={i}>
                                <span className='text-[14px] txt-light-black font-[400]'>Pasport {i+1}</span>
                                <a  target='_blank' href={data?.image_full_url} className='bg-gray-100 p-[8px] rounded-[7px] block'>
                                    <a target='_blank' className='text-[14px] text-blue-500' href={data?.image_full_url} >Pasport {i+1}</a>
                                </a>
                            </div>
                        )
                    })
                }
            </div>
        </div>
      </div>
    )
  }
}
const mapStateToProps = (state) =>({
    checkStudentApply: state.Data.checkStudentApply,
    studentPersonalInformation: state.Data.studentPersonalInformation,
});
const mapDispatchToProps = {getSingleData, getEducationBackground, getStudyDestination, getStudentExams, getCheckStudentApply, getPersonalInformation}
export default connect(mapStateToProps, mapDispatchToProps)(StudentInfo)
