import React, { Component } from 'react'
import { changeStateValue } from '../../../redux/MainReducer';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import CardRightSvg from '../../../components/svg/CardRightSvg';
import DocSvg from '../../../components/svg/DocSvg';

export class DocumentInfo extends Component {
    openTextModal=(text)=>{
        this.props.changeStateValue({
          name:'seeTextModalData',
          value:{
              position:true,
              text:text
          }
      })
      }
  render() {
    const {options} = this.props;
    return (
        <div className='bg-white rounded-[4px] bg-white p-[41px_32px_32px_15px]'>
            <h2 className='text-[#303972] text-[24px] font-bold'>{"Sənədlər"}</h2>
            {
                options.length ==0?
                <div className=' h-full flex justify-center items-center'>
                    <p className='txt-blue text-[20px] font-semibold'>Sənədləriniz hazırlanır.</p>
                </div>:null

            }
            <div className='flex flex-col gap-[11px] mt-[30px]'>
                {
                    options?.map((data, i) => {
                        return (
                            <div className='flex items-center gap-[10px] max-[400px]:border-b'>
                                <div className='max-[450px]:hidden'>
                                <DocSvg />
                                </div>
                                <div className='flex flex-col gap-[4px] w-full'>
                                    <div className='flex justify-between items-center w-full'>
                                        <h3 className='text-[#18181B] font-bold text-[18px] h-[25px] w-[80%] overflow-hidden'>{data?.requirements?.title}</h3>
                                        <Link to={`/program-requirements/${data?.user_id}/${data?.program_id}`}>
                                            <CardRightSvg />
                                        </Link>
                                    </div>
                                   
                                    <div className='flex justify-between w-full'>
                                        <p className='text-[#52525B] text-[14px] font-regular h-[20px] overflow-hidden max-w-[80%] ' dangerouslySetInnerHTML={{ __html: data?.requirements?.text }}></p>
                                        <button onClick={this.openTextModal.bind(this, data?.requirements?.text)} className='text-[13px] min-w-[80px] text-blue-400 text-right'>Daha çox</button>
                                    </div>
                                   
                                </div>
                            </div>
                        )
                    })
                }
            </div>
        </div>
    )
  }
}
const mapStateToProps = (state) =>({
    
});
const mapDispatchToProps = {changeStateValue}
export default connect(mapStateToProps, mapDispatchToProps)(DocumentInfo)
