import React, { Component } from 'react'
import CardLine from './CardLine';
import CardTitleSvg from '../svg/CardTitleSvg';
import DeleteSvg from '../svg/DeleteSvg';
import CardEditSvg from '../svg/CardEditSvg';
import { connect } from 'react-redux';
import { deleteData } from '../../actions/MainAction';
import { confirmAlert, mixinAlert } from '../../actions/SweetAlerts';
import CardCountrySvg from '../svg/CardCountrySvg';
import CardCheatingSvg from '../svg/CardCheatingSvg';
import CardMoreSvg from '../svg/CardMoreSvg';
import { Link } from 'react-router-dom';
import CardRankSvg from '../svg/CardRankSvg';
import CardUniSvg from '../svg/CardUniSvg';
import CheckSvg from '../svg/CheckSvg';
import ProfileCheckSvg from '../svg/ProfileCheckSvg';
import defImg from "../../images/def_user.png"
import CardDegreeSvg from '../public_components/svg/CardDegreeSvg';
import CardConnectedSvg from '../public_components/svg/CardConnectedSvg';
import ChangeUserSvg from '../public_components/svg/ChangeUserSvg';
import CloseSvg from '../svg/CloseSvg';
import CardPlusSvg from '../svg/CardPlusSvg';
import moment from 'moment';
export class Card1 extends Component {
    deleteItem=(deletePath)=>{
        confirmAlert("warning", "Are you sure?", "You won't be able to revert this!", "Yes, delete it")
        .then(alertResp=>{
            if(alertResp === "success"){
            this.props.deleteData(deletePath)
            .then(resp=>{
                if(resp == "success"){
                    this.props.afterCallFunction()
                    mixinAlert("success", "Data deleted successfully")
                }else{
                    mixinAlert("error", "Something went wrong")
                }
            })
            }
        })
    }
  render() {
    const {name="", image="", phone_number="", onClickEdit=()=>{}, 
        deletePath="", country="", type="", moreLink="", nameFull="", 
        username="", ranking_type="", ranking="", uni="", footer=true,
        rankStatus="",rankStatusBtn=false, onClickStatus=()=>{}, 
        program="", degree="", date="", relatedUser="", 
        changeStaffBtn=false, onChangeBtn=()=>{}, relatedUserManager="",
        activeStatus="",activeStatusPosition=false, onChangeStatus=()=>{}, 
        addSchBtn=false, onClickSch=()=>{}, userLink="", moreInfo=false, 
        onClickMore=()=>{}, activeStatusPosition1=false, activeStatus1, 
        onChangeStatus1=()=>{}, staffStatusBtn=false, staffStatusDate=""} = this.props;
    return (
        <div className='bg-white rounded-[12px] '>
            <div className='pt-[18px] pl-[9px] pr-[9px] '>
                <div className='mt-[-10px] flex justify-end mb-[4px]'>
                    {
                        changeStaffBtn?
                        <button onClick={()=>onChangeBtn()}>
                            <ChangeUserSvg />
                        </button>:null
                    }
                    {
                       activeStatusPosition1?
                        <button className='mr-[30px]' onClick={()=>onChangeStatus1()}>
                            {activeStatus1==0? <CloseSvg color={"red"} />:<CheckSvg /> }
                        </button>:null
                    }
                    {
                       activeStatusPosition?
                        <button onClick={()=>onChangeStatus()}>
                            {activeStatus==0? <CloseSvg color={"red"} />:<CheckSvg /> }
                        </button>:null
                    }
                   
                </div>
                <div className='flex justify-center'>
                    {
                        image && image !=""?
                        <img src={image} onError={(e)=>{e.target.src = defImg}} alt={name} className='w-full h-[120px] rounded-[12px]' />:
                        // <img src={defImg}  alt={name} className='w-full h-[120px] rounded-[12px]' />
                        null
                    }
                
                </div>
                <ul className='mt-[20px] flex flex-col gap-[10px]'>
                { nameFull!== ''?  
                <li className='flex items-center gap-[10px] border-b border-dashed pb-[10px] border-b-[rgba(0,0,0,0.5)]'>
                <div className={`bg-light-blue min-w-[22px] w-[22px] h-[22px] inline-flex items-center justify-center`}>
                    <CardTitleSvg />
                </div>
                <span  className={`text-[14px]  font-semibold  `}> {nameFull}</span>
            </li>
                :null }
                { name!== ''?  <CardLine svg={<CardTitleSvg />} userLink={userLink} title={name} />:null }
                { country!== ''?  <CardLine svg={<CardCountrySvg />}  title={country} />:null }
                { type!== ''?  <CardLine svg={<CardCheatingSvg />}  title={type} />:null }
                { username!== ''?  <CardLine svg={<CardCheatingSvg />}  title={username} />:null }
                { phone_number!== ''?  <CardLine svg={<CardCheatingSvg />}  title={phone_number} />:null }
                { ranking_type!== ''?  <CardLine svg={<CardCheatingSvg />}  title={ranking_type} />:null }
                { uni!== ''?  <CardLine svg={<CardUniSvg />}  title={uni} />:null }
                { ranking!== ''?  <CardLine svg={<CardRankSvg />}  title={ranking} />:null }
                
                { degree!== ''?  <CardLine svg={<CardDegreeSvg />}  title={degree} />:null }
               
                { relatedUser!== ''?  <CardLine svg={<CardConnectedSvg />}  title={relatedUser} />:null }
                { relatedUserManager!== ''?  <CardLine svg={<CardConnectedSvg />}  title={relatedUserManager} />:null }
                { program!== ''?  <CardLine svg={<CardRankSvg />}  title={program} />:null }
                { date!== ''?  <CardLine svg={<CardDegreeSvg />}  title={date} />:null }
                {rankStatusBtn? <button onClick={()=>onClickStatus()} className='mb-[20px] flex  items-center gap-[10px]'>Status: <ProfileCheckSvg color={rankStatus==1?"gray":"green"} /> </button>:null}
                { addSchBtn?<li className='flex items-center gap-[20px]'> 
                                <button onClick={()=>onClickSch("add")} className='text-[12px] bg-blue text-white p-[2px] rounded-[5px]'>Add Scholarship</button>  
                                <button onClick={()=>onClickSch("remove")} className='text-[12px] bg-blue text-white p-[2px] rounded-[5px]'>Remove Scholarship</button>  
                            </li>:null }
                {
                    staffStatusBtn?
                    <li className='flex gap-[6px] items-center'>
                        
                        <button  onClick={()=>onClickStatus()} className='inline-flex items-center gap-[3px]'>
                            <span>Status: </span>
                            <ProfileCheckSvg color={"green"} /> 
                        </button>
                        {
                            staffStatusDate!=""?
                            <span className='text-[14px]'>{moment(staffStatusDate).format("DD/MM/YYYY")}</span>:null
                        }
                        
                    </li>:null
                }
                </ul>
            </div>
            {
                footer?
                <>
                    <div className='h-[1px] w-full bg-gray-200 mt-[27px]'></div>
                    <div className='flex'>
                        {
                            deletePath !=""?
                            <button onClick={this.deleteItem.bind(this, deletePath)} className='w-[50%] h-[38px] border-r inline-flex items-center justify-center'>
                                <DeleteSvg />
                            </button>:null
                        }
                        
                        {
                            moreLink !=""?
                            <Link to={moreLink} className='w-[50%] h-[38px] border-r inline-flex items-center justify-center'>
                                <CardMoreSvg />
                            </Link>:null
                        }
                        {
                            moreInfo ?
                            <button onClick={()=>onClickMore()}  className='w-[50%] h-[38px] border-r inline-flex items-center justify-center'>
                                 <span className={`inline-block bg-blue p-[3px] rounded-[3px]`}>
                                    <CardPlusSvg color={"#fff" } />
                                </span>
                            </button>:null
                        }
                    
                        <button onClick={()=>onClickEdit()} className='w-[50%] h-[38px] inline-flex items-center justify-center'>
                            <CardEditSvg />
                        </button>
                    </div>
                </>:null
            }
            
        </div>
    )
  }
}
const mapStateToProps = (state) =>({});
const mapDispatchToProps = {deleteData}
export default connect(mapStateToProps, mapDispatchToProps)(Card1)
