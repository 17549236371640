import React, { Component } from 'react'
import SinglePageHeader from '../../components/public_components/SinglePageHeader'
import { getSingleData, getSubjectRankings } from '../../actions/MainAction';
import { connect } from 'react-redux';
import { withHooks } from '../../actions/withHooks';
import RankingTable from './sections/RankingTable';
import { Helmet } from 'react-helmet';
import Card5 from '../../components/public_components/cards/Card5';
import { getPathConvert, getStartFee, getTranslateLanguage } from '../../actions/getOtherFunctions';
import LoadMoreBtn from '../../components/public_components/LoadMoreBtn';
import Footer from './sections/Footer';
import FaqCard from '../../components/public_components/FaqCard';

export class SingleSubjectsPage extends Component {
  state={
    subjectData:'',
    page:1,
    subject_id:''
  }
  componentDidMount(){
    this.props.getSingleData(`public-subjects/${this.props.params?.id}?type=name`)
    .then(resp=>{
      // console.log(resp)
      if(resp == ""){this.props.navigate("/*")}
      if(resp?.redirect){
        this.props.navigate(`/subjects/${getPathConvert(resp?.title_en)}`)
      }
      this.setState({subjectData:resp, subject_id:resp?.id});
      this.props.getSubjectRankings(0.45,resp?.id);
    });
  
    window.scrollTo(0,0)
  }
  loadMore=(page)=>{
    this.setState({page})
    this.props.getSubjectRankings(page*0.45, this.state.subject_id);
  }
  render() {
    const {subjectData} = this.state;
    const {subjectRankings} = this.props;
    // console.log(subjectRankings)
    return (
      <div className='mt-[80px] bg-[#F5F5F5]'>
        {
          subjectData !=""?
          <Helmet>
            <title>{getTranslateLanguage(this.props.lang_id, "seo_title", subjectData)}</title>
            <meta property="og:locale" content="az_AZ" />
            <meta property="og:type" content="website" />
            <meta property="og:title" content={getTranslateLanguage(this.props.lang_id, "seo_title", subjectData)} />
            <meta property="og:description" content={getTranslateLanguage(this.props.lang_id, "seo_description", subjectData)} />
            <meta name="description" content={getTranslateLanguage(this.props.lang_id, "seo_description", subjectData)}/>
            <meta name="keywords" content={getTranslateLanguage(this.props.lang_id, "seo_keywords", subjectData)}/>
            <meta property="og:url" content={`https://celtgo.com${window.location.pathname}`} />
            <meta property="og:site_name" content="CELT Colleges" />
            <meta property="article:publisher" content="https://www.facebook.com/celtscollege" />
            <link rel="canonical" href={`https://celtgo.com${window.location.pathname}`} />
            <meta property="article:modified_time" content="2024-06-06T08:56:25+00:00" />
            <meta property="og:image" content={`${subjectData?.image_full_url}`} />
            <meta property="og:image:width" content="900" />
            <meta property="og:image:height" content="600" />
            <meta property="og:image:type" content="image/jpeg" />
            <meta name="twitter:card" content="summary_large_image" />
            <meta name="twitter:title" content={getTranslateLanguage(this.props.lang_id, "seo_title", subjectData)} />
            <meta name="twitter:description" content={getTranslateLanguage(this.props.lang_id, "seo_description", subjectData)} />
            <meta name="twitter:site" content="@celtcolleges" />
            <meta name="twitter:label1" content="Est. reading time" />
            <meta name="twitter:data1" content="7 minutes" />
          </Helmet>:null
        }
        <SinglePageHeader name={getTranslateLanguage(this.props.lang_id, "title", subjectData)} image={subjectData?.image_full_url} />
        <section className='mb-[50px]'>
          <div className='max-w-[1200px] ml-auto mr-auto max-[1240px]:ml-[20px] max-[1240px]:mr-[20px] mt-[40px]'>
            <h3 className='text-neutral-800 text-[35px] text-center mb-[30px] font-semibold max-[450px]:text-[30px]'>{this.props.language?.most_popular_uni}</h3>
            <div className='grid-full-380'>
              {
                subjectRankings?.map((data, i)=>{
                  return(
                    <Card5 key={i} 
                      image={data?.university?.image_full_url}
                      name={getTranslateLanguage(this.props.lang_id, "name", data?.university)}
                      country={getTranslateLanguage(this.props.lang_id, "name", data?.university?.country)}
                      city={getTranslateLanguage(this.props.lang_id, "name", data?.university?.city)}
                      data={data} 
                      path={`/${this.props.langPrefix}university/${getPathConvert(data?.university?.name_en)}`} 
                      ranking={data?.ranking}
                      view={true}
                      footer={false}
                      rankTxt={this.props.language?.at_the_subject}
                      start_fee={getStartFee(data?.university?.phd_start, data?.university?.master_start, data?.university?.pre_master_star, data?.university?.bachelour_start)}
                    />
                  )
                })
              }
            </div>
            {
              subjectRankings?.length >=9?
              <LoadMoreBtn defCount={9} onLoadData={this.loadMore} count={subjectRankings?.length} />:null
            }
            {
                subjectData?.faqs?.length != 0?
                <div className='flex flex-col gap-[10px] mt-[20px]'>
                    <h2 className='text-center text-[44px] font-semibold leading-[70px] max-[450px]:text-[30px] max-[450px]:leading-[35px] max-[450px]:text-center'>{this.props.language?.faq_title}</h2>
                    {
                        subjectData?.faqs?.map((data, i)=>{
                            return (
                                <FaqCard key={i} data={data} />
                            )
                        })
                    }
                </div>:null
            }
            {/* <RankingTable
              options={subjectRankings} 
              column1Title={"Sıralama"}
              column2Title={"Universitet"}
              column3Title={"Ölkə"}
              column1Property={"ranking"}
              column2Property={"university"}
              column3Property={"country"}
              rank_property={"subject_rank_id"}
              count={3}
            /> */}
            </div>
        </section>
        <Footer />
      </div>
    )
  }
}
const mapStateToProps = (state) =>({
  subjectRankings: state.Data.subjectRankings,
  lang_id: state.Data.lang_id,
  language: state.Data.language,
  langPrefix: state.Data.langPrefix,
});
const mapDispatchToProps = {getSingleData, getSubjectRankings}
export default connect(mapStateToProps, mapDispatchToProps)(withHooks(SingleSubjectsPage))
