import React, { Component } from 'react'
import TimeSvg from './svg/TimeSvg'
import logo from "../../images/logo/celtlogo-03.png";
import defUser from "../../images/def_user.png"
import { getPublicBookingInfo } from '../../actions/PublicActions';
import {connect} from "react-redux"
import moment from 'moment';
import ScheduleSvg from './svg/ScheduleSvg';
export class BookingInfoComponent extends Component {
  state={
    info:''
  }
  componentDidMount(){
    this.props.getPublicBookingInfo()
    .then(resp=>{
      this.setState({info:resp})
    })
  }
  render() {
    const {info} = this.state;
    const {bookingUserInfo} = this.props;
    return (
        <div className='flex flex-col border-r border-r-[1px]  h-[70vh] overflow-auto max-[900px]:border-r-[0px] max-[900px]:w-full max-[900px]:border-b max-[900px]:h-auto'>
        {/* <div className='w-full min-h-[160px] border-b border-b-[1px] flex justify-center items-center'>
          <img className='w-[200px]' src={logo} />
        </div> */}
        <div className='p-[20px] '>
          <div className='flex flex-col justify-center items-center'>
            <img className='w-[140px] h-[140px] rounded-full' src={info?.image_full_url} />
            <span className='inline-block mt-[8px] text-[#1a1a1a9c] font-[600] '>{this.props.language?.boss}</span>
            <h3 className='text-[24px] text-[#1a1a1a] font-[500] max-[400px]:text-[18px]'>{this.props.language?.education_profession}</h3>
          </div>
          <div className='flex items-center justify-center gap-[5px] text-[#1a1a1a9c]'>
            <TimeSvg />
            <span className='inline-block mt-[5px] font-[500]'> {info?.time} {this.props.language?.minute}</span>
          </div>
          {
            bookingUserInfo?.time !=""?
            <div className='flex items-center justify-center gap-[5px] text-[#1a1a1a9c] mt-[10px]'>
            <ScheduleSvg />
            <span className='inline-block mt-[5px] font-[500] max-[400px]:inline-flex max-[400px]:flex-col'><span> {bookingUserInfo?.time}, {moment(bookingUserInfo?.day).format("dddd")},</span> <span>{moment(bookingUserInfo?.day).format("MMM DD, YYYY")}</span></span>
          </div>:null
          }
          <div className='flex flex-col gap-[10px]'>
            <p className='mt-[10px] text-[#1a1a1a] text-[16px] text-center font-[300]'>{this.props.language?.booking_title_2}</p>
            <div className='manual-text text-[#1a1a1a] text-[16px] font-[300] mt-[10px] text-center' dangerouslySetInnerHTML={{ __html:this.props.language?.booking_text_2 }}></div>
          </div>
        </div>
      </div>
    )
  }
}
const mapStateToProps = (state) =>({
  bookingUserInfo: state.Data.bookingUserInfo,
  language: state.Data.language,
});
const mapDispatchToProps = {getPublicBookingInfo}
export default connect(mapStateToProps, mapDispatchToProps)(BookingInfoComponent)
