import React, { Component } from 'react'
import Header from '../../components/header/Header'
import Search from '../../components/Search'
import Buttons from '../../components/Buttons'
import { connect } from 'react-redux'
import { changeStateValue } from '../../redux/MainReducer';
import { getCountries, insertNewData } from '../../actions/MainAction';
import Card1 from '../../components/cards/Card1'
import LoadMoreBtn from '../../components/LoadMoreBtn'
import { confirmAlert, mixinAlert } from '../../actions/SweetAlerts'
import { withHooks } from '../../actions/withHooks'
export class SeoCountreisPage extends Component {
  state={
    page:1,
    search:''
  }
  openModal=(data)=>{
    this.props.changeStateValue({
      name:'adminModal',
      value:{
        position: true,
        id: data?.id?data?.id:'',
        seo_title: data?.seo_title?data?.seo_title:'',
        seo_title_en: data?.seo_title_en?data?.seo_title_en:'',
        seo_title_tr: data?.seo_title_tr?data?.seo_title_tr:'',
        seo_title_ru: data?.seo_title_ru?data?.seo_title_ru:'',

        seo_description: data?.seo_description?data?.seo_description:'',
        seo_description_en: data?.seo_description_en?data?.seo_description_en:'',
        seo_description_tr: data?.seo_description_tr?data?.seo_description_tr:'',
        seo_description_ru: data?.seo_description_ru?data?.seo_description_ru:'',

        seo_keywords: data?.seo_keywords?data?.seo_keywords:'',
        seo_keywords_en: data?.seo_keywords_en?data?.seo_keywords_en:'',
        seo_keywords_tr: data?.seo_keywords_tr?data?.seo_keywords_tr:'',
        seo_keywords_ru: data?.seo_keywords_ru?data?.seo_keywords_ru:'',
        

        afterCallFunction:this.props.getCountries,
        afterCallParams:[this.state.page, this.state.search],
        requireds: ["seo_description", "seo_keywords"],
        url:'country',
        modalTitle:`${data?.id?'Edit':'Add'} Country`
      }
    })
  }

  componentDidMount(){
    this.props.getCountries(1, '')
  }

  onSearchData=(search)=>{
    this.setState({search})
    this.props.getCountries(this.state.page, search)
  }
  loadMore=(page)=>{
    this.setState({page})
    this.props.getCountries(page, this.state.search)
  }

  afterCallFunction=()=>{
    this.props.getCountries(this.state.page, this.state.search)
  }
  onClickSch=(id, type)=>{
    confirmAlert("warning", "Are you sure?", "You cannot change it", `Yes, ${type} it`)
    .then(respAlert=>{
      this.props.insertNewData(`sch-by-country`, {country_id:id, type:type, scholarship_id: this.props.params.id})
      .then(resp=>{
        if(resp == "success"){
          mixinAlert("success", "Data saved successfully");
        }else{
          mixinAlert("error", "Something went wrong");
        }
      })
    })
  }
  onChangeStatus=(id, status)=>{
    confirmAlert("warning", "Are you sure?", `Do you want to ${status==1?"Active":"Deactice"} university`, "yes")
    .then(resp=>{
      if(resp == "success"){
        this.props.insertNewData(`changecountry-status/${id}`, {status:status})
        .then(resp=>{
          if(resp=="success"){
            mixinAlert("success", "Data saved successfully");
            this.afterCallFunction()
          }else{
            mixinAlert("error", "Something went wrong");
          }
        })
      }
    })
  }
  render() {
    const {countries, moreLink="", addSchBtn=false, header="", user} = this.props;
  
    return (
        <div className='w-full  pl-[27px] pr-[27px]'>
            <Header title={`${header !=""?header+" - ":""}Countries`} />
            {/* <div className='flex justify-end mt-[30px]'>
                <Buttons onClick={this.openModal.bind(this, '')} options={[{title:"Add a country", value:0}]} selected={0} />
            </div> */}
            <div className='flex justify-center mt-[20px]'>
                <Search onSearchData={this.onSearchData.bind(this)} largWidth={true} />
            </div>

            <div className='grid-full-250 mt-[30px]'>
              {
                countries?.map((data, i)=>{
                  return(
                    <Card1 
                      activeStatusPosition={user?.role == "admin"}
                      activeStatus={0}
                      activeStatusPosition1={user?.role == "admin"}
                      activeStatus1={1}
                      key={i}
                      image={data?.image_full_url}
                      name={data?.name}
                      onClickEdit={this.openModal.bind(this, data)}
                      deletePath={user?.role == "admin"?`country/${data?.id}`:""}
                      afterCallFunction={this.afterCallFunction.bind(this)}
                    //   moreLink={moreLink==""? `/admin-country/${data?.id}`:moreLink+`/${data?.id}`}
                      addSchBtn={addSchBtn}
                      onClickSch={this.onClickSch.bind(this, data?.id)}
                      onChangeStatus={this.onChangeStatus.bind(this, data?.id, 1)}
                      onChangeStatus1={this.onChangeStatus.bind(this, data?.id, 0)}
                    />
                  )
                })
              }
            </div>
            {
                countries?.length >=20?
                <LoadMoreBtn onLoadData={this.loadMore} count={countries?.length} />:null
            }
        </div>
    )
  }
}
const mapStateToProps = (state) =>({
  countries: state.Data.countries,
  user: state.Data.user,
});
const mapDispatchToProps = {changeStateValue, getCountries, insertNewData}
export default connect(mapStateToProps, mapDispatchToProps)(withHooks(SeoCountreisPage))


