import React, { Component } from 'react'
import { connect } from 'react-redux';
import { withHooks } from '../../actions/withHooks';
import { getSingleData } from '../../actions/MainAction';
import NewsLetterSection from './sections/NewsLetterSection';
import Footer from './sections/Footer';
import CardReadSvg from '../../components/public_components/svg/CardReadSvg';
import moment from 'moment';
import { Helmet } from 'react-helmet';
import { getTranslateLanguage } from '../../actions/getOtherFunctions';
export class SingleBlogPage extends Component {
    state={
        blogData:''
    }
    componentDidMount(){
        this.props.getSingleData(`public-blog/${this.props.params.id}?type=name`)
        .then(resp=>{
            // console.log(resp)
            if(resp == ""){this.props.navigate("/*")}
            this.setState({blogData:resp})
        });

        window.scrollTo(0,0)
    }
  render() {
    const {blogData} = this.state;
    // console.log(blogData)
    return (

        <div className='mt-[80px] bg-[#F6F8FB]'>
             {
                 blogData !=""?
                 <Helmet>
                   <title>{getTranslateLanguage(this.props.lang_id, "seo_title", blogData)}</title>
                   <meta property="og:locale" content="az_AZ" />
                   <meta property="og:type" content="website" />
                   <meta property="og:title" content={getTranslateLanguage(this.props.lang_id, "seo_title", blogData)} />
                   <meta property="og:description" content={getTranslateLanguage(this.props.lang_id, "seo_description", blogData)} />
                   <meta name="description" content={getTranslateLanguage(this.props.lang_id, "seo_description", blogData)}/>
                   <meta name="keywords" content={getTranslateLanguage(this.props.lang_id, "seo_keywords", blogData)}/>
                   <meta property="og:url" content={`https://celtgo.com${window.location.pathname}`} />
                   <meta property="og:site_name" content="CELT Colleges" />
                   <meta property="article:publisher" content="https://www.facebook.com/celtscollege" />
                   <meta property="article:modified_time" content="2024-06-06T08:56:25+00:00" />
                   <meta property="og:image" content={`${blogData?.image_full_url}`} />
                   <meta property="og:image:width" content="900" />
                   <meta property="og:image:height" content="600" />
                   <meta property="og:image:type" content="image/jpeg" />
                   <meta name="twitter:card" content="summary_large_image" />
                   <meta name="twitter:title" content={getTranslateLanguage(this.props.lang_id, "seo_title", blogData)} />
                   <meta name="twitter:description" content={getTranslateLanguage(this.props.lang_id, "seo_description", blogData)} />
                   <meta name="twitter:site" content="@celtcolleges" />
                   <meta name="twitter:label1" content="Est. reading time" />
                   <meta name="twitter:data1" content="7 minutes" />
                   <link rel="canonical" href={`https://celtgo.com${window.location.pathname}`} />
                 </Helmet>:null
            }
            <div className='pb-[30px] bg-white'>
                <div className='pt-[60px]'>
                    <div className='max-w-[1200px] ml-auto mr-auto max-[1240px]:ml-[20px] max-[1240px]:mr-[20px] pt-[30px]'>
                        <div className='flex gap-[80px] items-center justify-between max-[900px]:flex-col-reverse max-[900px]:gap-[30px]'>
                            <h1 className='text-[38px] font-semibold'>{blogData?.title}</h1>
                            <img className='w-[50%] rounded-[10px] max-[900px]:w-full' src={blogData?.image_full_url} />
                        </div>
                        <div className='flex flex-col gap-[10px] mt-[10px]'>
                            {/* <div className='flex items-center gap-[7px]'>
                                <CardReadSvg />
                                <span className='text-[12px]'>Read count {blogData?.read_count}</span>
                            </div> */}
                            <span className='text-gray-500 text-[14px]'>{moment(blogData?.created_at).format("DD MMM YYYY")}</span>
                        </div>
                        <p className='mt-[50px]' dangerouslySetInnerHTML={{ __html:blogData?.review }}></p>
                    </div>
                </div>
            </div>
            {/* <NewsLetterSection /> */}
            <Footer />
        </div>
      
    )
  }
}
const mapStateToProps = (state) =>({

});
const mapDispatchToProps = {getSingleData}
export default connect(mapStateToProps, mapDispatchToProps)(withHooks(SingleBlogPage))
