import React, { Component } from 'react'
import img from "../../images/country_img.png"
import Card5 from '../../components/public_components/cards/Card5'
import { connect } from 'react-redux';
import { withHooks } from '../../actions/withHooks';
import { getSingleData, getUniversityRankings } from '../../actions/MainAction';
import { getPublicUniversities } from '../../actions/PublicActions';
import SinglePageHeader from '../../components/public_components/SinglePageHeader';
import Footer from './sections/Footer';
import LoadMoreBtn from '../../components/public_components/LoadMoreBtn';
import { getPathConvert, getStartFee, getTranslateLanguage } from '../../actions/getOtherFunctions';
import RankingTable from './sections/RankingTable';
import { Helmet } from 'react-helmet';
import FaqCard from '../../components/public_components/FaqCard';
export class SingleCountryPage extends Component {
  state={
    countryData:'',
    page:1,
  }
  componentDidMount(){
    this.props.getSingleData(`public-country/${this.props.params.id}?type=name`)
    .then(resp=>{
      // console.log(resp)
      if(resp == ""){this.props.navigate("/*")}
      if(resp?.redirect){
        this.props.navigate(`/country/${getPathConvert(resp?.name_en)}`)
      }
      this.props.getPublicUniversities(0.45, resp?.id)
      this.props.getUniversityRankings(resp?.id)
      this.setState({
        countryData: resp
      })
    })
    window.scrollTo(0,0)
  }
  loadMore=(page)=>{
    this.props.getPublicUniversities(page*0.45, this.state.countryData?.id)
  }
  render() {
    const {countryData} = this.state;
    const {publicUniversities, universityRankings} = this.props;
    // console.log(countryData)
    return (
      <div className='mt-[80px] bg-[#F5F5F5]'>
        {
          countryData !=""?
          <Helmet>
            <title>{`${getTranslateLanguage(this.props.lang_id, "seo_title", countryData)}`}</title>
            <meta property="og:locale" content="az_AZ" />
            <meta property="og:type" content="website" />
            <meta property="og:title" content={`${ getTranslateLanguage(this.props.lang_id, "seo_title", countryData)}`} />
            <meta property="og:description" content={getTranslateLanguage(this.props.lang_id, "seo_description", countryData)} />
            <meta name="description" content={getTranslateLanguage(this.props.lang_id, "seo_description", countryData)}/>
            <meta name="keywords" content={getTranslateLanguage(this.props.lang_id, "seo_keywords", countryData)}/>
            <meta property="og:url" content={`https://celtgo.com${window.location.pathname}`} />
            <link rel="canonical" href={`https://celtgo.com${window.location.pathname}`} />
            <meta property="og:site_name" content="CELT Colleges" />
            <meta property="article:publisher" content="https://www.facebook.com/celtscollege" />
            <meta property="article:modified_time" content="2024-06-06T08:56:25+00:00" />
            <meta property="og:image" content={`${countryData?.image_full_url}`} />
            <meta property="og:image:width" content="900" />
            <meta property="og:image:height" content="600" />
            <meta property="og:image:type" content="image/jpeg" />
            <meta name="twitter:card" content="summary_large_image" />
            <meta name="twitter:title" content={`${getTranslateLanguage(this.props.lang_id, "seo_title", countryData)}`} />
            <meta name="twitter:description" content={getTranslateLanguage(this.props.lang_id, "seo_description", countryData)} />
            <meta name="twitter:site" content="@celtcolleges" />
            <meta name="twitter:label1" content="Est. reading time" />
            <meta name="twitter:data1" content="7 minutes" />
          </Helmet>:null
        }
          
        <SinglePageHeader Header name={getTranslateLanguage(this.props.lang_id, "name", countryData)} image={countryData?.image_full_url} />
        <section className='mb-[50px]'>
          <div className='max-w-[1200px] ml-auto mr-auto max-[1240px]:ml-[20px] max-[1240px]:mr-[20px] mt-[50px]'>
            <div className='grid-full-380'>
              {
                publicUniversities?.map((data, i)=>{
                  return(
                    <Card5 key={i} 
                      image={data?.image_full_url}
                      name={getTranslateLanguage(this.props.lang_id, "name", data)}
                      country={getTranslateLanguage(this.props.lang_id, "name", data?.country)}
                      city={getTranslateLanguage(this.props.lang_id, "name", data?.city)}
                      data={data} 
                      path={`/${this.props.langPrefix}university/${getPathConvert(data?.name_en)}`} 
                      ranking={data?.local_ranking >600? '': data?.local_ranking}
                      view={true}
                      footer={false}
                      start_fee={getStartFee(data?.phd_start, data?.master_start, data?.pre_master_star, data?.bachelour_start)}
                    />
                  )
                })
              }
            </div>
            {
                publicUniversities?.length >=9?
                <LoadMoreBtn defCount={9} onLoadData={this.loadMore} count={publicUniversities?.length} />:null
            }
            {
                countryData?.faqs?.length != 0?
                <div className='flex flex-col gap-[10px] mt-[20px]'>
                    <h2 className='text-center text-[44px] font-semibold leading-[70px] max-[450px]:text-[30px] max-[450px]:leading-[35px] max-[450px]:text-center'>{this.props.language?.faq_title}</h2>
                    {
                        countryData?.faqs?.map((data, i)=>{
                            return (
                                <FaqCard key={i} data={data} />
                            )
                        })
                    }
                </div>:null
            }
          </div>
        </section>
        <Footer />
      </div>
    )
  }
}
const mapStateToProps = (state) =>({
  publicUniversities: state.Data.publicUniversities,
  universityRankings: state.Data.universityRankings,
  lang_id: state.Data.lang_id,
  langPrefix: state.Data.langPrefix,
  language: state.Data.language,
});
const mapDispatchToProps = {getSingleData, getPublicUniversities, getUniversityRankings}
export default connect(mapStateToProps, mapDispatchToProps)(withHooks(SingleCountryPage))
