import React, { Component } from 'react'

export class MinusSvg extends Component {
  render() {
    const {color="#000"} = this.props;
    return (
        <svg
        xmlns="http://www.w3.org/2000/svg"
        width={15}
        height={15}
        fill="none"
        viewBox="0 0 24 24"
      >
        <path
          fill={color}
          d="M2 12a1 1 0 0 1 1-1h18a1 1 0 1 1 0 2H3a1 1 0 0 1-1-1Z"
        />
      </svg>
    )
  }
}

export default MinusSvg
