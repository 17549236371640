import React, { Component } from 'react'
import img from "../images/notFound.png"
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
export class NotFoundPage extends Component {
  render() {
    return (
      <div>
         <div className='w-full h-screen overflow-auto pb-[50px] flex items-center gap-[20px] flex-col'>
            <img src={img} className='w-[700px] max-[1100px]:mt-[100px]' alt='Not Found' />
            <p className='text-center'>{this.props.language?.not_page}</p>
            <Link className='bg-blue p-[9px] rounded-full text-white ' to={`/${this.props.langPrefix}`}>{this.props.language?.return_main_page}</Link>
        </div>
      </div>
    )
  }
}
const mapStateToProps = (state) =>({
  langPrefix: state.Data.langPrefix,
  language: state.Data.language,
})
export default connect(mapStateToProps)(NotFoundPage)
