import React, { Component } from 'react'
import SectionHeader from '../../../components/public_components/SectionHeader'
import { connect } from 'react-redux';
import { getTop300Rankings } from '../../../actions/MainAction';
import RankingTable from './RankingTable';
import Card5 from '../../../components/public_components/cards/Card5';
import { getPathConvert, getStartFee, getTranslateLanguage } from '../../../actions/getOtherFunctions';
import LoadMoreBtn from '../../../components/public_components/LoadMoreBtn';

export class Top300UniversitiesRankingSections extends Component {
    componentDidMount(){
        this.props.getTop300Rankings(1)
    }
    loadMore=(page)=>{
        // console.log(page)
        this.props.getTop300Rankings(page)
    }
  render() {
    const {top300Rankings, headerNone=false} = this.props;
    // console.log(top300Rankings)
    return (
        <section className={`${!headerNone? "pt-[100px] bg-[#F5F5F5]":""} `}>
            <div className='mt-[60px]'>
                {
                     !headerNone?
                 <SectionHeader text1={this.props.language?.section_title8} text2="" />    :null
                }
                
                <div className='max-w-[1200px] ml-auto mr-auto mt-[40px] pb-[80px] max-[1240px]:ml-[20px] max-[1240px]:mr-[20px]'>
                <div className='grid-full-380'>
                    {
                    top300Rankings?.map((data, i)=>{
                        return(
                            <Card5 key={i} 
                                image={data?.image_full_url}
                                name={getTranslateLanguage(this.props.lang_id, "name", data)}
                                country={getTranslateLanguage(this.props.lang_id, "name", data?.country)}
                                city={getTranslateLanguage(this.props.lang_id, "name", data?.city)}
                                data={data} 
                                path={`/${this.props.langPrefix}university/${getPathConvert(data?.name_en)}`} 
                                ranking={data?.ranking}
                                view={true}
                                footer={false}
                                rankTxt={this.props.language?.at_world}
                                start_fee={getStartFee(data?.phd_start, data?.master_start, data?.pre_master_star, data?.bachelour_start)}
                            />
                            )
                        })
                    }
                </div>
                {
                    top300Rankings?.length >=20?
                    <LoadMoreBtn onLoadData={this.loadMore} count={top300Rankings?.length} />:null
                }
                    {/* <RankingTable
                        options={top300Rankings} 
                        column1Title={"Sıralama"}
                        column2Title={"Universitet"}
                        column3Title={"Ölkə"}
                        column1Property={"ranking"}
                        column2Property={"university"}
                        column3Property={"country"}
                        rank_property={"top_300_rank_id"}
                        count={3}
                        /> */}
                </div>
                
            </div>
        
        </section>
    )
  }
}
const mapStateToProps = (state) =>({
    top300Rankings: state.Data.top300Rankings,
    language: state.Data.language,
    lang_id: state.Data.lang_id,
    langPrefix: state.Data.langPrefix,
});
const mapDispatchToProps= {getTop300Rankings}
export default connect(mapStateToProps, mapDispatchToProps)(Top300UniversitiesRankingSections)
