import React, { Component } from 'react'
import { withHooks } from '../../actions/withHooks'
import { connect } from 'react-redux';

export class RedirectSch extends Component {
    componentDidMount(){
        try{
            let url = this.props.params.id;
             window.location.href = `/${this.props.langPrefix}scholarships/${url}`
        }catch(err){}
    }
  render() {
    return (
      <div>
        
      </div>
    )
  }
}
const mapStateToProps = (state) =>({
  langPrefix: state.Data.langPrefix
})
export default connect(mapStateToProps)(withHooks(RedirectSch))
