import React, { Component } from 'react'

export class TNDashCard extends Component {
  render() {
    const {title, price, studentCount, note} = this.props;
    return (
        <div className='shadow bg-white rounded-[20px] p-[20px]'>
            <h3 className='text-[#061147] font-[500] text-[18px] text-center'>{title}</h3>
            <ul className='mt-[20px] border p-[10px] rounded-[5px]'>
              <li className='flex mb-[7px] pb-[7px] items-center gap-[6px] border-b border-dashed'><span className='txt-blue text-[16px] font-[500] '>Toplam tələbə sayı:</span> <span className='txt-blue text-[16px] font-[300]'>{studentCount}</span></li>
              <li className='flex items-center gap-[6px]'><span className='txt-blue text-[16px] font-[500]'>Yekun məbləğ:</span> <span className='txt-blue text-[16px] font-[300]'>{studentCount*price} AZN</span></li>
            </ul>
            <p className='text-[11px] text-gray-500 mt-[10px] font-[600]'>{note}</p>
        </div>
    )
  }
}

export default TNDashCard
