let blobs = [];
let stream;
let mediaRecorder;

export const  startRecording = async()  =>
{
    blobs = []
 
    navigator.getUserMedia = navigator.getUserMedia || navigator.webkitGetUserMedia || navigator.mozGetUserMedia || navigator.msGetUserMedia;
    stream = await navigator.mediaDevices.getUserMedia({ audio: true, video: false });
    mediaRecorder = new MediaRecorder(stream);
    mediaRecorder.ondataavailable = (event) => {
        if (event.data)
            blobs.push(event.data);   
    }
    mediaRecorder.onstop = doPreview;
    mediaRecorder.start(10);
}
export const endRecording =() =>
{
    mediaRecorder.stop();
    stream.getTracks().forEach(track => track.stop());
    const newBlob =  new Blob(blobs, { 'type' : 'audio/mp4'})
    const audio_url = (window.URL || window.webkitURL).createObjectURL(newBlob);
    if(audio_url.length !== 0){
        const audioName =new File([newBlob], '.mp4');
        return{
            audio_url,
            audioName
        }
    }
    
}
function doPreview()
{
    if (!blobs.length)
        return;
}