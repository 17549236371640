import React, { Component } from 'react'

export class CardDegree2Svg extends Component {
  render() {
    return (
        <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlSpace="preserve"
        id="Icons"
        width={16}
        height={16}
        viewBox="0 0 32 32"
      >
        <style>{".st0{fill:#fff}"}</style>
        <path d="M30.4 6.5c-.2-1.1-.8-2.3-1.7-3.2-.9-.9-2-1.5-3.2-1.7-1.2-.2-2.4.2-3.2 1-2.8 3.3-5.8 6.5-8.9 9.6-.2 0-.5 0-.7.1-.4.2-.5.6-.4 1-3.1 3.1-6.3 6.1-9.7 9-.8.8-1.1 1.9-1 3.2.2 1.1.8 2.3 1.7 3.2.9.9 2 1.5 3.2 1.7h.6c1 0 1.9-.4 2.6-1.1 2.4-2.7 4.8-5.3 7.3-7.9V25c0 .6.4 1 1 1s1-.4 1-1v-4.9c.7 1 1 2.4 1 4.4V29c0 .6.4 1 1 1s1-.4 1-1v-4.5c0-1.9-.2-4.4-1.9-6.2 3-3 6.1-5.9 9.3-8.6.8-.8 1.2-2 1-3.2zm-4.9 3.9c-2.4 2.2-4.8 4.4-7.1 6.7-.1 0-.2-.1-.3-.1-1.1-.9-2.5-2.2-3.4-3.3 2.3-2.4 4.6-4.8 6.8-7.2.3.9.8 1.8 1.5 2.5.8.6 1.6 1.2 2.5 1.4zM8.2 28c-.5.5-1.1.4-1.5.4-.7-.1-1.5-.5-2.1-1.1-.6-.6-1-1.3-1.1-2.1-.1-.4-.1-1 .3-1.4 3.2-2.8 6.4-5.7 9.4-8.7 1.1 1.3 2.5 2.6 3.6 3.5-2.9 3.1-5.8 6.2-8.6 9.4z" />
      </svg>
    )
  }
}

export default CardDegree2Svg
