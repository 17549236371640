import React, { Component } from 'react'
import { connect } from 'react-redux';
import {changeStateValue} from "../../../redux/MainReducer";
import EyeSvg from '../../svg/EyeSvg';
import EyeCloseSvg from '../../svg/EyeCloseSvg';
import CheckRoundedSvg from '../../svg/CheckRoundedSvg';
import GreenCheckSvg from '../../svg/GreenCheckSvg';
import InpCheckSvg2 from '../../svg/InpCheckSvg2';
export class Input extends Component {
  state={
    inpType:'password'
  }
    changeInpValue(onChangeParent, minValue, maxValue, step, e){
      
      if(minValue == "" && maxValue == ""){
        this.props.changeStateValue({
            name: e.target.name, 
            value: e.target.value
        })
        onChangeParent(e.target.name, e.target.value);
      }else{
        if(!e.target.name?.includes("ielts")){
          if(e.target.value <= +maxValue){
            this.props.changeStateValue({
              name: e.target.name, 
              value: e.target.value
            })
            onChangeParent(e.target.name, e.target.value);
          }
        }else if(e.target.name?.includes("ielts")){
          if(
              +e.target.value === 3 ||
              +e.target.value === 3.5 ||
              +e.target.value === 4 || 
              +e.target.value ===4.5 || 
              +e.target.value ===5 ||
              +e.target.value ===5.5 ||
              +e.target.value ===6 ||
              +e.target.value ===6.5 ||
              +e.target.value ===7 ||
              +e.target.value ===7.5 ||
              +e.target.value ===8 ||
              +e.target.value ===8.5 ||
              +e.target.value ===9 ||
              e.target.value ===''
            ){
              this.props.changeStateValue({
                name: e.target.name, 
                value: e.target.value
              })
              onChangeParent(e.target.name, e.target.value);
            }
        }
        
      }
        
    }
  render() {
    const {type = "text", name="", redborder=true, value="", title="", required=false, onChangeParent=()=>{}, minValue="", maxValue="", step=1, bg="bg-gray-100 ", bigInp=false, svg, requiredSvg=false, placeholder="", reqText="Bu xana vacibdir"} = this.props;
    const {inpType} = this.state;
    return (
      <div className='flex flex-col items-start'>
        {title !== "" ? <label htmlFor={name} className={bigInp?"text-[#000] text-[12px] font-[500] inline-flex items-center gap-[5px]":'text-[14px] txt-light-black'}>{svg} {title}</label>:null }
        {
          type !=="password"?
          <div className='relative w-full'>
            <input autoComplete={'off'} onChange={this.changeInpValue.bind(this, onChangeParent, minValue, maxValue, step)} placeholder={placeholder !=""?placeholder:title!=""?title:''} id={name} name={name} value={value} type={type} className={`${bg} w-full ${bigInp?`h-[49px] mt-[6px] text-[12px] text-[#868686] pr-[40px]  ${redborder?"border border-[1px] border-[var(--red)]":""}`:"h-[35px]  text-[15px]"}  rounded-[6px] p-[5px] pl-[12px] outline-none`} />
            {requiredSvg?
            <div className='absolute right-[14px] top-[21px]'>
              {
                value ===""?
                 <InpCheckSvg2 />:
                 <GreenCheckSvg />
              }
             
            </div>:null
          }
          </div>
          
          :<div className={`${bg} w-full ${bigInp?`h-[49px] mt-[6px] text-[12px] text-[#868686] pr-[40px] ${redborder?"border border-[1px] border-[var(--red)]":""}`:"h-[35px]  text-[15px]"} rounded-[6px] flex items-center gap-[10px] pr-[7px]`}>
            <input autoComplete={'off'} onChange={this.changeInpValue.bind(this, onChangeParent, minValue, maxValue, step)} placeholder={title} id={name} name={name} value={value} type={inpType} className={`${bg}  w-full rounded-[6px] p-[5px] pl-[12px] outline-none ${bigInp?"h-[49px] text-[12px] text-[#868686] pr-[40px]":"h-[35px]  text-[15px]"}`} />
            <button onClick={()=>this.setState({inpType: inpType==="password"? "text":"password"})}>
             { inpType==="password"?<EyeSvg />:<EyeCloseSvg /> } 
            </button>
          </div>
        }
        {
            required && value===""?
            <span className='text-[11px] text-red-700'>{reqText}</span>:null
        }
      </div>
    )
  }
}
const mapStateToProps = (state) =>({
});
const mapDispatchToProps = {changeStateValue}
export default connect(mapStateToProps, mapDispatchToProps)(Input)
