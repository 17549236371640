import React, { Component } from 'react'
// import logo from "../../images/logo.png"
// import logo from "../../images/logo/celtlogo-03.png"
import logo from "../../images/logo/celtLogo6.png"
import { Link } from 'react-router-dom'
import {  resetStateValue } from '../../redux/MainReducer'
import { connect } from 'react-redux'
import MenuSvg from './svg/MenuSvg'
import ChangeLangBtn from './ChangeLangBtn'
export class Header extends Component {
    resetRandevu=()=>{
        this.props.resetStateValue({name:"bookingUserInfo"})
        this.props.resetStateValue({name:"bookingLastModal"})
        this.closeNav()
    }

    // componentDidMount(){
    //     window.addEventListener("click", (e)=>{
    //         if(window.innerWidth <= 950){
    //             const nav = document.querySelector("#publicNavBtn");
    //             if(nav){
    //                 nav.checked = !nav.checked
    //                 console.log(nav.checked)
    //             }
    //         }   
    //     })
    // }
    closeNav=()=>{
        window.scrollTo(0,0)
        if(window.innerWidth <= 950){
            const nav = document.querySelector("#publicNavBtn");
            if(nav){
                nav.checked = false
            }
        }   
    }
  render() {
    return (
      <header className='h-[80px] bg-[#F6F8FB] shadow  fixed w-full z-[990]'>
        <div className='max-w-[1200px] ml-auto mr-auto flex justify-between items-center h-full max-[1240px]:ml-[20px] max-[1240px]:mr-[20px] max-[950px]:justify-center'>
            <Link onClick={this.closeNav.bind(this)}  to={`/${this.props.langPrefix}`}>
                <img src={logo} className='w-[210px] max-[500px]:w-[150px]' alt="Logo" />
            </Link>
            <input type='checkbox' className='hidden' id="publicNavBtn" />
            <div onClick={this.closeNav.bind(this)} className='empt_nav_right'>

            </div>
            <div className='flex items-center gap-[92px] nav_cont '>
            <Link onClick={this.closeNav.bind(this)}  className='min-[950px]:hidden' to={`/${this.props.langPrefix}`}>
                <img src={logo} className='w-[250px] ' alt="Logo" />
            </Link>
                <nav>
                    <ul className='flex gap-[50px] public_nav max-[980px]:gap-[20px] '>
                        <li>
                            <Link onClick={this.closeNav.bind(this)} className='text-[14px] font-regular' to={`/${this.props.langPrefix}countries`}>{this.props.language?.nav_1} </Link>
                        </li>
                        <li>
                            <Link  onClick={this.resetRandevu.bind(this)} className='text-[14px] font-regular' to={`/${this.props.langPrefix}booking`}>{this.props.language?.nav_2}</Link>
                        </li>
                        <li>
                            <Link onClick={this.closeNav.bind(this)}  className='text-[14px] font-regular' to={`/${this.props.langPrefix}accepted-students`}>{this.props.language?.nav_3}</Link>
                        </li>
                        {/* <li>
                            <Link className='text-[14px] font-regular' to='/city-rankings'>City ranking</Link>
                        </li>
                        <li>
                            <Link className='text-[14px] font-regular' to='/employability-rankings'>Emp ranking</Link>
                        </li> */}
                        <li>
                            <Link onClick={this.closeNav.bind(this)}  className='text-[14px] font-regular' to={`/${this.props.langPrefix}rank-universities`}>{this.props.language?.nav_4}</Link>
                        </li>
                        <li>
                            <Link onClick={this.closeNav.bind(this)}  className='text-[14px] font-regular' to={`/${this.props.langPrefix}contact`}>{this.props.language?.nav_5}</Link>
                        </li>
                        {/* <li>
                            <Link className='text-[14px] font-regular' to='/blog'>Blogs</Link>
                        </li> */}
                    </ul>
                </nav>
               <div className='flex items-center gap-[20px]'>
               <Link  onClick={this.closeNav.bind(this)} className='h-[32px] w-[97px] bg-[#1E398F] text-white rounded-full font-regular text-[14px] text-center inline-flex items-center justify-center' to={`/${this.props.langPrefix}login`}>
                    {this.props.language?.login_btn}
                </Link>
                <ChangeLangBtn />
               </div>
            </div>
            <label htmlFor='publicNavBtn' className='min-[950px]:hidden absolute right-[20px]'>
                <MenuSvg />
            </label>
        </div>
      </header>
    )
  }
}
const mapStateToProps = (state) =>({
    language:state.Data.language,
    langPrefix:state.Data.langPrefix,
});
const mapDispatchToProps = {resetStateValue}
export default connect(mapStateToProps, mapDispatchToProps)(Header)
