import React, { Component } from 'react'
import { getPathConvert, getTranslateLanguage, getZeroPad } from '../../../actions/getOtherFunctions';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

export class Card10 extends Component {
  render() {
    const {data} = this.props;
    return (
      <Link to={`/${this.props.langPrefix}subjects/${getPathConvert(data?.title_en)}`}  className='flex flex-col items-center'>
        <img className='w-[221px] h-[221px] rounded-full max-[500px]:rounded-[10px] max-[500px]:w-full'src={data?.image_full_url} alt={data?.title} />
        <h3 className='text-[20px] font-regular text-center mt-[25px]'>{getTranslateLanguage(this.props.lang_id, "title", data)}</h3>
        <p className='text-[rgba(0,0,0,0.5)] text-[16px] font-regular text-center'>{data?.universities_count} {this.props.language?.university}</p>
        {/* <div className='bg-white relative pl-[22px] pr-[22px] pt-[20px] pb-[18px] rounded-b-[15px]'>
            <div className='w-[57px] h-[57px] shadow bg-white rounded-full absolute flex items-center justify-center flex-col right-[5px] top-[-25px] gap-[0px]'>
                <span className='text-[18px] font-semibold text-[#1E398F]'>{getZeroPad(data?.universities_count)}</span>
                <span className='text-[10px] font-regular text-[#1E398F]'>Prog</span>
            </div>
            <h3 className='text-[22px] font-semibold '>{data?.title}</h3>
        </div> */}
      </Link>
    )
  }
}
const mapStateToProps = (state)=>({
  lang_id: state.Data.lang_id,
  language: state.Data.language,
  langPrefix: state.Data.langPrefix,
})
export default connect(mapStateToProps)(Card10)


