import React, { Component } from 'react'

export class ProgSvg extends Component {
  render() {
    return (
        <svg
        xmlns="http://www.w3.org/2000/svg"
        width={30}
        height={30}
        fill="var(--blue)"
        className="cf-icon-svg"
        viewBox="-1 0 19 19"
      >
        <path d="M16.417 9.583A7.917 7.917 0 1 1 8.5 1.666a7.917 7.917 0 0 1 7.917 7.917zM3.024 8.034s-.15.06-.15.155.15.155.15.155l5.117 2.171a1.074 1.074 0 0 0 .73 0l3.1-1.315 2.015-.855a.464.464 0 0 0 .05-.025l.006-.004.016-.01a.148.148 0 0 0 .078-.116c0-.057-.05-.112-.15-.155L8.87 5.863a.922.922 0 0 0-.312-.063h-.053a.969.969 0 0 0-.364.063L3.024 8.034zm8.948 1.853-2.854 1.211a1.699 1.699 0 0 1-1.225 0L5.04 9.887v1.565c0 1.009 1.552 1.826 3.466 1.826s3.467-.817 3.467-1.826zm1.882 1.992a.595.595 0 0 0-1.19 0v.79h1.19zm-.595-1.959a.396.396 0 1 0-.396-.395.396.396 0 0 0 .396.395zm-.396.672a.396.396 0 1 0 .396-.396.396.396 0 0 0-.396.396z" />
      </svg>
    )
  }
}

export default ProgSvg
