import React, { Component } from 'react'
import { changeStateValue } from '../redux/MainReducer';
import { connect } from 'react-redux';
import CloseSvg from './svg/CloseSvg';
import { insertNewData, insertNewDataParams } from '../actions/MainAction';
import { confirmAlert } from '../actions/SweetAlerts';

export class EmailPhoneConfTN extends Component {
    closeBtn=()=>{
        this.props.onClose();
    }
    sendCodeBtn=(type) => {
        let msg = "";
        let msg1 = "";
        if(type == "email"){
            msg =this.props.language?.send_otp_email;
            msg1 = this.props.language?.if_email_incorrect
        }else{
            msg = this.props.language?.send_otp_wp;
            msg1 = this.props.language?.if_wp_incorrect
        }
        confirmAlert("warning", msg, msg1, this.props.language?.cont_btn_txt, this.props.language?.back_btn_txt)
        .then(respAlert=>{
            if(respAlert == "success"){
                let formData = new FormData();
                for(const property in this.props.data){
                    formData.append(property, this.props.data[property])
                }
                formData.append("type", type)
                this.props.insertNewDataParams("send-verify-code", formData)
                .then(resp=>{
                    if(resp?.msg == "success"){
                        this.props.changeStateValue({name:"tnRegConfModal", value: false})
                        this.props.changeStateValue({
                            name:"tnRegisterVerifyModal",
                            value:{
                                position: true,
                                type: type,
                                ...resp?.data
                            }
                        })
                    }
                })
            }
        })
    }
  render() {
    return (
        <div className='background_shadow flex items-center justify-center'>
            <div className='bg-white w-[400px] rounded-[8px] shadow max-[450px]:ml-[20px] max-[450px]:mr-[20px]'>
                <div className='flex justify-center relative p-[8px_0] border-b'>
                    <h2 className='text-[#032552] font-semibold text-[24px]   max-[640px]:text-[14px] text-center'>{this.props.language?.where_send_otp}</h2>
                    <div className='absolute right-[-25px] top-[-25px] mt-0 bg-white w-[38px] h-[38px] flex justify-center items-center rounded-full max-[640px]:w-[30px] max-[640px]:h-[30px] max-[640px]:right-[-20px] max-[640px]:top-[-20px]'>
                        <button onClick={this.closeBtn.bind(this)} className='w-[30.28px] h-[30.28px] rounded-full border border-[#1E398F] inline-flex justify-center items-center max-[640px]:w-[24px] max-[640px]:h-[24px]'>
                            <CloseSvg color="#1E398F" />
                        </button>
                    </div>
                </div>
                <div className='flex items-center justify-between  p-[20px]'>
                    <button onClick={this.sendCodeBtn.bind(this, "email")} className='bg-blue p-[3px_8px] text-white text-[14px] rounded-[6px]'>
                        Email
                    </button>
                    <div className='flex items-center justify-between gap-[20px] max-[500px]:gap-[10px]'>
                        <span className='inline-block h-[1px] w-[50px] bg-gray-300 max-[500px]:w-[10px]'></span>
                        <span className='text-gray-400 font-[300] text-[14px]'>{this.props.language?.or}</span>
                        <span className='inline-block h-[1px] w-[50px] bg-gray-300 max-[500px]:w-[10px]'></span>
                    </div>
                    <button onClick={this.sendCodeBtn.bind(this, "phone")} className='bg-blue p-[3px_8px] text-white text-[14px] rounded-[6px]'>
                        Whatsapp
                    </button>
                </div>
                <p className='text-center txt-red text-[14px] mb-[10px]'>{this.props.language?.shouldbe_active_email}</p>
            </div>
        </div>
    )
  }
}
const mapStateToProps = (state) => ({
    language: state.Data.language
});
const mapDispatchToProps = {changeStateValue, insertNewDataParams}
export default connect(mapStateToProps, mapDispatchToProps)(EmailPhoneConfTN)
