import React, { Component } from 'react'
import { connect } from 'react-redux';
import { changeStateValue, resetStateValue } from '../../../../redux/MainReducer';
import { getCountries, getDisciplines, getEduDegree } from '../../../../actions/MainAction';
import DropSearch from '../../../../components/modals/components/DropSearch';
import { getDropValue } from '../../../../actions/getOtherFunctions';
import TextArea from '../../../../components/modals/components/TextArea';
import { mixinAlert } from '../../../../actions/SweetAlerts';
import { insertNewData, getStudentWishLists } from '../../../../actions/MainAction';
import Card5 from '../../../../components/public_components/cards/Card5';
import Header from '../../../../components/modals/components/Header';
import WorldSvg from '../../../../components/svg/WorldSvg';
import DiplomaSvg from '../../../../components/svg/DiplomaSvg';
import DisciplineSvg from '../../../../components/svg/DisciplineSvg';
import TextSvg from '../../../../components/svg/TextSvg';
import Card2 from '../../../../components/cards/Card2';

export class WishList extends Component {
    state={
        btnDisable:false,
        dropView: false
    }
    componentDidMount(){
        this.props.getCountries(3);
        this.props.getDisciplines("","", "all");
        this.props.getEduDegree();
        this.props.getStudentWishLists(this.props.student_id)
    }
    saveDataBtn=(data)=>{
        if(data?.country_id !=="" && data?.discipline_id !=="" && data?.degree_id !==""){
            this.setState({btnDisable:true})
            let formData = new FormData();
            for(const property in data){
                formData.append(property, data[property]);
               
            }
            formData.append("student_id", this.props.student_id);
            this.props.insertNewData('student-wish-list', formData)
            .then(resp=>{
                this.setState({btnDisable:false})
                if(resp === "success"){
                    this.props.getStudentWishLists(this.props.student_id)
                    this.props.resetStateValue({name:"studentWishListInfo"});
                    mixinAlert("success", "Sorğunuz uğurla yadda saxlanıldı");
                    this.setState({dropView:false})
                }else{
                    mixinAlert("error", "Xəta baş verdi");
                }
                
            })
        }else{
            mixinAlert("warning", "PZəhmət olmasa bütün xanaları doldurun.");
        }
    }
  render() {
    const {countries, disciplines, educationDegree, studentWishListInfo, studentWishListData, user} = this.props;
    // console.log(studentWishListData)
    const {dropView}= this.state;
    return (
      <div>
        {
            user?.role == "student" || user?.role == "teacher"?
            <div className='flex flex-col gap-[10px p-[10px]  rounded-[10px]'>
            <div className='flex justify-end'>
                <button onClick={()=>this.setState({dropView: !dropView})} className='bg-orange text-white text-[17px] h-[40px] p-[0_10px] rounded-[6px] max-[450px]:text-[14px] max-[450px]:h-[30px]'>Sorğu göndər</button>
            </div>
            {
                dropView?
                <div className='background_shadow flex justify-center items-center'>
                 <div className='w-[577px] bg-white rounded-[10px]   max-[750px]:ml-[20px] max-[750px]:mr-[20px]'>
                    <Header title={"Sörğunuzu qeyd edin"} closeBtn={()=>this.setState({dropView:false})} />
                    <div className='p-[11px] max-h-[60vh] overflow-scroll'>
                        <div className='grid grid-cols-2 gap-[30px] max-[750px]:grid-cols-1'>
                            <DropSearch svg={<WorldSvg />} bigDrop required options={getDropValue(countries, "name", "id")} 
                                name="studentWishListInfo.country_id" value={studentWishListInfo?.country_id} title={"Ölkə seçin"} placeholder={"Ölkə seçin"} />
                            <DropSearch svg={<DisciplineSvg />} bigDrop required options={getDropValue(disciplines, "name", "id")} 
                            name="studentWishListInfo.discipline_id" value={studentWishListInfo?.discipline_id} title={"Sahə seçin"} placeholder={"Sahə seçin"} />
                            <DropSearch svg={<DiplomaSvg />} bigDrop required options={getDropValue(educationDegree, "name", "id")} 
                            name="studentWishListInfo.degree_id" value={studentWishListInfo?.degree_id} title={"Təhsil dərəcəsi seçin"} placeholder={"Təhsil dərəcəsi seçin"} />
                        </div>
                        <div className='mt-[10px]'>
                            <TextArea svg={<TextSvg />} bigArea value={studentWishListInfo?.description} title='Qeydiniz' name="studentWishListInfo.description" />
                        </div>
                        <div className='grid grid-cols-2 mt-[30px] p-[11px] gap-[30px]'>
                        <button  onClick={()=>this.setState({dropView: !dropView})} className='h-[48px] p-[0_10px] bg-orange font-regular text-white rounded-[5px] text-[20px]  max-[400px]:text-[16px] max-[400px]:h-[30px] max-[400px]:pl-[5px] max-[400px]:pr-[5px] max-[310px]:text-[14px]'>Bağla</button>
                        {
                            studentWishListInfo?.country_id !=="" && studentWishListInfo?.discipline_id !=="" && studentWishListInfo?.degree_id !==""?
                            <button onClick={this.saveDataBtn.bind(this, studentWishListInfo)} className='h-[48px] p-[0_10px] bg-blue font-regular text-white rounded-[5px] text-[20px]  max-[400px]:text-[16px] max-[400px]:h-[30px] max-[400px]:pl-[5px] max-[400px]:pr-[5px] max-[310px]:text-[14px]'>Yadda saxla</button>
                            :
                            <button className='h-[48px] p-[0_10px] bg-gray-400 font-regular text-white rounded-[5px] text-[20px]  max-[400px]:text-[16px] max-[400px]:h-[30px] max-[400px]:pl-[5px] max-[400px]:pr-[5px] max-[310px]:text-[14px]'>Yadda saxla</button>
                        }
                    </div>
                    </div>
                   
                 </div>
                </div>:null
            }
           
        </div>:null
        }
      
        <div  className='grid grid-full-360'>
        {
              studentWishListData?.map((data, i)=>{
                return(
                   <Card2
                    key={i}
                    image={data?.country?.image_full_url}
                    country={data?.country?.name}
                    flag={data?.country?.flag_full_url}
                    degree={data?.degree?.name}
                    discipline={data?.discipline?.name}
                    text={data?.description}
                   />
                )
              })
          }
        </div>
      </div>
    )
  }
}
const mapStateToProps = (state) =>({
    studentWishListInfo: state.Data.studentWishListInfo,
    countries: state.Data.countries,
    disciplines: state.Data.disciplines,
    educationDegree: state.Data.educationDegree,
    studentWishListData: state.Data.studentWishListData,
    user: state.Data.user,
});
const mapDispatchToProps = {changeStateValue, getCountries, getDisciplines, getEduDegree, insertNewData, resetStateValue, getStudentWishLists}
export default connect(mapStateToProps, mapDispatchToProps)(WishList)
