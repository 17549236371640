import React, { Component } from 'react'
import BookingCalendarSection from '../public_pages/sections/BookingCalendarSection'
import { changeStateValue, resetStateValue } from '../../redux/MainReducer';
import { connect } from 'react-redux';
import { deleteData, getUserAlreadyBooking } from '../../actions/MainAction';
import moment from 'moment';
import { confirmAlert, mixinAlert } from '../../actions/SweetAlerts';

export class StudentBooking extends Component {
   

    getData(){
        this.props.getUserAlreadyBooking()
        .then(resp=>{
            if(resp ==""){
                if(this.props.user !=""){
                    this.props.changeStateValue({  name:"bookingUserInfo.name",  value: this.props.user?.full_name  });
                    this.props.changeStateValue({  name:"bookingUserInfo.email",  value: this.props.user?.email  });
                    this.props.changeStateValue({  name:"bookingUserInfo.phone_number",  value: this.props.user?.phone_number  });
                    this.props.changeStateValue({  name:"bookingUserInfo.day",  value: ""  });
                    this.props.changeStateValue({  name:"bookingUserInfo.time",  value: ""  });
                }
                this.props.changeStateValue({
                    name:"bookingData",
                    value: ""
                })
            }else{
                this.props.changeStateValue({
                    name:"bookingData",
                    value: resp
                })
            }
           
        })
    }
    componentDidMount(){
        this.getData();
    }
    onDeleteBtn=(id)=>{

        confirmAlert("warning", "Əminsiniz?", "Bunu geri gətirə bilmiyəcəksiniz!", "Bəli, Silin.", "Xeyir")
        .then(alertResp=>{
            if(alertResp === "success"){
            this.props.deleteData(`user-already-booking/${id}`)
            .then(resp=>{
                if(resp == "success"){
                    this.getData();
                    mixinAlert("success", "Randevu uğurla silindi");
                    this.props.resetStateValue({name:"bookingUserInfo"})
                    this.props.resetStateValue({name:"bookingData"})
                    this.props.resetStateValue({name:"bookingLastModal"})
                }else{
                    mixinAlert("error", "Xəta baş verdi")
                }
            })
            }
        })
        // console.log(id)
    }

    onChangeBtn=(data)=>{
        this.props.changeStateValue({  name:"bookingUserInfo.id",  value: data?.id  });
        this.props.changeStateValue({  name:"bookingUserInfo.name",  value: data?.name  });
        this.props.changeStateValue({  name:"bookingUserInfo.email",  value: data?.email  });
        this.props.changeStateValue({  name:"bookingUserInfo.phone_number",  value: data?.phone_number  });
        this.props.changeStateValue({  name:"bookingUserInfo.content",  value: data?.content  });
        this.props.changeStateValue({  name:"bookingUserInfo.day",  value: ""  });
        this.props.changeStateValue({  name:"bookingUserInfo.time",  value: ""  });
        this.props.changeStateValue({  name:"bookingData",  value: ""  });
        this.props.changeStateValue({  name:"bookingLastModal",  value:false  });
    }
  render() {
    const {bookingData} = this.props;
    return (
        <div className='pt-[40px]'>
            {/* <SectionHeader text1={this.props.language?.section_title6} text2="" /> */}
            <div className='max-w-[1200px] ml-auto mr-auto max-[1240px]:ml-[20px] max-[1240px]:mr-[20px] mt-[40px]'>
                <div className='bg-white p-[20px] rounded-[5px]'>
                    {
                        bookingData==""?
                        <BookingCalendarSection />:
                       <div className='flex flex-col gap-[10px] items-center justify-center h-[60vh]'>
                        <p>Siz {moment(bookingData?.day).format("DD/MM/YYYY")}, {bookingData?.time} tarixinə randevu almısınız.</p>
                        <p>Randevunu silə və dəyişdirə bilərsiniz.</p>

                        <div className='flex justify-center items-center gap-[20px]'>
                            <button onClick={this.onChangeBtn.bind(this, bookingData)} className='p-[5px_10px] bg-blue rounded-[6px] text-white'>Dəyişdir</button>
                            <button onClick={this.onDeleteBtn.bind(this, bookingData?.id)} className='p-[5px_10px] bg-red rounded-[6px] text-white'>Sil</button>
                        </div>
                       </div>
                    }
                    
                </div>
            </div>
        </div>
    )
  }
}
const mapStateToProps = (state)=>({
    user: state.Data.user,
    bookingData: state.Data.bookingData,
});
const mapDispatchToProps = {changeStateValue, getUserAlreadyBooking, deleteData, changeStateValue, resetStateValue}
export default connect(mapStateToProps, mapDispatchToProps)(StudentBooking)
