import React, { Component } from 'react'
import DashAdvImg from '../../components/DashAdvImg'
import DashPaymentComponent from '../../components/DashPaymentComponent'
import DashTeacher from '../../components/DashTeacher'
import DashAppliedComp from '../../components/DashAppliedComp'
import { getTeacherApplicationStatus } from '../../actions/MainAction'
import { connect } from 'react-redux'
import TNDashCard from '../../components/TNDashCard'

export class TeacherDashboard extends Component {
  componentDidMount(){
    this.props.getTeacherApplicationStatus()
  }
  render() {
    const {teacherApplicationStatus} = this.props;
    // console.log(teacherApplicationStatus)
    // console.log(teacherApplicationStatus)
    return (
      <div>
        {/* <div className='grid grid-cols-[8fr_4fr] gap-[20px]'>
            <DashAdvImg />
            <DashPaymentComponent />
        </div>
        <div className='mt-[40px] grid grid-cols-3 gap-[20px] max-[1350px]:grid-cols-2'>
            <DashAppliedComp link="student-info" options={teacherApplicationStatus?.applied_students} title="Müraciət olunanlar" />
            <DashAppliedComp options={teacherApplicationStatus?.accepted_students} title="Qəbul olunanlar" />
            <DashTeacher />
        </div> */}
        <div className='grid grid-cols-3 mt-[20px] gap-[50px] max-[1320px]:gap-[20px] max-[1150px]:grid-cols-2 max-[600px]:grid-cols-1'>
          <TNDashCard
            title="Qeydiyyatdan keçənlər"
            price={1}
            studentCount={teacherApplicationStatus?.student_count}
            note="Qeyd: Hər qeydiyyat üçün 1 AZN"
          />
          <TNDashCard
            title="Müqavilə bağlayanlar"
            price={200}
            studentCount={0}
            note="Qeyd: Hər müqavilə üçün 200 AZN"
          />
          <TNDashCard
            title="Universitetə başlayanlar"
            price={300}
            studentCount={0}
            note="Qeyd: Hər universitetə başlayan üçün 300 AZN"
          />
        </div>

        {/* <div className='mt-[40px]'>
          <h2 className='text-center txt-blue text-[28px] font-[600]'>Xaricdə təhsil üçün imkanlar</h2>

          <div className='mt-[20px] grid grid-cols-2 gap-[50px]'>
            <div className='flex gap-[30px] items-center'>
              <img className='w-[200px] h-[200px] rounded-[40px_0_40px_0]' src="https://the7.io/elementor-creative-light/wp-content/uploads/sites/109/2022/04/cr715.jpg"/>
              <div>
                  <h3 className='text-[30px] txt-blue font-[500]'>10 ways how to lorem ipsum glavrida dolor amet</h3>
                  <span className='text-[16px] uppercase text-gray-600 font-[400]'>Industry / January 2, 2024</span>
              </div>
            </div>
            <div className='flex gap-[30px] items-center'>
              <img className='w-[200px] h-[200px] rounded-[40px_0_40px_0]' src="https://the7.io/elementor-creative-light/wp-content/uploads/sites/109/2022/04/cr715.jpg"/>
              <div>
                  <h3 className='text-[30px] txt-blue font-[500]'>10 ways how to lorem ipsum glavrida dolor amet</h3>
                  <span className='text-[16px] uppercase text-gray-600 font-[400]'>Industry / January 2, 2024</span>
              </div>
            </div>
            <div className='flex gap-[30px] items-center'>
              <img className='w-[200px] h-[200px] rounded-[40px_0_40px_0]' src="https://the7.io/elementor-creative-light/wp-content/uploads/sites/109/2022/04/cr715.jpg"/>
              <div>
                  <h3 className='text-[30px] txt-blue font-[500]'>10 ways how to lorem ipsum glavrida dolor amet</h3>
                  <span className='text-[16px] uppercase text-gray-600 font-[400]'>Industry / January 2, 2024</span>
              </div>
            </div>
            <div className='flex gap-[30px] items-center'>
              <img className='w-[200px] h-[200px] rounded-[40px_0_40px_0]' src="https://the7.io/elementor-creative-light/wp-content/uploads/sites/109/2022/04/cr715.jpg"/>
              <div>
                  <h3 className='text-[30px] txt-blue font-[500]'>10 ways how to lorem ipsum glavrida dolor amet</h3>
                  <span className='text-[16px] uppercase text-gray-600 font-[400]'>Industry / January 2, 2024</span>
              </div>
            </div>
          </div>
        </div> */}
      </div>
    )
  }
}
const mapStateToProps = (state)=>({
  teacherApplicationStatus: state.Data.teacherApplicationStatus
});
const mapDispatchToProps = {getTeacherApplicationStatus}
export default connect(mapStateToProps, mapDispatchToProps)(TeacherDashboard)
