import React, { Component } from 'react'
import Card7 from '../../../components/public_components/cards/Card7';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { getPathConvert } from '../../../actions/getOtherFunctions';
import { connect } from 'react-redux';

export class MostRecentBlogSection extends Component {
  render() {
    const {blogs} = this.props;
    // console.log(blogs)
    return (
      <section >
        {/* <div className='max-w-[1200px] ml-auto mr-auto max-[1240px]:ml-[20px] max-[1240px]:mr-[20px]'>
            <div className='border-b pb-[20px]'>
                <h3 className='text-[28px] font-regular'>{'Most recent posts'}</h3>
            </div>
            <div className='grid grid-cols-2 gap-[30px] mt-[30px] max-[1000px]:grid-cols-1'>
                <div>
                    <Card7 data={blogs[0]} type={'column'} />
                </div>
                <div className='flex flex-col justify-between  max-[1000px]:gap-[30px]'>
                    {blogs[1]?<Card7 data={blogs[1]} type={'row'} />:null}
                
                    {blogs[2]?
                    <>
                        <div className='h-[0.5px] bg-gray-300'></div>
                        <Card7 data={blogs[2]} type={'row'} />
                    </>
                    :null}
                    {blogs[3]?
                    <>
                        <div className='h-[0.5px] bg-gray-300'></div>
                        <Card7 data={blogs[3]} type={'row'} />
                    </>
                    :null}
                </div>
            </div>
        </div>
        {
            blogs.length >4?
            <div className='bg-[#fafafa] p-[20px] mt-[60px]'>
                <div className='max-w-[1200px] ml-auto mr-auto max-[1240px]:ml-[20px] max-[1240px]:mr-[20px]'>
                    <div className='grid grid-cols-3 gap-[30px] mt-[30px]'>
                    {
                        blogs?.map((data, i)=>{
                            if(i>3){
                                return(
                                    <Card7 data={data} key={i} type={'column'} />
                                )
                            }
                        })
                    }
                    </div>
                </div>
            </div>:null
        }
        */}
        <div className='max-w-[1200px] ml-auto mr-auto max-[1240px]:ml-[20px] max-[1240px]:mr-[20px] mt-[30px]'>
            <div className='grid grid-cols-2 gap-[50px] max-[900px]:grid-cols-1'>
                {
                    blogs?.map((data, i)=>{
                        return (
                            <Link to={`/${this.props.langPrefix}blog/${data?.path}`} key={i} className='flex items-center gap-[30px] max-[550px]:flex-col'>
                                <img className='w-[200px] h-[200px] rounded-tl-[40px] rounded-br-[40px]' src={data?.image_full_url} />
                                <div>
                                    <h3 className='text-[#001a40] text-[25px] font-[500] max-[550px]:text-center'>{data?.title}</h3>
                                    <span className='text-gray-500 text-[14px] font-[400] max-[550px]:text-center max-[550px]:inline-block max-[550px]:w-full'>{moment(data?.created_at)?.format("DD/MM/YYYY")}</span>
                                </div>
                            </Link>
                        )
                    })
                }
            </div>
        </div>
      </section>
    )
  }
}
const mapStateToProps = (state) =>({
    langPrefix: state.Data.langPrefix
})
export default connect(mapStateToProps)(MostRecentBlogSection)
