import React, { Component } from 'react'
import Header from '../../components/header/Header'
import Search from '../../components/Search'
import Buttons from '../../components/Buttons'
import { connect } from 'react-redux'
import { changeStateValue } from '../../redux/MainReducer';
import { getSubjects } from '../../actions/MainAction';
import Card1 from '../../components/cards/Card1'
import LoadMoreBtn from '../../components/LoadMoreBtn'
export class SeoSubjectPage extends Component {
  state={
    page:1,
    search:''
  }
  openModal=(data)=>{
    this.props.changeStateValue({
      name:'adminModal',
      value:{
        position: true,
        id: data?.id?data?.id:'',
        seo_title: data?.seo_title?data?.seo_title:'',
        seo_title_en: data?.seo_title_en?data?.seo_title_en:'',
        seo_title_tr: data?.seo_title_tr?data?.seo_title_tr:'',
        seo_title_ru: data?.seo_title_ru?data?.seo_title_ru:'',

        seo_description: data?.seo_description?data?.seo_description:'',
        seo_description_en: data?.seo_description_en?data?.seo_description_en:'',
        seo_description_tr: data?.seo_description_tr?data?.seo_description_tr:'',
        seo_description_ru: data?.seo_description_ru?data?.seo_description_ru:'',

        seo_keywords: data?.seo_keywords?data?.seo_keywords:'',
        seo_keywords_en: data?.seo_keywords_en?data?.seo_keywords_en:'',
        seo_keywords_tr: data?.seo_keywords_tr?data?.seo_keywords_tr:'',
        seo_keywords_ru: data?.seo_keywords_ru?data?.seo_keywords_ru:'',
        afterCallFunction:this.props.getSubjects,
        afterCallParams:[this.state.page, this.state.search],
        requireds: ["seo_description", "seo_keywords"],
        url:'subjects',
        modalTitle:`${data?.id?'Edit':'Add'} Subject`
      }
    })
  }

  componentDidMount(){
    this.props.getSubjects(1, '')
  }

  onSearchData=(search)=>{
    this.setState({search})
    this.props.getSubjects(this.state.page, search)
  }
  loadMore=(page)=>{
    this.setState({page})
    this.props.getSubjects(page, this.state.search)
  }

  afterCallFunction=()=>{
    this.props.getSubjects(this.state.page, this.state.search)
  }
  render() {
    const {subjectsData} = this.props;
    // console.log(subjectsData)
    return (
        <div className='w-full  pl-[27px] pr-[27px]'>
            <Header title="Subjects" />
          
            <div className='flex justify-center mt-[20px]'>
                <Search onSearchData={this.onSearchData.bind(this)} largWidth={true} />
            </div>

            <div className='grid-full-250 mt-[30px]'>
              {
                subjectsData?.map((data, i)=>{
                  return(
                    <Card1 
                      key={i}
                      image={data?.image_full_url}
                      name={data?.title}
                      onClickEdit={this.openModal.bind(this, data)}
                    />
                  )
                })
              }
            </div>
            {
                subjectsData?.length >=20?
                <LoadMoreBtn onLoadData={this.loadMore} count={subjectsData?.length} />:null
            }
        </div>
    )
  }
}
const mapStateToProps = (state) =>({
  subjectsData: state.Data.subjectsData
});
const mapDispatchToProps = {changeStateValue, getSubjects}
export default connect(mapStateToProps, mapDispatchToProps)(SeoSubjectPage)
