import React, { Component } from 'react'
import SectionHeader from '../../components/public_components/SectionHeader'
import Footer from './sections/Footer'
import EmployabilityRankingSection from './sections/EmployabilityRankingSection'
import CitiesRankingSection from './sections/CitiesRankingSection'
import { Link } from 'react-router-dom'
import Card3 from '../../components/cards/Card3'
import { Helmet } from 'react-helmet'
import UniRankSection from './sections/UniRankSection'
import { connect } from 'react-redux'
import { getSeoPageData } from '../../actions/MainAction'
import { getTranslateLanguage } from '../../actions/getOtherFunctions'

export class UniversityRanks extends Component {
    state={
        page:'emp'
    }

    componentDidMount(){
        window.scrollTo(0,0);
        this.props.getSeoPageData("university_page");
    }
  render() {
    const {page} = this.state;
    const {seo_page_data} = this.props;
    return (
        <div className='mt-[80px] bg-[#F6F8FB]'>
          {
            seo_page_data !=""?
            <Helmet>
              <title>{getTranslateLanguage(this.props.lang_id, "seo_title", seo_page_data)}</title>
              <meta property="og:locale" content="az_AZ" />
              <meta property="og:type" content="website" />
              <meta property="og:title" content={getTranslateLanguage(this.props.lang_id, "seo_title", seo_page_data)} />
              <meta property="og:description" content={getTranslateLanguage(this.props.lang_id, "seo_description", seo_page_data)} />
              <meta name="description" content={getTranslateLanguage(this.props.lang_id, "seo_description", seo_page_data)}/>
              <meta name="keywords" content={getTranslateLanguage(this.props.lang_id, "seo_keywords", seo_page_data)}/>
              <meta property="og:url" content={`https://celtgo.com${window.location.pathname}`} />
              <meta property="og:site_name" content="CELT Colleges" />
              <meta property="article:publisher" content="https://www.facebook.com/celtscollege" />
              <meta property="article:modified_time" content="2024-06-06T08:56:25+00:00" />
              <meta property="og:image" content="https://celtgo.com/static/media/celtLogo6.05ddd884642befe11a4e.png" />
              <meta property="og:image:width" content="900" />
              <link rel="canonical" href={`https://celtgo.com${window.location.pathname}`} />
              <meta property="og:image:height" content="600" />
              <meta property="og:image:type" content="image/jpeg" />
              <meta name="twitter:card" content="summary_large_image" />
              <meta name="twitter:title" content={getTranslateLanguage(this.props.lang_id, "seo_title", seo_page_data)} />
              <meta name="twitter:description" content={getTranslateLanguage(this.props.lang_id, "seo_description", seo_page_data)} />
              <meta name="twitter:site" content="@celtcolleges" />
              <meta name="twitter:label1" content="Est. reading time" />
              <meta name="twitter:data1" content="7 minutes" />
          </Helmet>:null
          }
           
           <div className='h-[90vh] max-[1100px]:h-auto'>
           <UniRankSection headingType="h1" />
           </div>
            <Footer />
      </div>
    )
  }
}
const mapStateToProps = (state) =>({
  language: state.Data.language,
  seo_page_data: state.Data.seo_page_data,
  lang_id: state.Data.lang_id,
});
const mapDispatchToProps = {getSeoPageData}
export default connect(mapStateToProps, mapDispatchToProps)(UniversityRanks)
