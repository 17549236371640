import React, { Component } from 'react'
import Searchable from 'react-searchable-dropdown';
import { connect } from 'react-redux';
import { changeStateValue } from '../../../redux/MainReducer'; 
export class DropSearch extends Component {
    selectValue=(name, selectData,onChangeParent,data)=>{
      if(name){
        this.props.changeStateValue({
          name: name, 
          value: data
        })
      }
      selectData(data);
      onChangeParent(name, data)
    }

    getPlaceholder(placeholder, value, options){
      if(value === ""){
        return placeholder;
      }else{
        const check = options.find(item=>item?.value == value);
        if(check){
          return check?.label
        }else{
          return placeholder
        }
      }
    }
  render() {
    const {options=[], name, placeholder, title="", value="", required=false, selectData=()=>{}, lang, onChangeParent=()=>{}, bigDrop=false, svg, reqText="This field is required"} = this.props;
    // console.log(name)
    return (
      <div className={`${bigDrop?"big-drop":""} ${required && value===""? "big-drop_req ":""}`}>
        {title !== "" ? <label className={bigDrop?"text-[#000] text-[12px] font-[500] inline-flex items-center gap-[5px]":`text-[14px] txt-light-black`}>{svg} {title}</label>:null }
        <Searchable
            placeholder={this.getPlaceholder(placeholder, value, options)}
            options={options}
            
            onSelect={this.selectValue.bind(this.label, name, selectData, onChangeParent)}
        />
        {
            required && value===""?
            <span className='text-[11px] text-red-700'>{reqText}</span>:null
        }
      </div>
    )
  }
}
const mapStateToProps = (state) =>({
  lang: state.Data.lang
});
const mapDispatchToProps = {changeStateValue}
export default connect(mapStateToProps, mapDispatchToProps)(DropSearch)
