import React, { Component } from 'react'
import { changeStateValue } from '../../redux/MainReducer';
import { connect } from 'react-redux';
import { getAllGeneralSeo } from '../../actions/MainAction';
import Card1 from '../../components/cards/Card1';
import Header from '../../components/header/Header';

export class GeneralPagesSeo extends Component {
    componentDidMount(){
        this.props.getAllGeneralSeo();
    }
    openModal=(data)=>{
        this.props.changeStateValue({
          name:'adminModal',
          value:{
            position: true,
            id: data?.id?data?.id:'',
            seo_title: data?.seo_title?data?.seo_title:'',
            seo_title_en: data?.seo_title_en?data?.seo_title_en:'',
            seo_title_tr: data?.seo_title_tr?data?.seo_title_tr:'',
            seo_title_ru: data?.seo_title_ru?data?.seo_title_ru:'',
    
            seo_description: data?.seo_description?data?.seo_description:'',
            seo_description_en: data?.seo_description_en?data?.seo_description_en:'',
            seo_description_tr: data?.seo_description_tr?data?.seo_description_tr:'',
            seo_description_ru: data?.seo_description_ru?data?.seo_description_ru:'',
    
            seo_keywords: data?.seo_keywords?data?.seo_keywords:'',
            seo_keywords_en: data?.seo_keywords_en?data?.seo_keywords_en:'',
            seo_keywords_tr: data?.seo_keywords_tr?data?.seo_keywords_tr:'',
            seo_keywords_ru: data?.seo_keywords_ru?data?.seo_keywords_ru:'',
            
    
            afterCallFunction:this.props.getAllGeneralSeo,
            afterCallParams:[],
            requireds: ["seo_description", "seo_keywords"],
            url:'general-seo-all',
            modalTitle:`${data?.id?'Edit':'Add'} SEO`
          }
        })
      }
  render() {
    const {general_seo_all} = this.props;
    // console.log(general_seo_all)
    return (
        <div className='w-full  pl-[27px] pr-[27px]'>
            <Header title={"General SEO"} />
            <div className='grid-full-250 mt-[30px]'>
              {
                general_seo_all?.map((data, i)=>{
                  return(
                    <Card1
                      key={i}
                      name={data?.seo_title}
                      onClickEdit={this.openModal.bind(this, data)}
                      country={data?.page}
                    />
                  )
                })
              }
            </div>
      </div>
    )
  }
}
const mapStateToProps = (state) =>({
    general_seo_all: state.Data.general_seo_all
});
const mapDispatchToProps = {changeStateValue, getAllGeneralSeo}
export default connect(mapStateToProps, mapDispatchToProps)(GeneralPagesSeo)
