import axios from "axios"
import {changeStateValue} from "../redux/MainReducer";
import { MAIN_API } from "../MAIN_API";
import { getErrors } from "./MainAction";
export const getAccStudent=(page=1, companyId="")=>async dispatch=>{
    dispatch(changeStateValue({name:"loader", value:true}))
    return await axios.get('https://goapi.celt.vip/api/v1/accepted-students',{
        params: {page, lang_id:1,companyId}
    })
    .then(resp=>{
        dispatch(changeStateValue({name:"loader", value:false}))
        dispatch(changeStateValue({name:'acceptedStudents', value: resp?.data}))
    }).catch(err=>{
        dispatch(changeStateValue({name:"loader", value:false}))
    })
}
export const getPublicCountries=(page=1, uni_count='true', uni_rank_count="")=>async dispatch=>{
    // console.log(page, uni_count, uni_rank_count)
    dispatch(changeStateValue({name:"loader", value:true}))
    return await axios.get(`${MAIN_API}/public-country`,{
        params: {page, uni_count:uni_count, uni_rank_count}
    })
    .then(resp=>{
        dispatch(changeStateValue({name:"publicCountries", value:resp?.data}))
    }).catch(err=>{
        // console.log(err.response)
        dispatch(changeStateValue({name:"loader", value:false}))
    })
}
export const getPublicDisciplines=(page=1, type="page")=>async dispatch=>{
    dispatch(changeStateValue({name:"loader", value:true}))
    return await axios.get(`${MAIN_API}/public-discipline`,{
        params: {page, program_count:'true', sch_count:'true', type}
    })
    .then(resp=>{
        dispatch(changeStateValue({name:"publicDisciplines", value:resp?.data}))
    }).catch(err=>{
        dispatch(changeStateValue({name:"loader", value:false}))
    })
}
export const getPublicSections=(page=1, type="page")=>async dispatch=>{
    dispatch(changeStateValue({name:"loader", value:true}))
    return await axios.get(`${MAIN_API}/public-subjects`,{
        params: {page, program_count:'true', sch_count:'true', type}
    })
    .then(resp=>{
        dispatch(changeStateValue({name:"publicSections", value:resp?.data}))
    }).catch(err=>{
        dispatch(changeStateValue({name:"loader", value:false}))
    })
}
export const getPublicUniversities=(page=1, country_id)=>async dispatch=>{
    dispatch(changeStateValue({name:"loader", value:true}))
    return await axios.get(`${MAIN_API}/public-university`,{
        params: {page, country_id}
    })
    .then(resp=>{
        dispatch(changeStateValue({name:"publicUniversities", value:resp?.data}))
    }).catch(err=>{
        dispatch(changeStateValue({name:"loader", value:false}))
    })
}
export const getPublicPrograms=(page=1, university_id, degree_id)=>async dispatch=>{
    dispatch(changeStateValue({name:"loader", value:true}))
    return await axios.get(`${MAIN_API}/public-programs`,{
        params: {page, university_id, degree_id}
    })
    .then(resp=>{
        dispatch(changeStateValue({name:"publicPrograms", value:resp?.data}))
    }).catch(err=>{
        // console.log(err.response)
        dispatch(changeStateValue({name:"loader", value:false}))
    })
}
export const getDegreeByUniversity=(university_id, sch_program="")=>async dispatch=>{
    dispatch(changeStateValue({name:"loader", value:true}))
    return await axios.get(`${MAIN_API}/degree-by-university`,{
        params: {university_id, sch_program}
    })
    .then(resp=>{
        dispatch(changeStateValue({name:"degreeByUniversity", value:resp?.data}))
        return resp?.data
    }).catch(err=>{
        dispatch(changeStateValue({name:"loader", value:false}));
        return []
    })
}

export const getPublicBookingInfo = () => async dispatch =>  {
    dispatch(changeStateValue({name: "loader", value:true}));
    return await axios.get(`${MAIN_API}/public-booking-info`,{
        headers:{}
    }).then(resp=>{
        dispatch(changeStateValue({name:"loader", value:false}));
        return resp?.data;
    }).catch(err=>{
        dispatch(changeStateValue({name: "loader", value:false}));
        // dispatch(getErrors(err))
        // console.log(err.response)
        return '';
    })
}

export const getPublicBookingDate = (date) => async dispatch =>  {
    dispatch(changeStateValue({name: "loader", value:true}));
    return await axios.get(`${MAIN_API}/public-booking-date?date=${date}`,{
        headers:{}
    }).then(resp=>{
        dispatch(changeStateValue({name:"bookingDates", value:resp.data}));
        return resp?.data;
    }).catch(err=>{
        dispatch(changeStateValue({name: "loader", value:false}));
        // dispatch(getErrors(err))
        // console.log(err.response)
        return '';
    })
}

export const getPublicHighSchoolExams = () => async dispatch =>  {
    dispatch(changeStateValue({name: "loader", value:true}));
    return await axios.get(`${MAIN_API}/public-high-school-exams`,{
        headers:{}
    }).then(resp=>{
        dispatch(changeStateValue({name:"publicHighSchoolExams", value:resp.data}));
        return "success";
    }).catch(err=>{
        dispatch(changeStateValue({name: "loader", value:false}));
        dispatch(getErrors(err))
        return "error";
    })
}

export const getPublicEduDegree = () => async dispatch =>  {
    dispatch(changeStateValue({name: "loader", value:true}));
    return await axios.get(`${MAIN_API}/public-education-degree`,{
        headers: {}
    }).then(resp=>{
        dispatch(changeStateValue({name:"publicEducationDegree", value:resp.data}));
        return "success";
    }).catch(err=>{
        dispatch(changeStateValue({name: "loader", value:false}));
        // dispatch(getErrors(err))
        return "error";
    })
}
export const getPublicExams = () => async dispatch =>  {
    dispatch(changeStateValue({name: "loader", value:true}));
    return await axios.get(`${MAIN_API}/public-exams`,{
        headers: {}
    }).then(resp=>{
        dispatch(changeStateValue({name:"publicExams", value:resp.data}));
        return "success";
    }).catch(err=>{
        dispatch(changeStateValue({name: "loader", value:false}));
        // dispatch(getErrors(err))
        return "error";
    })
}
export const getCountryByScholarhip = (page=1) => async dispatch =>  {
    dispatch(changeStateValue({name: "loader", value:true}));
    return await axios.get(`${MAIN_API}/countries-by-sch?page=${page}`,{
        headers: {}
    }).then(resp=>{
        dispatch(changeStateValue({name:"countryBySch", value:resp.data}));
        return "success";
    }).catch(err=>{
        dispatch(changeStateValue({name: "loader", value:false}));
        // dispatch(getErrors(err))
        return "error";
    })
}
export const getUniByScholarhip = (page=1,country_title) => async dispatch =>  {
    dispatch(changeStateValue({name: "loader", value:true}));
    return await axios.get(`${MAIN_API}/uni-by-sch?country_title=${country_title}&page=${page}`,{
        headers: {}
    }).then(resp=>{
        dispatch(changeStateValue({name:"uniBySch", value:resp.data}));
        return "success";
    }).catch(err=>{
        dispatch(changeStateValue({name: "loader", value:false}));
        // dispatch(getErrors(err))
        console.log(err.response)
        return "error";
    })
}
export const getUProgramByScholarhip = (page=1, university_id="", degree_id="") => async dispatch =>  {
    dispatch(changeStateValue({name: "loader", value:true}));
    return await axios.get(`${MAIN_API}/program-by-sch?university_id=${university_id}&page=${page}&degree_id=${degree_id}`,{
        headers: {}
    }).then(resp=>{
        dispatch(changeStateValue({name:"programBySch", value:resp.data}));
        return "success";
    }).catch(err=>{
        dispatch(changeStateValue({name: "loader", value:false}));
        // dispatch(getErrors(err))
        return "error";
    })
}
export const getCountryByEduBack = (params) => async dispatch =>  {
    console.log(params)
    dispatch(changeStateValue({name: "loader", value:true}));
    return await axios.get(`${MAIN_API}/country-edu-back`,{
        params: params,
        headers: {
            
        }
    }).then(resp=>{
        dispatch(changeStateValue({name:"publicCountries", value:resp.data}));
        return "success";
    }).catch(err=>{
        dispatch(changeStateValue({name: "loader", value:false}));
        // dispatch(getErrors(err))
        return "error";
    })
}
export const getDisciplinesByEduBack = (params) => async dispatch =>  {
    dispatch(changeStateValue({name: "loader", value:true}));
    return await axios.get(`${MAIN_API}/discipline-edu-back`,{
        params,
        headers: {
        }
    }).then(resp=>{
        dispatch(changeStateValue({name:"publicDisciplines", value:resp.data}));
        return "success";
    }).catch(err=>{
        dispatch(changeStateValue({name: "loader", value:false}));
        // dispatch(getErrors(err))
        return "error";
    })
}