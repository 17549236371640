import React, { Component } from 'react'
import Header from './components/Header'
import { changeStateValue, resetStateValue } from '../../redux/MainReducer'
import { connect } from 'react-redux'
import Input from './components/Input'
import TextArea from './components/TextArea'
import TextSvg from '../svg/TextSvg'
import InpSvg from '../svg/InpSvg'
import { getApplyRequirements, getStudentRequirements, insertNewData } from '../../actions/MainAction'
import { mixinAlert } from '../../actions/SweetAlerts'

export class StudentNewReuiremenmtModal extends Component {

    closeModal=()=>{
        this.props.resetStateValue({name:"programRequirementModal"})
    }
    saveDataBtn=(data)=>{
        if(data?.title !="" && data?.text !=""){
            let formData = new FormData();
            for(const property in data){
                formData.append(property, data[property]);
            }
            this.props.insertNewData("apply-requirements", formData)
            .then(resp=>{
                if(resp == "success"){
                    this.closeModal();
                    this.props.getStudentRequirements(data.student_id, data.program_id);
                    this.props.getApplyRequirements(data.student_id, data.program_id)
                    mixinAlert("success", 'Requirement added successfully');
                }else{
                    mixinAlert("success", 'Something went wrong');
                }
            })
        }
    }
  render() {
    const {programRequirementModal} = this.props;
    // console.log("ss")
    return (
      <div className='background_shadow flex justify-center items-center'>
        <div className='bg-white rounded-[10px] w-[600px]'>
            <Header title={"Add new requirement"} closeBtn={this.closeModal.bind(this)} />
            <div className='p-[20px]'>
                <div>
                    <Input
                    svg={<InpSvg />}
                    requiredSvg
                    bigInp  bg="bg-[#F3F4F6]"
                    name={`programRequirementModal.title`}  
                    value={programRequirementModal?.title} 
                    title={'Title'}  />
                   <div className='mt-[20px]'>
                        <TextArea svg={<TextSvg />} bigArea value={programRequirementModal?.text} title='Description' name="programRequirementModal.text" />
                   </div>
                </div>

                <div className='flex justify-end mt-[20px]'>
                    <button onClick={this.saveDataBtn.bind(this, programRequirementModal)} className='h-[48px] w-[102px] p-[0_10px] bg-blue text-white rounded-[5px] font-regular text-[20px]'>Save</button>
                </div>
            </div>
           
        </div>
      </div>
    )
  }
}
const mapStateToProps = (state) =>({
    programRequirementModal: state.Data.programRequirementModal
});
const mapDispatchToProps = {changeStateValue, resetStateValue, insertNewData, getStudentRequirements, getApplyRequirements}
export default connect(mapStateToProps, mapDispatchToProps)(StudentNewReuiremenmtModal)
