import React, { Component } from 'react'
import Header from '../components/header/Header'
import { connect } from 'react-redux'
import StudentDashboard from './student_role/StudentDashboard';
import { insertNewData } from '../actions/MainAction';
import { x } from '../actions/testFunc';
import { mixinAlert } from '../actions/SweetAlerts';
import TeacherDashboard from './teacher_role/TeacherDashboard';
import StaffDashBoard from './StaffDashBoard';
export class Dashboard extends Component {

  addAllProg=()=>{
    // console.log(x)
    let programs = [];
    for(const y of x){
     
      y.country_id = 4;
      y.city_id = 11;
      y.scholarship = "";
      y.scholarship_option='0';
      y.duration=y.duration.substring(0, 1);
      programs.push(y)
    }
    let formData = new FormData();
    formData.append("programs", JSON.stringify(programs))
    this.props.insertNewData('add-all-programs', formData)
    .then(resp=>{
      // console.log(resp);
    })
  }

  copyLink=()=>{
    navigator.clipboard.writeText(window.location?.href+`?tn=${btoa("generated"+this.props.user?.id+"code")}`)
    mixinAlert("success", "Link kopyalandı")
    // console.log()
  }
  render() {
    const {user} = this.props;
    // console.log()
    return (
      <div className='w-full  pl-[27px] pr-[27px] mt-[16px]'>
        <Header title="Ana Səhifə" />
        {
          user?.role =="student"?
          <StudentDashboard />:null
        }
        {/* <button onClick={this.addAllProg.bind(this)}>Add</button> */}
        {/* {
          user?.role == "teacher"?
          <button onClick={this.copyLink.bind(this)} className='bg-blue text-white rounded-[7px] text-[14px] p-[5px_9px]'>
          Müraciət linkini kopyalağa
        </button>:null
        } */}
        {
          user?.role == "admin" || user?.role == "xtm" || user?.role == "manager"?
          <StaffDashBoard />:null
        }
        {
          user?.role == "teacher"?
          <TeacherDashboard />:null
        }
      </div>
    )
  }
}
const mapStateToProps = (state) =>({
  user: state.Data.user
});
const mapDispatchToProps = {insertNewData}
export default connect(mapStateToProps, mapDispatchToProps)(Dashboard)
