import React, { Component } from 'react'
import Input from '../../../components/modals/components/Input'
import InpUserSvg from '../../../components/svg/InpUserSvg'
import PhoneSvg from '../../../components/svg/PhoneSvg'
import { changeStateValue } from '../../../redux/MainReducer'
import { connect } from 'react-redux'
import InputPhone from '../../../components/modals/components/InputPhone'
import InpEmailSvg from '../../../components/svg/InpEmailSvg'

export class PersonalInfo extends Component {
  render() {
    const {educationPlanModalData} = this.props;
    return (
        <div className='mt-[45px]'>
     
        <div className='grid grid-cols-2 gap-[20px] max-[640px]:grid-cols-1'>
            <Input requiredSvg bigInp svg={<InpUserSvg />}
                name={`educationPlanModalData.information.first_name`}  
                value={educationPlanModalData.information?.first_name} 
                title={this.props.language?.name}  />
            <Input requiredSvg bigInp  svg={<InpUserSvg />}
                name={`educationPlanModalData.information.last_name`}  
                value={educationPlanModalData.information?.last_name} 
                title={this.props.language?.surname}  />
            <Input requiredSvg bigInp  svg={<InpEmailSvg />} type="email"
                name={`educationPlanModalData.information.email`}  
                value={educationPlanModalData.information?.email} 
                title={this.props.language?.email}  />
            <InputPhone requiredSvg bigInp svg={<PhoneSvg />}
                name={`educationPlanModalData.information.phone_number`}  
                value={educationPlanModalData.information?.phone_number} 
                title={this.props.language?.your_phone_num}  />
        </div>
      </div>
    )
  }
}
const mapStateToProps = (state) =>({
    educationPlanModalData: state.Data.educationPlanModalData,
    language: state.Data.language,
});
const mapDispatchToProps = {changeStateValue}
export default connect(mapStateToProps, mapDispatchToProps)(PersonalInfo)
