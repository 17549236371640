import React, { Component } from 'react'

export class CardLangSvg extends Component {
  render() {
    return (
        <svg
    xmlns="http://www.w3.org/2000/svg"
    id="Layer_1"
    width={16}
    height={16}
    data-name="Layer 1"
    viewBox="0 0 24 24"
  >
    <defs>
      <style>
        {
          ".cls-1{fill:none;stroke:#020202;stroke-miterlimit:10;stroke-width:1.92px}"
        }
      </style>
    </defs>
    <path
      d="M.5 3.35H12M6.25.48v2.87M9.12 3.35c0 3.52-3.28 8.2-7.66 10.55"
      className="cls-1"
    />
    <path
      d="M4.51 7.37A16.4 16.4 0 0 0 11 13.9M12.96 22.52l3.83-10.54h.96l3.83 10.54M20.43 18.69h-5.36M11.04 22.52h3.84M19.67 22.52h3.83"
      className="cls-1"
    />
  </svg>
    )
  }
}

export default CardLangSvg
