import React, { Component } from 'react'
import { connect } from 'react-redux';
import { withHooks } from '../../../actions/withHooks';
import Header from '../../../components/header/Header';
import { getSingleData, getStudentAppliedPrograms, getStudentRequirements} from '../../../actions/MainAction';
import { changeStateValue } from '../../../redux/MainReducer';
import Documents from './application_pages/Documents';
import Trackings from './application_pages/Trackings';
import Expencess from './application_pages/Expencess';
import StudentExpences from './StudentExpences';
import { mixinAlert } from '../../../actions/SweetAlerts';
import { Link } from 'react-router-dom';
import StudentInfo from './pages/StudentInfo';
export class StudentProgramRequirements extends Component {
    state={
        programData:'',
        section:'tracking',
        programs:[],
        studentData:''
    }
    componentDidMount(){
        this.props.getSingleData(`users/${this.props.params.student_id}`)
        .then(resp=>{
          this.setState({studentData:resp})
        });
        this.props.getStudentRequirements(this.props.params.student_id, this.props.params.program_id);
        this.props.getSingleData(`programs/${this.props.params.program_id}`)
        .then(resp=>{
            if(resp == ""){
                mixinAlert("warning", "İxtisas tapılmadı")
                this.props.navigate("/")
            }else{
                this.setState({programData:resp})
            }
            
        })
       
    }
    componentDidUpdate(prevProps, prevState){
        if(prevProps?.params.program_id != this.props.params.program_id){
            this.props.getStudentRequirements(this.props.params.student_id, this.props.params.program_id);
            this.props.getSingleData(`programs/${this.props.params.program_id}`)
            .then(resp=>{
                if(resp == ""){
                    mixinAlert("warning", "İxtisas tapılmadı")
                    this.props.navigate("/")
                }else{
                    this.setState({programData:resp})
                }
                
            })
        }
    }
    onChangeBtn=()=>{
        this.props.getStudentAppliedPrograms(this.props.params.student_id)
        .then(resp=>{
            // console.log(resp)
          this.setState({programs: resp})
        })
    }
  render() {
    const {programData, section, programs, studentData} = this.state;
    const {user, studentRequirements} = this.props;
    // console.log(programData)
    return (
        <div className='w-full  pl-[27px] pr-[27px]'>
            <Header title={user?.role !== "student"? `${studentData?.full_name} - ${programData?.name} (${programData?.degree?.name})`:programData?.name + ` (${programData?.degree?.name})` } changeBtn={user?.role == "xtm"? true:false} onChangeBtn={this.onChangeBtn.bind(this)} />
            <div>
                <h3 className='txt-dark-blue font-semibold'>{programData?.university?.name}</h3>
            </div>
            <div className='flex items-center gap-[20px] mt-[20px]'>
                <button onClick={()=>this.setState({section: "tracking"})} className='bg-white text-[14px] font-semibold shadow p-[6px_9px] rounded-[5px]'>Proses İzləmə</button>
                <div className='relative'>
                    {
                        studentRequirements?.filter(x=>x.status==0)?.length !=0?
                        <span className='absolute inline-flex w-[20px] h-[20px] bg-[var(--orange)] items-center justify-center text-[12px] text-white rounded-full right-[-10px] top-[-10px]'>{studentRequirements?.filter(x=>x.status==0)?.length}</span>:null
                    }
                    <button onClick={()=>this.setState({section: "documents"})} className='bg-white text-[14px] font-semibold shadow p-[6px_9px] rounded-[5px]'>Sənədlər</button>
                </div>
                {
                    user?.role !="student"?
                     <button onClick={()=>this.setState({section: "expencess"})} className='bg-white text-[14px] font-semibold shadow p-[6px_9px] rounded-[5px]'>Xərclər</button>:null
                }
                {
                    user?.role !="student"?
                     <button onClick={()=>this.setState({section: "student_info"})} className='bg-white text-[14px] font-semibold shadow p-[6px_9px] rounded-[5px]'>Ümumi məlumat</button>:null
                }
               
            </div>
            {
                section === "documents"?
                <Documents />:
                section === "tracking"?
                <Trackings />:
                section === "student_info"?
                <div className='mt-[20px]'>
                    <StudentInfo student_id={this.props.params.student_id} />
                </div>:
                section === "expencess"?
                <Expencess />:null
            }
            
          
         {
            programs?.length !=0?
            <div onClick={(e)=>e.target.className.includes("background_shadow")? this.setState({programs: []}):()=>{}} className='background_shadow flex justify-center items-center'>
                <div className='bg-white w-[400px] p-[20px] shadow rounded-[10px] flex flex-col max-h-[60vh] overflow-auto gap-[7px]'>
                    {
                        programs?.map((data, i)=>{
                            return(
                                <Link onClick={()=>this.setState({programs: []})} to={`/program-requirements/${this.props.params.student_id}/${data?.program_id}`} key={i} className='border-b border-dashed pb-[4px]'>
                                    <p className='text-[16px]'>{data?.university_name}</p>
                                    <p className='text-[14px]'>{data?.program_name}</p>
                                </Link>
                            )
                        })
                    }
                </div>
            </div>:null
         }
        </div>
    )
  }
}
const mapStateToProps = (state) =>({
   user: state.Data.user,
   studentRequirements: state.Data.studentRequirements,
});
const mapDispatchToProps = {getSingleData, changeStateValue, getStudentRequirements, getStudentAppliedPrograms}
export default connect(mapStateToProps, mapDispatchToProps)(withHooks(StudentProgramRequirements))
