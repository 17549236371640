import React, { Component } from 'react'
import SectionHeader from '../../../components/public_components/SectionHeader'
import { connect } from 'react-redux';
import { getTop20Rankings } from '../../../actions/MainAction';
import RankingTable from './RankingTable';
import img from "../../../images/top20.jpeg"
import Card5 from '../../../components/public_components/cards/Card5';
import { getPathConvert, getStartFee, getTranslateLanguage } from '../../../actions/getOtherFunctions';
export class Top20UniversitiesRankingSection extends Component {
    componentDidMount(){
        this.props.getTop20Rankings()
    }
  render() {
    const {top20Rankings, headerNone=false} = this.props;
    // console.log(top20Rankings)
    return (
        <section className={`${!headerNone? "pt-[100px] bg-[#F5F5F5] ":""} `}>
            <div className='mt-[60px]'>
                {
                     !headerNone?
                 <SectionHeader text1={this.props.language?.section_title7} text2="" />    :null
                }
                <div className='max-w-[1200px] ml-auto mr-auto mt-[40px] pb-[30px] max-[1240px]:ml-[20px] max-[1240px]:mr-[20px]'>
                    {/* <iframe className='rounded-[10px] max-[1050px]:h-[500px] max-[750px]:h-[400px] max-[680px]:h-[300px] max-[570px]:h-[250px] max-[460px]:h-[200px] max-[390px]:h-auto' width="100%" height="600" src="https://www.youtube.com/embed/gNDH1Ikv8Ho?si=ypIw-3d5hr4B2pye" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe> */}
                    <img className='rounded-[10px] w-full h-auto' src={img} />
                </div>
                <div className='max-w-[1200px] ml-auto mr-auto mt-[0px] pb-[80px] max-[1240px]:ml-[20px] max-[1240px]:mr-[20px]'>
                <div className='grid-full-380'>
                    {
                    top20Rankings?.map((data, i)=>{
                    return(
                                <Card5 key={i} 
                                image={data?.image_full_url}
                                name={getTranslateLanguage(this.props.lang_id, "name", data)}
                                country={getTranslateLanguage(this.props.lang_id, "name", data?.country)}
                                city={getTranslateLanguage(this.props.lang_id, "name", data?.city)}
                                data={data} 
                                path={`/${this.props.langPrefix}university/${getPathConvert(data?.name_en)}`} 
                                ranking={data?.ranking}
                                view={true}
                                footer={false}
                                rankTxt={this.props.language?.at_world}
                                start_fee={getStartFee(data?.phd_start, data?.master_start, data?.pre_master_star, data?.bachelour_start)}
                            />
                        )
                        })
                    }
                </div>
                    {/* <RankingTable
                        options={top20Rankings} 
                        column1Title={"Sıralama"}
                        column2Title={"Universitet"}
                        column3Title={"Ölkə"}
                        column1Property={"ranking"}
                        column2Property={"university"}
                        column3Property={"country"}
                        rank_property={"top_20_rank_id"}
                        count={3}
                        /> */}
                </div>
                
            </div>
        
        </section>
    )
  }
}
const mapStateToProps = (state) =>({
    top20Rankings: state.Data.top20Rankings,
    language: state.Data.language,
    lang_id: state.Data.lang_id,
    langPrefix: state.Data.langPrefix,
});
const mapDispatchToProps= {getTop20Rankings}
export default connect(mapStateToProps, mapDispatchToProps)(Top20UniversitiesRankingSection)



