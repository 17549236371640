import React, { Component } from 'react'
import Top3 from '../../../images/rankings/top3.png';
import Top5 from '../../../images/rankings/top5.png';
import Top10 from '../../../images/rankings/top10.png';
import Top20 from '../../../images/rankings/top20.png';
import Top500More from '../../../images/rankings/top500+.png';
import Top50 from '../../../images/rankings/top50.png';
import Top100 from '../../../images/rankings/top100.png';
import Top200 from '../../../images/rankings/top200.png';
import Top500 from '../../../images/rankings/top500.png';
import rank1 from '../../../images/rankings/rank1.png';
import rank2 from '../../../images/rankings/rank2.png';
import rank3 from '../../../images/rankings/rank3.png';
import rank4 from '../../../images/rankings/rank4.png';
import ProgSvg from '../../svg/ProgSvg';
import { Link } from 'react-router-dom';
import LocationSvg2 from '../svg/LocationSvg2';
import { getCompanyPath } from '../../../actions/getOtherFunctions';
export class Card1 extends Component {
    getSvg(item){
        if(item<=3){
          return Top3 
          
        }else if( item<=5 &&  item>3){
          return Top5 
        }else if(item<=10 &&  item>5){
          return Top10 
        }else if( item<=20 &&  item>10){
          return Top20 
        }else if( item<=50 &&  item>20){
          return Top50 
        }else if(item<=100 &&  item>50){
          return Top100 
        }else if(item<=200 &&  item>100){
          return Top200 
        }else if(item<=500 &&  item>200){
          return Top500
        }else{
          return Top500More
        }
      }
      getRandImage(){
        let images = [rank1, rank2, rank3, rank4];
        let rand = Math.floor(Math.random()*4)
        return images[rand]
      }
  render() {
    const {data} = this.props;
    return (
        <div className='bg-white shadow rounded-[12px] relative'>
        <div className="absolute top-3 right-[10px]  inset-x-3 flex justify-end">
           {/* <img style={{ width: '50px' }} src={this.getSvg(data?.ranking)} /> */}
          <div  className='relative flex justify-center items-center '>
          <img  style={{ width: '50px' }} src={this.getRandImage(data?.ranking)} />
          <span className='absolute text-[20px] text-white font-black mt-[9px] different-font'>{data?.ranking}</span>
          </div>
         </div>
       <img src={data?.inst_image?.image_full_url} alt={data?.inst_name} className='w-full h-[191px] rounded-t-[12px]' />
       <div style={{ clipPath:'polygon(0 0, 100% 90%, 100% 100%, 0 100%)', width:'100%',height:'60px',position:'relative', backgroundColor:'white', marginTop:'-56px'}}></div>
       <div className='w-[71px] h-[71px] bg-white rounded-full flex items-center justify-center p-[4px] mt-[-90px] ml-[10px] mr-auto relative'>
           <img src={data?.user_image} alt={data?.first_name} className='w-full h-full rounded-[50%]' />
       </div>
       <div className='mt-[10px] relative pl-[20px] pr-[20px] pb-[25px] '>
           <h3 className='txt-blue text-[18px] font-bold text-start'>{data?.first_name} {data?.last_name?.substring(0,1)}...</h3>
           <h4 className='text-[#2C2C2C] text-[16px] font-bold text-start mt-[5px] mb-[5px]'>
           {
            data?.inst_name?.length <=28?
            <span>{data?.inst_name}</span>:
            <div className='group relative'>
              <span>{data?.inst_name?.substring(0,28)}...</span>
              <span className='hidden group-hover:block absolute top-0 left-0 w-full bg-white shadow rounded-[4px] p-[2px] ml-[-4px]'>{data?.inst_name}</span>
            </div>
           }
           </h4>
           <span className='text-[#000] text-[12px] font-medium text-start flex items-center gap-[10px] ml-[-3px]'><ProgSvg /> 
           {
            data?.program_name?.length <=30?
            <span>{data?.program_name}</span>:
            <div className='group relative'>
              <span>{data?.program_name?.substring(0,30)}...</span>
              <span className='hidden group-hover:block absolute top-0 left-0 w-full bg-white shadow rounded-[4px] p-[2px]'>{data?.program_name}</span>
            </div>
           }
           </span>
           <span className='text-[#000] text-[12px] font-medium text-start flex items-center gap-[14px] mt-[5px]'>
              <img className='w-[22px] h-[15px]' src={data?.country_image} />
              {data?.country_name}
            </span>
            <Link to={`https://celt.az${getCompanyPath(data?.company_id)}`} target='_blank' className='text-[#0066FF] text-[14px] font-semibold flex items-center gap-[12px] mt-[5px] ml-[-1px]'>
              <LocationSvg2 />
              {data?.company_name}
            </Link>
       </div>
     </div>
    )
  }
}

export default Card1
