import React, { Component } from 'react'
import LeftSvg from '../svg/LeftSvg'
import RightSvg from '../svg/RightSvg'
import BookingDateComponent from './BookingDateComponent';
import { renderCalendar } from '../../actions/calendar';
import { getPublicBookingDate } from '../../actions/PublicActions';
import {connect} from "react-redux"
import moment from 'moment';
import { changeStateValue } from '../../redux/MainReducer';
export class BookingCalendarComponent extends Component {
    state={
        days:[],
        timePosition:false,
        times:[],
        selectedDay:'',
        date: new Date()
    }
    componentDidMount(){
        this.setState({days: renderCalendar()})
        this.props.getPublicBookingDate(moment(new Date()).format("YYYY-MM-DD"))
    }

    checkExistsDay=(bookingDates, day)=>{
        const check = bookingDates.find(x=>x.day == day);
        // console.log(bookingDates, day)
        if(check){
            return true;
        }else{
            return false
        }
    }

    selectDay=(date, bookingDates)=>{
        const times = bookingDates.find(x=>x.day == date)?.times
        if(times.length !=0){
            this.props.changeStateValue({
                name:'bookingUserInfo.day',
                value: date
            })
            this.setState({
                timePosition:true,
                times:times,
                selectedDay: date
            });
        }else{
            
        }
        if(window.innerWidth <= 600){
           setTimeout(()=>{
            window.scrollTo(0,document.body.scrollHeight)
           }, 200)
        }
        console.log()
        
    }
    prevMonth=(date)=>{
        let month = date.getMonth() - 1;
        date.setMonth(month);
        this.setState({days: renderCalendar(date)})
        this.props.getPublicBookingDate(moment(date).format("YYYY-MM-DD"))
    }
    nextMonth=(date)=>{
        let month = date.getMonth() + 1;
        date.setMonth(month);
        this.setState({days: renderCalendar(date)})
        this.props.getPublicBookingDate(moment(date).format("YYYY-MM-DD"))
    }
  render() {
   const {days, timePosition, times, selectedDay, date} = this.state;
   const {bookingDates} = this.props;
//    console.log(bookingDates)
    return (
      <div className='p-[20px] w-full'>
        <h3 className='text-[#1a1a1a] text-[18px] font-[600]'>{this.props.language?.select_date_time}</h3>
        <div className={`grid ${timePosition?"grid-cols-[8fr_4fr]":"grid-cols-1" } items-start gap-[20px] max-[600px]:grid-cols-1`}>
            <div>
                <div className='flex items-center justify-center gap-[20px] mt-[20px]'>
                    <button onClick={moment(new Date()).format("YYYY-MM-DD") != moment(date).format("YYYY-MM-DD")? this.prevMonth.bind(this, date):()=>{}} className={`${moment(new Date()).format("YYYY-MM-DD") != moment(date).format("YYYY-MM-DD")? " bg-[var(--light-blue)]":""} w-[30px] h-[30px] inline-flex justify-center items-center rounded-full`}>
                        <LeftSvg color="#1a1a1a" />
                    </button>
                    <span className='font-[300]'>{moment(date).format("MMMM YYYY")}</span>
                    <button onClick={this.nextMonth.bind(this, date)} className='bg-[var(--light-blue)] w-[30px] h-[30px] inline-flex justify-center items-center rounded-full'>
                        <RightSvg color="#1a1a1a"  />
                    </button>
                </div>
                <div className='grid grid-cols-7 mt-[20px]'>
                    <span className='text-[13px] text-center'>SUN</span>
                    <span className='text-[13px] text-center'>MON</span>
                    <span className='text-[13px] text-center'>TUE</span>
                    <span className='text-[13px] text-center'>WED</span>
                    <span className='text-[13px] text-center'>THU</span>
                    <span className='text-[13px] text-center'>FRI</span>
                    <span className='text-[13px] text-center'>SAT</span>
                   
                </div>
                <ul className='grid grid-cols-7 text-center gap-[10px] mt-[20px]'>
                    {
                        days?.map((day, i)=>{
                            return (
                                <li className='text-gray-500 text-[14px] ' key={i}>
                                    <button onClick={this.checkExistsDay(bookingDates, day?.date)? this.selectDay.bind(this, day?.date, bookingDates):()=>{}} className={` w-[30px] h-[30px] ${this.checkExistsDay(bookingDates, day?.date)? 'bg-[#F2F6FF] text-[#0060E6] cursor-pointer':"text-gray-500 cursor-default"}  inline-flex justify-center items-center rounded-full`}>
                                        {day?.day}
                                    </button>
                                </li>
                            )
                        })
                    }
                </ul>
            </div>
            {timePosition? <BookingDateComponent selectedDay={selectedDay} times={times} />:null}
            
        </div>
      </div>
    )
  }
}
const mapStateToProps = (state) =>({
    bookingDates: state.Data.bookingDates,
    language: state.Data.language,
});
const mapDispatchToProps = {getPublicBookingDate, changeStateValue}
export default connect(mapStateToProps, mapDispatchToProps)(BookingCalendarComponent)
